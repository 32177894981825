import {getDateTime, getShortDate} from "commonMethod/common";
import {millisToMinutesAndSeconds} from "commonMethod/common"

export const VehicleCurrentInventoryColumns = [
  {
    width: 150,
    Header: 'NAME',
    accessor: 'productName',
  },
  {
    width: 80,
    Header: 'TYPE',
    accessor: 'itemType',
  },
  {
    width: 150,
    Header: 'TAG ID',
    accessor: 'EPC'
  },

  {
    width: 100,
    Header: 'NDC',
    accessor: 'NDC',
  },
  {
    width: 100,
    Header: 'SERIAL#',
    accessor: 'serialNumber',
  },
  {
    width: 150,
    Header: 'LOT#',
    accessor: 'lotNumber',
  },
  {
    width: 150,
    Header: 'EXPIRY',
    accessor: 'expirationDate',
    Cell: ({ row }) => (
    //  row._original.expirationDate !== null ?
      <span>{getShortDate(row._original.expirationDate)}</span>
    //  :''
    ),
  },
  {
    width: 150,
    Header: 'ASSETID',
    accessor: 'assetId',
  },
  {
    width: 150,
    Header: 'MANUFACTURE DATE',
    accessor: 'manufactureDate',
    Cell: ({ row }) => (
      row._original.manufactureDate !== null ?
      <span>{getShortDate(row._original.manufactureDate)}</span>
      : ""
    ),
  },
  {
    width: 150,
    Header: 'VENDOR',
    accessor: 'vendor',
  },
  {
    width: 150,
    Header: 'VENDOR NO',
    accessor: 'vendorNumber',
  },
  {
    width: 150,
    Header: 'STATUS',
    accessor: 'status',
  },
  {
    width: 150,
    Header: 'DEPARTMENT',
    accessor: 'department',
  },
  {
    width: 150,
    Header: 'PURCHASE',
    accessor: 'purchaseDate',
    Cell:  ({ row }) => (
      row._original.purchaseDate !== null ?
      <span>{getShortDate(row.purchaseDate)}</span>
      : ''
    ),
  },
  {
    width: 150,
    Header: 'LAST CHECK IN',
    accessor: 'lastCheckIn',
    Cell: ({ row }) => (
      row._original.lastCheckIn !== null ?
      <span>{getDateTime(row._original.lastCheckIn)}</span>
      : ''
    ),
  },

];