import DisposableDetailMainTableContainer from "../../container/Disposable/DisposableDetailMainTableContainer";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import DisposableDetailOverviewContainer from "container/Disposable/DisposableDetailOverviewContainer";
import DisposableItemOverviewContainer from "container/Disposable/DisposableItemOverviewContainer";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import disposablesService from "service/DisposablesService";
import ProductServices from "service/CommonServices";
import { AllList } from "commonComponents/DropDown";
import { Button as DxButton } from "devextreme-react/button";
import DataGrid, {
  Scrolling,
  Selection,
  Pager,
  Paging,
  Column,
  HeaderFilter,
  StateStoring,
  ColumnChooser,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Paper } from "@mui/material";
import { detailColumns } from "columns/detailColumn";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { BaseURL } from "Constant/BaseURL";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const renderGridCell = (data) => {
  return data.text == "" ? (
    ""
  ) : getDateColor(data.text, 2) ? (
    <div style={{ color: "red" }}>{getTime(data.text)}</div>
  ) : getDateColor(data.text, 5) ? (
    <div style={{ color: "#E8912D" }}>{getTime(data.text)}</div>
  ) : (
    <div style={{ color: "black" }}>{getTime(data.text)}</div>
  );
};
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=disposable`,
});

class DisposableDetail extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
      categoryStatus: "Active",
      categoryName: "",
    };
  }

  getDisposables = (params = {}) => {
    let { pageIndex, pageSize } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "disposable",
      ...params,
    };
    this.props.getDisposableList(obj);
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
  }

  sync = async (params = {}) => {
    await this.getDisposables(params);
    await this.props.getActiveCategory("disposable");
  };

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  handleSelect = async (val) => {
    await this.setState({
      selectedItem: val,
    });

    await this.props.changePage(0);
    val === "All Disposables"
      ? this.getDisposables()
      : val === "0" || val === "1"
      ? this.sync({
          isActive: parseInt(val),
        })
      : this.sync({
          isActive: "1",
          category: val,
        });
    // this.sync({
    //   isActive : parseInt(val)
    // });
  };
  onRowClick(e) {
    if (e.rowType != "group") {
      this.props.history.push({
        pathname: `/disposable/${e.key}`,
        state: {
          assetName: e.data.name,
        },
      });
      e.component.selectRows(e.key);
    }
  }
  render() {
    const { match, location, activeCategories } = this.props;
    const { dropdownOpen, selectedItem, categoryStatus } = this.state;
    const { disposableName } = location?.state ? location?.state : "";
    let isDisposableItemTagPage = false;
    let disposable_id;
    let disposable_item_tag_id;

    /**
     * To display main table in all screen,
     * disposable_id and disposable_item_id is inserted into the URL to switch between the pages
     */
    disposable_id = match.params.disposable_id;

    if (match.params.disposable_item_id) {
      disposable_item_tag_id = match.params.disposable_item_id;
    }
    return (
      <div>
        <Row className="drug-detail-table-actions">
          <Col lg="3 quick-navigation">
            {/* <Row>
              <Col lg="4" md="3" sm="2" className="table-inner-actions">
                <div>
                  <Button variant="primary">
                    <Link to="/addDisposable"> New </Link>
                  </Button>
                </div>
                <div className="sync">
                  <a href="#" onClick={this.sync}>
                    <i className="fas fa-sync"></i>
                  </a>
                </div>
              </Col>
            </Row> */}
            {/* <DisposableDetailMainTableContainer disposable_id={disposable_id} /> */}
            <Paper>
              <DataGrid
                id="miniGridContainer"
                dataSource={dataSource}
                keyExpr="GUID"
                showBorders={false}
                hoverStateEnabled={true}
                // rowAlternationEnabled={true}
                ref={this.dataGrid}
                onRowClick={(e) => {
                  this.onRowClick(e);
                }}
                onRowDblClick={(e) => {
                  this.onRowClick(e);
                }}
                onContentReady={(e) => {
                  if (!e.component.getSelectedRowKeys().length) {
                    e.component.selectRows(disposable_id);
                  }
                }}
              >
                <ColumnChooser enabled={true} mode={"select"} width={0} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="disposal-main-grid"
                  savingTimeout={100}
                />
                <Column
                  dataField="IMAGE"
                  cssClass="hide-asset-detail-column"
                ></Column>
                <Column dataField="name" caption="NAME" />
                <Column
                  dataField="manufacturer"
                  cssClass="hide-asset-detail-column"
                />
                <Column
                  dataField="productCode"
                  cssClass="hide-asset-detail-column"
                />
                <Column
                  dataField="UNSPSCCode"
                  cssClass="hide-asset-detail-column"
                />
                <Column
                  dataField="modelNumber"
                  cssClass="hide-asset-detail-column"
                />
                <Column dataField="brand" cssClass="hide-asset-detail-column" />
                <Column
                  dataField="inventoryQuantity"
                  cssClass="hide-asset-detail-column"
                />
                <Column
                  dataField="expiryDate"
                  cssClass="hide-asset-detail-column"
                />
                <HeaderFilter visible={false} allowSearch={false} />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.props.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                {/* <Toolbar visible={false}>
                <Item name="columnChooserButton" location="after" />
              </Toolbar> */}
                <Toolbar>
                  <Item location="after">
                    <Button variant="primary">
                      <Link to="/AddDisposable"> + New</Link>
                    </Button>
                  </Item>
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={() => {
                        this.sync();
                        this.dataGrid.current.instance.refresh();
                      }}
                    />
                  </Item>
                </Toolbar>
              </DataGrid>
            </Paper>
          </Col>
          {disposable_item_tag_id ? (
            <Col lg="9" className="drug-item-detail-overview">
              <DisposableItemOverviewContainer
                disposableName={disposableName}
                disposable_item_tag_id={disposable_item_tag_id}
                disposable_id={disposable_id}
              ></DisposableItemOverviewContainer>
            </Col>
          ) : (
            <Col lg="9" className="detail-overview">
              <DisposableDetailOverviewContainer
                disposableName={disposableName}
                disposable_id={disposable_id}
              ></DisposableDetailOverviewContainer>
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { disposables, rows, pageIndex, pageSize, activeCategories } =
    state.disposablesReducer;
  return {
    disposables,
    activeCategories,
    rows,
    pageIndex,
    pageSize,
  };
};

/**
 * To perform add,get,delete and update action on disposables
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getDisposableList: (obj) =>
      dispatch(ProductServices.getAllProductList(obj)),
    getFilteredDisposableList: (type) =>
      dispatch(disposablesService.getFilteredDisposableList(type)),
    changePage: (pageNo) =>
      dispatch(disposablesService.changeDisposablePage(pageNo)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DisposableDetail);
