import EditButton from "commonComponents/EditButton";
import CloseButton from "commonComponents/closeButton";
import ChecklistTitle from "commonComponents/Overview/checklistTitle";
import React, { Component } from "react";
import { AiOutlineClockCircle } from 'react-icons/ai';
import { Dropdown, DropdownButton, Row , Badge } from "react-bootstrap";

export class ChecklistOverviewHeader extends Component {
  render() {
    const {
      title,
      isActive,
      history,
      editPath,
      duration,
      complete,
      closeButtonPath,
      onHandleMoreOperation,
    } = this.props;
    return (
      <div className="drug-detail-action">
        <ChecklistTitle title={title} complete = {complete} duration= {duration} isActive={isActive} />
        
        <Row>
          {/* <EditButton history={history} path={editPath} />
          <DropdownButton
            className={"drag_more_menu"}
            variant="info"
            title="More"
            onClick={(e) => onHandleMoreOperation(e.target.value)}
          >
            <Dropdown.Item as="button" value="markActive">
              Mark {isActive ? "Inactive" : "Active"}
            </Dropdown.Item>
            <Dropdown.Item as="button" value="delete">
              Delete
            </Dropdown.Item>
          </DropdownButton> */}
          <CloseButton history={history} path={closeButtonPath} />
          
        </Row>
      </div>
    );
  }
}

export default ChecklistOverviewHeader;
