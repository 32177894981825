import * as React from 'react';
import Paper from '@mui/material/Paper';
import { withRouter } from 'react-router-dom';
import { Grid, Table, TableHeaderRow, TableRowDetail } from '@devexpress/dx-react-grid-material-ui';
import {
  SortingState,
  SelectionState,
  FilteringState,
  PagingState,
  GroupingState,
  RowDetailState,
  IntegratedFiltering,
  IntegratedGrouping,
  IntegratedPaging,
  IntegratedSorting,
  DataTypeProvider,
  IntegratedSelection,
} from '@devexpress/dx-react-grid';
import { StyledDiv, classes } from './HistoryDetailTable';
import { Button } from 'react-bootstrap';
import { getDetailComuns } from 'columns/detailColumn';
import { getDetailComunsExtension } from 'columns/detailColumn';
import { getSectionColumnsExtension } from 'columns/detailColumn';

const GridDetailContainer = ({ row, onAddLineItemView, onDeleteLineItem, onEditLineItem, onMoveLineItem }) => (
  <StyledDiv className='detailColumn'>
    {row.lineItems ? (
      <Paper>
        <Grid
          rows={row.lineItems}
          columns={getDetailComuns({
            onDelete: onDeleteLineItem,
            onEdit: onEditLineItem,
            section: row,
            onMove: onMoveLineItem,
          })}
        >
          <Table columnExtensions={getDetailComunsExtension} />
          <TableHeaderRow />
        </Grid>
      </Paper>
    ) : null}
    <Button
      variant='primary'
      style={{ marginBottom: '10px', marginLeft: '200px', marginTop: '5px' }}
      onClick={() => {
        onAddLineItemView && onAddLineItemView({ row, id: row.id });
      }}
    >
      Add Line Item
    </Button>
  </StyledDiv>
);

const Section = ({ data = [], sectionColumns, onAddLineItemView, onDeleteLineItem, onEditLineItem, onMoveLineItem }) => {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     dropdownOpen: false,
  //     selectedItem: "",
  //   };
  // }

  // render (){
  // const {
  //   data = [] ,
  //   sectionColumns,
  //   onAddLineItemView,
  //   onDeleteLineItem,
  //   onEditLineItem,
  //   onMoveLineItem
  // } = this.props;

  const [expandedGroups, setExpandedGroups] = React.useState([]);

  const [expandedRowIds, setExpandedRowIds] = React.useState([0]);

  const onGroupingChange = React.useCallback((value) => {
    setGrouping(value);
  });

  const onExpandedGroupsChange = React.useCallback((value) => {
    setExpandedGroups(value);
  });

  const onExpandedRowIdsChange = React.useCallback((value) => {
    setExpandedRowIds(value);
  });

  React.useEffect(() => {
    setExpandedRowIds(data.map((d, index) => index));
  }, [data]);

  return (
    <Paper>
      <Grid rows={data} columns={sectionColumns}>
        <GroupingState onGroupingChange={onGroupingChange} expandedGroups={expandedGroups} onExpandedGroupsChange={onExpandedGroupsChange} />
        <RowDetailState
          // defaultExpandedRowIds={[]}
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={onExpandedRowIdsChange}
        />
        <Table columnExtensions={getSectionColumnsExtension} />
        <TableHeaderRow />
        <TableRowDetail
          contentComponent={({ row }) => (
            <GridDetailContainer
              row={row}
              onAddLineItemView={onAddLineItemView}
              onDeleteLineItem={onDeleteLineItem}
              onEditLineItem={onEditLineItem}
              onMoveLineItem={onMoveLineItem}
            ></GridDetailContainer>
          )}
        />
      </Grid>
    </Paper>
  );
};

export default withRouter(Section);
