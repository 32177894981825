import React, { useState, useRef, useEffect } from "react";
import { Badge, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { LoadPanel } from "devextreme-react";
import { BaseURLDev, BaseURLMock } from "../../Constant/BaseURL";
import { toLower } from "lodash";

import CloseButton from "commonComponents/closeButton";
import OverViewTab from "commonComponents/OverViewTab";
import OverviewProductTabs from "commonComponents/OverviewProductTabs";
import DetailGrid from "./ReceivingDetailGrid";
import ReceivingDialog from "./ReceivingDialog";
import axios from "axios";
import { Grid } from "@mui/material";
import moment from "moment/moment";
import ReceivingPacking from "./ReceivingPacking";
import ReceivingProducts from "./ReceivingProducts";
import { toast } from "react-toastify";
import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { useDispatch } from "react-redux";

const position = { of: "#packingminiGridContainer" };

const PackingDetail = (props) => {
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [key, setKey] = useState("packings");
  const [loadPanelVisible, setLoadPanelVisible] = useState(false);
  const [receiveData, setReceivingData] = useState({});
  const [actionToggle, setActionToggle] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  useEffect(() => {
    props.location?.hash.length > 0 &&
      setKey(props.location?.hash?.replace("#", ""));
  }, [props.location]);

  const handleTabs = (k) => {
    setKey(k);
    const routePath = props.location.pathname + "#" + k;
    props.history.push(routePath);
  };

  const onHandleMoreOperation = async (key) => {
    // Add your logic for handling more operations here
  };

  const handleActions = (action) => {
    if (action === "delete") {
      setShowDeletePopup(true);
    }
    if (action === "complete") {
      axios(
        `${BaseURLDev}/ShipmentManifests/bulk-mark-complete?companyGuid=${localStorage.companyGuid}`,
        {
          method: "POST",
          data: { guids: [receiveData.GUID] },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
          },
        }
      )
        .then((res) => {
          // props.history.push(`/receiving`);
          setActionToggle(!actionToggle);
        })
        .catch(function (error) {
          toast.error(`An error occured while updating the Records`);
        });
    }
    if (action === "incomplete") {
      axios(
        `${BaseURLDev}/ShipmentManifests/bulk-mark-incomplete?companyGuid=${localStorage.companyGuid}`,
        {
          method: "POST",
          data: { guids: [receiveData.GUID] },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
          },
        }
      )
        .then((res) => {
          setActionToggle(!actionToggle);
        })
        .catch(function (error) {
          toast.error(`An error occured while updating the Records`);
        });
    }
  };

  useEffect(() => {
    const receiving_id = props.match.params.receiving_id;
    setLoadPanelVisible(true);
    axios(`${BaseURLMock}/ShipmentManifests/${receiving_id}`, {
      method: "GET",
      params: {
        companyGuid: localStorage.companyGuid,
      },
      headers: {
        Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
      },
    })
      .then((res) => {
        setLoadPanelVisible(false);
        setReceivingData(res.data.data);
      })
      .catch(function (error) {
        if (error.response.status === 401) {
          localStorage.removeItem("@login_details");
          localStorage.removeItem("custodyToken");
          window.location.reload();
        }
      });
  }, [actionToggle]);

  const deleteButtonOptions = {
    text: "Ok",
    onClick: () =>
      axios(
        `${BaseURLDev}/ShipmentManifests/bulk-delete-shipments?companyGuid=${localStorage.companyGuid}`,
        {
          method: "DELETE",
          data: { guids: [receiveData.GUID] },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
          },
        }
      )
        .then((res) => {
          props.history.push(`/receiving`);
        })
        .catch(function (error) {
          toast.error(`An error occured while updating the Records`);
        }),
  };

  const closeButtonOptions = {
    text: "Cancel",
    onClick: () => setShowDeletePopup(false),
  };

  return (
    <div style={{ minHeight: "100vH" }}>
      <Popup
        visible={showDeletePopup}
        onHiding={() => {}}
        dragEnabled={false}
        hideOnOutsideClick={false}
        showCloseButton={false}
        showTitle={false}
        width={400}
        height={140}
      >
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          options={deleteButtonOptions}
        />
        <ToolbarItem
          widget="dxButton"
          toolbar="bottom"
          options={closeButtonOptions}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <div>
            <ErrorOutlinedIcon fontSize="large" color="error" />
          </div>
          <div style={{ marginLeft: "15px", marginTop: "10px" }}>
            <p>Are you sure you want to delete?</p>
          </div>
        </div>
      </Popup>
      <LoadPanel
        shadingColor="rgba(0,0,0,0.4)"
        position={position}
        visible={loadPanelVisible}
        showIndicator={true}
        shading={false}
        showPane={true}
      />
      <Row className="drug-detail-table-actions">
        <Col lg="12">
          <div>
            <div className="drug-detail-action align-items-start">
              <div className="col">
                <div className="dx-field-label w-100 mt-2">
                  <h6
                    className={` ${
                      receiveData && receiveData.isComplete
                        ? "text-success"
                        : "text-danger"
                    }`}
                  >
                    <h4 class="mr-1 badge badge-light" style={{fontSize:"14px"}}>
                      {receiveData && receiveData.qtyReceived} of{" "}
                      {receiveData.qtyShipped} (
                      {(receiveData.qtyReceived / receiveData.qtyShipped) * 100}
                      %)
                    </h4>
                    {receiveData && receiveData.isComplete
                      ? "COMPLETED"
                      : "INCOMPLETE"}{" "}
                  </h6>
                </div>
              </div>
              <>
                <ReceivingDialog
                  receiving_id={props.match.params.receiving_id}
                  setActionToggle={setActionToggle}
                  actionToggle={actionToggle}
                />
              </>
              <DropdownButton
                className="drag_more_menu d-none d-sm-block"
                variant="info"
                title="More"
                onClick={(e) => onHandleMoreOperation(e.target.value)}
              >
                <Dropdown.Item
                  as="button"
                  onClick={() => handleActions("delete")}
                  value="delete"
                >
                  Delete
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  value="complete"
                  onClick={() => handleActions("complete")}
                >
                  Mark as Complete
                </Dropdown.Item>
                <Dropdown.Item
                  as="button"
                  value="incomplete"
                  onClick={() => handleActions("incomplete")}
                >
                  Mark as Incomplete
                </Dropdown.Item>
              </DropdownButton>
              <span className="d-none d-sm-block">
                <CloseButton history={props.history} path="/receiving" />
              </span>
            </div>
            <Row
              className={`dx-fieldset flex-nowrap overflow-auto my-0 ${
                receiveData && receiveData.isComplete
                  ? "border-left-success"
                  : "border-left-danger"
              }`}
            >
              <Col lg="12" sm="12">
                <Grid container>
                  <Grid item xs={4}>
                    <div className="dx-field">
                      <div className="dx-field-label w-100">Purchase Order</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.purchaseOrderNumber}
                        </div>
                      </div>
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className="dx-field">
                      <div className="dx-field-label w-100">Buyer</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.destinationParty?.name}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="dx-field">
                      <div className="dx-field-label w-100">ASN #</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.advancedShipmentNoticeNumber}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  {/* </Grid>
                <div className='dx-fieldset-row d-flex justify-content-between'> */}
                  <Grid item xs={4}>
                    <div className="dx-field ">
                      <div className="dx-field-label w-100">Invoice #</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.invoiceNumber}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="dx-field ">
                      <div className="dx-field-label w-100">Seller</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.sourceParty?.name}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="dx-field ">
                      <div className="dx-field-label w-100">Ship Date</div>
                      <div className="dx-field-value w-100">
                        <div id="operatortickets">
                          {receiveData?.shipDate &&
                            moment(receiveData?.shipDate).format("YYYY-MM-DD")}
                        </div>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </Col>
              <div className="clear"></div>
            </Row>
            <div className="my-2">
              <OverviewProductTabs
                TabsDetails={[
                  {
                    name: "Details",
                    EventKey: "details",
                    Component: (
                      <OverViewTab
                        OverViewTabsData={[
                          {
                            name: "purchase Order",
                            value: receiveData?.purchaseOrderNumber,
                          },
                          {
                            name: "Invoice #",
                            value: receiveData?.invoiceNumber,
                          },
                          {
                            name: "ASN #",
                            value: receiveData?.advancedShipmentNoticeNumber,
                          },
                          {
                            name: "Buyer",
                            value: receiveData?.destinationParty?.name,
                          },
                          {
                            name: "Seller",
                            value: receiveData?.sourceParty?.name,
                          },
                          {
                            name: "Ship From",
                            value: receiveData?.shipFromLocation?.name,
                          },
                          {
                            name: "Ship To",
                            value: receiveData?.shipToLocation?.name,
                          },
                          {
                            name: "Ship Date",
                            value:
                              receiveData?.shipDate &&
                              moment(receiveData?.shipDate).format(
                                "YYYY-MM-DD"
                              ),
                          },
                          {
                            name: "QTY Shipped",
                            value: receiveData?.qtyShipped,
                          },
                          {
                            name: "QTY Received",
                            value: receiveData?.qtyReceived,
                          },
                          {
                            name: "Completed",
                            value:
                              receiveData && receiveData.isComplete
                                ? "COMPLETED"
                                : "INCOMPLETE",
                          },
                          {
                            name: "Status",
                            value: receiveData?.statusCode ?? "--",
                          },
                          {
                            name: "Received Date",
                            value:
                              receiveData?.receiveDate &&
                              moment(receiveData?.receiveDate).format(
                                "YYYY-MM-DD"
                              ),
                          },
                          {
                            name: "Shipment ID",
                            value: receiveData?.shipmentNumber ?? "--",
                          },
                        ]}
                      />
                    ),
                  },
                  {
                    name: "Packings",
                    EventKey: "packings",
                    Component: (
                      <ReceivingPacking
                        receiving_id={props.match.params.receiving_id}
                        actionToggle={actionToggle}
                      />
                    ),
                  },
                  {
                    name: "Products",
                    EventKey: "products",
                    Component: (
                      <ReceivingProducts
                        receiving_id={props.match.params.receiving_id}
                        actionToggle={actionToggle}
                      />
                    ),
                  },
                ]}
                handleTabs={handleTabs}
                tabKey={key}
              />
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default PackingDetail;
