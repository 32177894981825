import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AllList } from "../../commonComponents/DropDown";
import AssetsService from "service/AssetsService";
import AssetDetailMainTableContainer from "container/Assets/AssetDetailMainTableContainer";
import AssetDetailOverviewContainer from "container/Assets/AssetDetailOverviewContainer";
import AssetItemOverviewContainer from "container/Assets/AssetItemOverviewContainer";
import ProductServices from "service/CommonServices";
import { Button as DxButton } from "devextreme-react/button";
import DataGrid, {
  Scrolling,
  Selection,
  Pager,
  Paging,
  Column,
  HeaderFilter,
  StateStoring,
  ColumnChooser,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Paper } from "@mui/material";
import { detailColumns } from "columns/detailColumn";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { BaseURL } from "Constant/BaseURL";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const renderGridCell = (data) => {
  return data.text == "" ? (
    ""
  ) : getDateColor(data.text, 2) ? (
    <div style={{ color: "red" }}>{getTime(data.text)}</div>
  ) : getDateColor(data.text, 5) ? (
    <div style={{ color: "#E8912D" }}>{getTime(data.text)}</div>
  ) : (
    <div style={{ color: "black" }}>{getTime(data.text)}</div>
  );
};
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=asset`,
});
class AssetsDetails extends Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
      categoryStatus: "Active",
      categoryName: "",
      pageIndex: "",
    };
  }
  getAssets = (params = {}) => {
    let { pageIndex, pageSize } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "asset",
      ...params,
    };
    this.props.getAssetList(obj);
  };

  componentDidMount() {
    // this.sync();
    this.props.getAllCategory();
  }

  sync = async (params = {}) => {
    // await this.getAssets(params);
    // await this.props.getActiveCategory("asset");
  };

  clearFilter = () => {
    this.setState({
      selectedItem: "",
    });
  };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };
  onPageIndexChange = async (pageIndex) => {
    this.props.changeAssetsCommonPageIndex(pageIndex);
    // this.sync();
  };

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
    });
  };

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  handleSelect = async (val) => {
    await this.setState({
      selectedItem: val,
    });
    await this.props.changePage(0);
    val === "All Assets"
      ? this.getAssets()
      : val === "0" || val === "1"
      ? this.sync({
          isActive: parseInt(val),
        })
      : this.sync({
          isActive: "1",
          category: val,
        });

    // this.sync({
    //   isActive : parseInt(val)
    // });
  };

  // handleSelect = async (e, type) => {
  //   const {
  //     getFilteredAssetsList,
  //     filterProductByCategory,
  //     pageIndex,
  //     pageSize,
  //   } = this.props;
  //   const value = e.target.value;
  //   let obj = {
  //     rows: pageSize,
  //     page: pageIndex,
  //     field: "name",
  //     sortOrder: "ASC",
  //     type: parseInt(value),
  //   };
  //   value === "All Assets"
  //     ? this.sync()
  //     : this.sync({
  //       isActive : parseInt(value)
  //     })
  //   this.setState({ categoryStatus: status });
  // };
  onRowClick(e) {
    this.props.history.push({
      pathname: `/asset/${e.key}`,
      state: {
        assetName: e.data.name,
      },
    });
    e.component.selectRows(e.key);
  }

  DiffCell(cellData) {
    return (
      <div>
        {cellData.data.image ? (
          <img
            src={cellData.data.image}
            id={cellData.data.GUID}
            height="50"
            width="50"
          ></img>
        ) : (
          <img src={Placeholder} height="50" width="50"></img>
        )}
      </div>
    );
  }
  render() {
    const { match, location, activeCategories, assetCommonPageIndex } =
      this.props;
    const { dropdownOpen, selectedItem, categoryStatus } = this.state;
    let { assetName } = location?.state ? location?.state : "";
    let isAssetItemTagPage = false;
    let asset_id;
    let asset_item_tag_id;
    /**
     * To display main table in all screen,
     * drug_id and drug_item_id is inserted into the URL to switch between the pages
     */
    asset_id = match.params.assetId;

    if (match.params.asset_item_id) {
      asset_item_tag_id = match.params.asset_item_id;
    }
    return (
      <Row className="drug-detail-table-actions" style={{ paddingLeft: "5px" }}>
        <Col lg="3 quick-navigation">
          {/* <Col
            lg="12"
            className="drug-detail-table-inner-actions overview_table"
          > */}
          <Row>
            <Col lg="4" md="3" sm="2">
              {/* <AllList
                name="Assets"
                handleSelect={this.handleSelect}
                toggleFilter={this.toggleFilter}
                dropdownOpen={dropdownOpen}
                selectedItem={selectedItem}
                categoryStatus={categoryStatus}
                list={activeCategories}
              /> */}
            </Col>
            <Col lg="8" md="6" sm="4" className="table-inner-actions">
              {/* <div>
                <Button variant="primary">
                  <Link to="/addAsset"> New </Link>
                </Button>
              </div> */}
              {/* <div className="sync">
                <Button
                  style={{
                    padding: "2px 9px",
                    backgroundColor: "#f5f5f5",
                    borderColor: "#ddd",
                  }}
                  onClick={() => {
                    this.sync();
                    this.dataGrid.current.instance.refresh();
                  }}
                >
                  <i className="fas fa-sync"></i>
                </Button>
              </div> */}
            </Col>
          </Row>
          {/* <AssetDetailMainTableContainer asset_id={asset_id} /> */}
          <Paper>
            <DataGrid
              id="miniGridContainer"
              dataSource={dataSource}
              keyExpr="GUID"
              showBorders={false}
              hoverStateEnabled={true}
              // rowAlternationEnabled={true}
              ref={this.dataGrid}
              onRowClick={(e) => {
                this.onRowClick(e);
              }}
              onRowDblClick={(e) => {
                this.onRowClick(e);
              }}
              onContentReady={(e) => {
                if (!e.component.getSelectedRowKeys().length) {
                  e.component.selectRows(asset_id);
                }
              }}
            >
              <ColumnChooser enabled={true} mode={"select"} width={0} />
              <StateStoring
                enabled={true}
                type="localStorage"
                storageKey="assets-main-grid"
                savingTimeout={100}
              />
              <Column
                dataField="IMAGE"
                cssClass="hide-asset-detail-column"
              ></Column>
              <Column dataField="name" caption="NAME" />
              <Column
                dataField="manufacturer"
                cssClass="hide-asset-detail-column"
              />
              <Column
                dataField="productCode"
                cssClass="hide-asset-detail-column"
              />
              <Column
                dataField="UNSPSCCode"
                cssClass="hide-asset-detail-column"
              />
              <Column
                dataField="modelNumber"
                cssClass="hide-asset-detail-column"
              />
              <Column dataField="brand" cssClass="hide-asset-detail-column" />
              <Column
                dataField="inventoryQuantity"
                cssClass="hide-asset-detail-column"
              />
              <Column
                dataField="expiryDate"
                cssClass="hide-asset-detail-column"
              />
              <HeaderFilter visible={false} allowSearch={false} />
              <Scrolling rowRenderingMode="virtual"></Scrolling>
              <Paging
                defaultPageSize={this.props.pageSize}
                onPageSizeChange={this.onPageSizeChange}
              />
              <Pager
                visible={true}
                allowedPageSizes={allowedPageSizes}
                displayMode={"adaptive"}
                showPageSizeSelector={true}
                showInfo={true}
                showNavigationButtons={true}
              />
              {/* <Toolbar visible={false}>
                <Item name="columnChooserButton" location="after" />
              </Toolbar> */}
              <Toolbar>
                <Item location="after">
                  <Button variant="primary">
                    <Link to="/addAsset"> + New</Link>
                  </Button>
                </Item>
                <Item location="after">
                  <DxButton
                    hint="Refresh"
                    icon="refresh"
                    onClick={() => {
                      this.sync();
                      this.dataGrid.current.instance.refresh();
                    }}
                  />
                </Item>
              </Toolbar>
            </DataGrid>
          </Paper>
        </Col>
        {match.params.asset_item_id ? (
          <Col lg="9" className="drug-item-detail-overview">
            <AssetItemOverviewContainer
              assetName={assetName}
              asset_id={asset_id}
              asset_item_tag_id={asset_item_tag_id}
            ></AssetItemOverviewContainer>
          </Col>
        ) : (
          <Col lg="9" className="detail-overview">
            <AssetDetailOverviewContainer
              assetName={assetName}
              asset_id={asset_id}
            ></AssetDetailOverviewContainer>
          </Col>
        )}
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    assetsList,
    activeCategories,
    rows,
    pageIndex,
    pageSize,
    assetCommonPageIndex,
  } = state.assetsReducer;
  return {
    activeCategories,
    assetsList: assetsList,
    rows,
    pageIndex,
    pageSize,
    assetCommonPageIndex,
  };
};
/**
 * To perform add,get,delete and update action on drugs
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getAssetList: (obj) =>
      dispatch(ProductServices.getAllAssetsProductList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    getFilteredAssetsList: (type) =>
      dispatch(AssetsService.getFilteredAssetsList(type)),
    changePage: (pageNo) => dispatch(AssetsService.changeAssetsPage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(AssetsService.changeAssetsPageSize(pageSize)),
    changeAssetsCommonPageIndex: (gridIndex) =>
      dispatch(AssetsService.changeAssetsCommonPage(gridIndex)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetsDetails);
