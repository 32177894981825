import OverviewProductTable from "commonComponents/OverviewProductTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import UserServices from "service/UserServices";
import ProductServices from "service/CommonServices";
import { Col, Row } from "react-bootstrap";
import OverviewHeader from "commonComponents/Overview/OverviewHeader";
import OverviewProductTabs from "commonComponents/OverviewProductTabs";
import { VehicleMovementHistory } from "commonComponents/VehicleMovementHistory";
import VehicleCurrentInventory from "../../commonComponents/VehicleCurrentInventory";
import VehicleChecklistHistory from "../../commonComponents/VehicleChecklistHistory";
import OverViewTabVehicle from "commonComponents/OverviewVehicle";
import { getDateTime } from "commonMethod/common";
import { get } from "lodash";
import vehiclesService from "../../service/VehiclesServices";
import VehicleList from "./VehicleList";

export class VehicleDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      columnModal: false,
      selectedItem: "",
      selectedUser: "",
      categoryStatus: "",
      key: "overview",
      pageSize: 10,
      pageIndex: 1,
    };
  }

  sync = async (newObj = {}) => {
    const { pageSize, pageIndex, match, vehicleList } = this.props;
    const { vehicleId } = match.params;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "GUID",
      sortOrder: "ASC",
      ...newObj,
    };
    await this.props.getVehicleList(obj);
    await this.handleGetVehicleDetails(vehicleId);
    await this.handleGetCurrentInventory(vehicleId);
    await this.handleGetVehicleMovementHistory(vehicleId);
    await this.handleGetVehicleChecklistHistory(vehicleId);
  };

  onSortedChange = (sorted) => {
    const { pageSize, pageIndex } = this.props;
    const { id, desc } = sorted[0];
    const obj = {
      rows: pageSize,
      page: pageIndex,
      field: id,
      sortOrder: desc ? "DES" : "ASC",
    };
    this.sync(obj);
  };

  // onMovementSortedChange = (sorted) => {
  //   const { usersDetails } = this.props;
  //   const { pageSize, pageIndex } = this.state;
  //   const { id, desc } = sorted[0];
  //   const obj = {
  //     rows: pageSize,
  //     page: pageIndex,
  //     field: id,
  //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
  //     sortOrder: desc ? "DES" : "ASC",
  //   };
  //   this.handleGetMovementHistory(obj);
  // };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.sync();
  };

  clearOnClick = async () => {
    const { vehicleId } = this.props.match.params;
    await this.props.clearItemTag(vehicleId);
    this.sync();
  };

  handleSelectDropDown = async (val) => {
    // await this.props.changePage(0);
    val === "All Vehicles"
      ? this.sync()
      : this.sync({
          isActive: parseInt(val),
        });
  };

  handleSelect = async (val) => {
    // await this.props.changePage(0);
    val === "All Vehicles"
      ? this.sync()
      : this.sync({
          isActive: parseInt(val),
        });
  };

  handleGetVehicleDetails = (id) => {
    const { getVehicleDetails } = this.props;

    getVehicleDetails(id);
  };

  handleGetCurrentInventory = (id) => {
    const { getCurrentInventory } = this.props;

    getCurrentInventory(id);
  };

  handleGetVehicleMovementHistory = (id) => {
    const { getVehicleMovementHistory } = this.props;

    getVehicleMovementHistory(id);
  };

  handleGetVehicleChecklistHistory = (id) => {
    const { getVehicleChecklistHistory } = this.props;

    getVehicleChecklistHistory(id);
  };

  // handleGetMovementHistory = (newObj) => {
  //   const { getMovementHistory, usersDetails } = this.props;
  //   const { pageSize, pageIndex } = this.state;
  //   let obj = {
  //     rows: pageSize,
  //     page: pageIndex,
  //     field: "firstSeen",
  //     sortOrder: "DES",
  //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
  //     ...newObj,
  //   };
  //   getMovementHistory(obj);
  // };

  handleTabs = (k) => {
    this.setState({ key: k });
  };

  onHandleMoreOperation = async (key) => {
    const { vehicleDetails } = this.props;
    if (key === "markActive") {
      await this.props.updateUserStatus(
        [vehicleDetails.GUID],
        !vehicleDetails.isActive
      );
      await this.handleGetVehicleDetails(vehicleDetails.GUID);
    } else if (key === "delete") {
      await this.props.deleteBulkVehicles([vehicleDetails.GUID]);
      this.props.history.push("/vehicles");
    }
  };

  handleTdProps = (rowInfo) => {
    this.handleGetVehicleDetails(rowInfo.original.GUID);
    this.props.history.push({
      pathname: `/vehicle/${rowInfo.original.GUID}#${this.state.key}`,
      state: {
        assetName: rowInfo.original.name,
      },
    });
    this.handleGetCurrentInventory(rowInfo.original.GUID);
    this.handleGetVehicleMovementHistory(rowInfo.original.GUID);
    this.handleGetVehicleChecklistHistory(rowInfo.original.GUID);
  };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.setState({ pageIndex }, () => this.sync());
  };

  handleTrProps = (rowInfo) => {
    const { vehicleId } = this.props.match.params;

    return {
      style: {
        background: rowInfo.original.GUID === vehicleId && "#EBECF0",
      },
      className: "table-row",
    };
  };
  handleTrPropsCheck = (rowInfo) => {
    const { vehicleId } = this.props.match.params;

    return {
      style: {
        background: rowInfo.original.GUID === vehicleId && "#EBECF0",
      },
      className: "table-row",
    };
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key !== this.state.key) {
      const routePath = this.props.location.pathname + "#" + this.state.key;
      this.props.history.push(routePath);
    }
  }

  componentDidMount() {
    if (this.props.location.hash) {
      this.setState({
        key: this.props.location.hash.slice(1),
      });
    }
    this.sync();
    console.log(this.props.location);
  }

  render() {
    const {
      vehicleList,
      usersDetails,
      vehicleDetails,
      inventory,
      checklistHistory,
      movementHistory,
      pageIndex,
      total,
      match,
      pageSize,
      currentPage,
      movementList,
      isActive,
      isActiveFlage,
      loginUser,
    } = this.props;
    const { key } = this.state;
    const { vehicleId } = match.params;
    const OverViewTabsData = [
      // {
      //   type: "image",
      //   value: vehicleDetails.image,
      // },
      {
        name: "Name",
        value: vehicleDetails.name,
      },
      {
        name: "Unit ID",
        value: vehicleDetails.vehicleUnitId,
      },
      {
        name: "Radio ID",
        value: vehicleDetails.radioId,
      },
      {
        name: "Type",
        value: vehicleDetails.vehicleType,
      },
      {
        name: "License Plate",
        value: vehicleDetails.licensePlateNumber,
      },
      {
        name: "Vehicle Status",
        value: vehicleDetails.vehicleStatus,
      },
      {
        name: "Vehicle Model",
        value: vehicleDetails.vehicleModel,
      },
      {
        name: "Model Year",
        value: vehicleDetails.vehicleModelYear,
      },
      {
        name: "Service Date",
        value: vehicleDetails?.serviceDate
          ? getDateTime(vehicleDetails?.serviceDate)
          : "",
      },
    ];
    const TabsDetails = [
      {
        name: "Overview",
        EventKey: "overview",
        Component: <OverViewTabVehicle OverViewTabsData={OverViewTabsData} />,
      },

      {
        name: "Current Inventory",
        EventKey: "inventory",
        Component: (
          <VehicleCurrentInventory
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
            inventory={inventory}
            clearOnClick={this.clearOnClick}
          />
        ),
      },
      {
        name: "Inventory Movement",
        EventKey: "movement",
        Component: (
          <VehicleMovementHistory
            pageSize={pageSize}
            total={total}
            pageIndex={pageIndex}
            onPageChange={this.onPageChange}
            onPageSizeChange={this.onPageSizeChange}
            movementList={movementHistory}
          />
        ),
      },
      {
        name: "Checklist History",
        EventKey: "checklist",
        Component: (
          <VehicleChecklistHistory
            historyData={checklistHistory}
            // pageSize={pageSize}
            // total={total}
            // pageIndex={pageIndex}
            // onPageChange={this.onPageChange}
            // onPageSizeChange={this.onPageSizeChange}
            handleTrPropsCheck={this.handleTrPropsCheck}
          />
        ),
      },
    ];

    const role = this.props.systemRole;
    console.log(role);
    return (
      <Row className="drug-detail-table-actions">
        <Col lg="3 quick-navigation">
          <VehicleList
            tableCol={12}
            handleTrProps={this.handleTrProps}
            getVehicleDetails={this.getVehicleDetails}
            handleTdProps={this.handleTdProps}
            name={"Vehicles"}
            history={this.props.history}
            addButtonURl={"/addVehicle"}
            tableData={vehicleList}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
            pages={total / pageSize}
            currentPage={currentPage}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
            handleSelect={this.handleSelect}
            onSortedChange={this.onSortedChange}
            userId={vehicleId}
            onHandleMoreOperation={this.onHandleMoreOperation}
            selectedProduct={usersDetails}
            handleTabs={this.handleTabs}
            tabKey={key}
            module="vehicle"
          />
        </Col>

        <Col lg="9" className="drug-item-detail-overview">
          {/* {
            (role == 'Staff' && usersDetails.systemRole == 'Staff') || (role == 'Owner' && usersDetails.systemRole == 'Owner') || (role == 'Staff' && usersDetails.systemRole == 'Owner')
              ? <Row className="drug_title">
                <h3 className="drug_name" style={{ marginBottom: "10px" }}>{vehicleDetails && vehicleDetails.name}</h3>
                {isActiveFlage && <h4 className={`drug_status ${isActive ? "" : 'drug_inactive'}`}>{isActive ? "" : <CategoryChip variant="secondary" name="INACTIVE" />}</h4>}
              </Row> */}
          <OverviewHeader
            editPath={`/editVehicle/${vehicleId}`}
            history={this.props.history}
            title={vehicleDetails && vehicleDetails.name}
            isActive={vehicleDetails.isActive}
            onHandleMoreOperation={this.onHandleMoreOperation}
            closeButtonPath={`/${"Vehicles".toLowerCase()}`}
          />

          <OverviewProductTabs
            TabsDetails={TabsDetails}
            handleTabs={this.handleTabs}
            tabKey={key}
          />
        </Col>
      </Row>
    );
  }
}

const mapStateToProps = (state) => {
  const { usersList, userColumns, usersMovementHistory, usersDetails } =
    state.usersReducer;
  const {
    vehicles,
    total,
    currentPage,
    vehicleDetails,
    pageSize,
    pageIndex,
    rows,
    columns,
    sortInfo,
  } = state.vehicleReducer;
  const { movementList } = state.movementHistoryReducer;
  const { inventory } = state.inventoryReducer;
  const { movementHistory } = state.vehicleMovementHistoryReducer;
  const { checklistHistory } = state.vehicleChecklistHistoryReducer;
  console.log(Object.keys(inventory).length);
  return {
    usersList,
    vehicleList: vehicles,
    checklistHistory,
    userColumns,
    inventory,
    pageSize,
    movementHistory,
    pageIndex,
    currentPage,
    usersMovementHistory,
    usersDetails,
    vehicleDetails,
    rows,
    total,
    movementList,
    userData: state.loginReducer.userData,
    systemRole: get(state, "loginReducer.userData.systemRole"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (obj) => dispatch(UserServices.getAllUserList(obj)),
    getVehicleList: (obj) => dispatch(vehiclesService.getVehicleList(obj)),
    getCurrentInventory: (vehicleId) =>
      dispatch(vehiclesService.getCurrentInventory(vehicleId)),
    getVehicleMovementHistory: (vehicleId) =>
      dispatch(vehiclesService.getVehicleMovementHistory(vehicleId)),
    getVehicleChecklistHistory: (vehicleId) =>
      dispatch(vehiclesService.getVehicleChecklistHistory(vehicleId)),
    // getMovementHistory: (obj) =>
    //   dispatch(ProductServices.getMovementHistory(obj)),
    getVehicleDetails: (vehicleId) =>
      dispatch(vehiclesService.getVehicleDetails(vehicleId)),
    updateUserStatus: (ids, isActive) =>
      dispatch(UserServices.updateUserStatus(ids, isActive)),
    changePage: (pageNo) =>
      dispatch(vehiclesService.changePageVehicles(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(vehiclesService.changePageSizeVehicles(pageSize)),
    deleteBulkVehicles: (ids) =>
      dispatch(vehiclesService.deleteBulkVehicles(ids)),
    clearItemTag: (vehicleId) =>
      dispatch(vehiclesService.clearItemTag(vehicleId)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(VehicleDetails);
