import React from 'react';
import ReactTable from 'react-table-6';
import { withRouter } from "react-router";
import 'react-table-6/react-table.css';
import { Container, Row, Col } from "react-bootstrap";
import { getTime, getDateColor } from 'commonMethod/common';
import Pagination from "../../commonComponents/Pagination";
import equipmentChecklistColumnBuilder from 'views/equipmentChecklists/EquipmentChecklistColumnBuilder';
import Placeholder from '../../assets/img/placeholder-picture-image.png';

class EquipmentChecklistsTableContainer extends React.Component {
    disposable = [];
    constructor(props) {
        super(props);
    }

    createAndReturnCheckBox = () => {
        return (this.props.tableData?.length > 0) ?
            this.props.tableData.map((data) => {
                data['check'] = (
                    <div style={{ 'textAlign': 'center' }}>
                        <input type="checkbox" name={data.GUID}
                            checked={this.props.selectedIdProps.includes(data.GUID)}
                            onChange={(event) => this.handleClickGroup(data.GUID)} />
                    </div>
                )
                return data;
            })
            : []
    }

    // Filter the data based on user search
    getPosts = () => {
        let { searchKeyword, columns } = this.props;
        let allTrueColumns = {};
        columns.map(item => {
            allTrueColumns[item.accessor] = item.checked
        });
        const list = this.createAndReturnCheckBox();
        this.data = list;
        return this.data;
    }

    /**
     * Handle check box onchange event
     * @param {*} id 
     */
    handleClickGroup = (id) => {
        let list = [...this.props.selectedIdProps];
        const isPresent = list.includes(id);
        let ids = [];
        if (isPresent) {
            const index = list.indexOf(id);
            list.splice(index, 1);

        } else {
            list.push(id);
        }

        if (list.length !== this.props.tableData.length) {
            this.props.getMultiSelected(false);
        } else {
            this.props.getMultiSelected(true);
        }

        this.props.getSelectedIds(list);
    }

    /**
     * Handle check box onchange event
     * @param {*} id 
     */
    handleMultiSelect = () => {
        const { pageIndex, pageSize } = this.props;
        let ids = this.props.isMultiSelectedProps ?
            [] :
            this.data.slice(pageSize * pageIndex, pageSize * (pageIndex + 1)).map((row) => {
                return row.GUID
            });
        this.props.getMultiSelected(!this.props.isMultiSelectedProps)
        this.props.getSelectedIds(ids);
    }

    /**
     *  Return the selected pageSize
     * @param {*} pageSize 
     * @param {*} pageIndex 
     */
    onPageSizeChange = (pageSize, pageIndex) => {
        this.props.onPageSizeChange(pageSize, pageIndex);
    }

    onPageChange = (pageIndex) => {
        this.props.onPageChange(pageIndex);
    }

    onSortedChange = (sorted) => {
        const { onSortChange } = this.props;
        onSortChange && onSortChange(sorted)
    }

    render() {
        const { pages, total, currentPage, pageSize, pageIndex } = this.props;

        return (

            <Container fluid>
                <Col md="">
                    <ReactTable
                        manual
                        columns={equipmentChecklistColumnBuilder({ columns: this.props.columns })}
                        onSortedChange={this.onSortedChange}
                        pageSize={this.props.pageSize}
                        loadingText="Loading the checklists"
                        noDataText="Couldn't find checklists"
                        data={this.getPosts()}
                        pages={Math.ceil(pages)}
                        page={pageIndex}
                        onPageSizeChange={this.props.onPageSizeChange}
                        onPageChange={this.props.onPageChange}
                        getTdProps={(state, rowInfo, column, instance) => ({
                            onClick: () => {
                                if (rowInfo && column.id !== 'check') {
                                    this.props.history.push({
                                        pathname: `/equipmentChecklist/${rowInfo.original.GUID}`
                                    })
                                }
                            }
                        })}
                        getTrGroupProps={(state, rowInfo, column, instance) => {
                            if (rowInfo !== undefined) {
                                return {
                                    style: {
                                        color: !rowInfo.original.isActive && "gray",
                                        background: this.props.selectedIdProps.includes(rowInfo.original.GUID) && '#EBECF0',
                                        background: '#fffff'
                                    },
                                    className: "table-row"
                                }
                            }
                        }
                        }
                    />
                    <Pagination total={total} pageSize={pageSize} currentPage={currentPage} />
                </Col>
            </Container>
        )
    }
}

export default withRouter(EquipmentChecklistsTableContainer);