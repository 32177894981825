import VehiclesTableContainer from "../../container/Vehicles/VehiclesTableContainer";
import EquipmentChecklistsTableContainer from "container/EquipmentChecklists/EquipmentChecklistsTableContainer";
import React from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import ModalDialog from "../../commonComponents/ModalDialog";
import { Link } from "react-router-dom";
import { AllList } from "../../commonComponents/DropDown";
import { BulkAction } from "../../commonComponents/ItemDropDown";
import { connect } from "react-redux";
import ProductServices from "../../service/CommonServices";
import vehiclesService from "../../service/VehiclesServices";
import equipmentChecklistsService from "service/EquipmentChecklistsServices";
import AssetsColumnSelector from "commonComponents/AssetsColumnSelector";
import Multiselect from "multiselect-react-dropdown";
import { EquipmentChecklistsColumns } from "Constant/Column";

class EquipmentChecklists extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      isMultiSelected: false,
      selectedFilter: "",
      columnModal: false,
      name: "",
      selectedIds: [],
      selectedItem: '',
      categoryStatus: "Active",
      categoryName: "",
      categoryList: [],
      isMatched: false,
      dropdownOpen: false,
      bulkActionOpen: false,
      description : "",
      isDescription: false
    };
    // if(this.props.userData && this.props.userData.systemRole === 'Worker'){
    //   this.props.history.push('/login');
    // }
  }

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  /**
   * Perform network request to get drugs list data
   */
  componentDidMount() {
    this.sync();
  }

  /**
   * handle sync action to get latest data from server
   */
  sync = async (params = {}) => {
    const { pageSize, pageIndex, sortInfo } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "GUID",
      sortOrder: "ASC",
      ...params,
    };
    await this.props.getEquipmentCheckList(obj);
  };

  /**
 * Toggle add category modal
 */
  toggleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  handleSubmit = async (e) => {
    const { getProductCategory } = this.props;
    const { selectedIds, categoryList } = this.state;
    // let isDescription = description.length === 0;
    let isMatched = categoryList.length === 0;
    
    if(!isMatched){
      let category = categoryList[0].name; // categoryList.map((item) => item.name);
      let obj = {
        GUIDS: selectedIds,
        name: category,
        // description : description
      }
      await getProductCategory(obj, "drug").then(() => {
        this.sync();
      });
      this.setState({ show: !this.state.show, categoryList: [], selectedIds : [] });
    }else{
      this.setState({ isMatched })
    }
  };

  handleDelete = () => {
    const { deleteBulkChecklists } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      deleteBulkChecklists(selectedIds).then(() => {
        this.sync();
      });
    }
    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleActiveFilter = async(val) => {
    const { markStausBulkActiveInActive } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      let filterActive = val !== "0";
      await markStausBulkActiveInActive(selectedIds, filterActive).then(() => {
        this.sync();
      });
    }
    this.setState({ selectedIds: [], isMultiSelected: false, selectedItem : '' });
  };

  handleSelectAction = async (value) => {
    // await this.props.changePage(0);
    value === "Category"
      ? this.setState({ show: !this.state.show })
      : value === "Delete"
        ? this.handleDelete()
        : value === "0"
          ? this.handleActiveFilter(value)
          : value === "1"
            ? this.handleActiveFilter(value)
            : this.sync();
  };

  handleSelect = async (value) => {
    this.setState({
      selectedItem: value,
    })
    await this.props.changePage(0);
    value === "All Drugs"
      ? this.sync()
      : (value === '0' || value === '1') ? this.sync({
        isActive : parseInt(value)
      }) :  this.sync({
        isActive : '1',
        category: value
      })
  };

  // Return the bulk action value
  getMultiSelected = (e) => {
    this.setState({ isMultiSelected: e });
  };

  handleColumnSelectorModal = () => {
    this.setState({ columnModal: !this.state.columnModal });
  };

  getColumns = async (columns) => {
    this.setState({
      columnModal: false,
    });
    this.props.updateColumns(columns);
  };

  setNewCategory = (categoryName) => {
    this.setState({ categoryName, isMatched: false });
  };


  getSelectedIds = (ids) => {
    this.setState({ selectedIds: ids });
  };

  clearFilter = () => {
    this.setState({
      selectedItem: ''
    })
  }

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false
    })
  }

  clearCategory = () => {
    this.setState({
      categoryList: "",
    })
  }

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      categoryName: "",
      categoryList: selectedList,
      isMatched: false,
    });
  };

  onAddCategory = () => {
    let text = this.state.categoryName;
    let newCategory = [...this.state.categoryList];
    let isMatched = newCategory.find((i) => i.name === text);
    if (!isMatched) {
      newCategory.push({ name: text, id: "" });
      this.setState({
        categoryList: [...newCategory],
        categoryName: "",
      });
    } else {
      this.setState({
        isMatched: true,
      });
    }
  };

  onSortChange = (sorted) => {
    const { updsteSortInfo } = this.props;
    const { id, desc } = sorted[0];
    const obj = {
      field: id,
      sortOrder: desc ? "DES" : "ASC",
    };
    updsteSortInfo && updsteSortInfo({ ...obj })
    this.sync(obj);
  }

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.setState({ pageIndex }, () => this.sync());
  };

  columnSelectionSubmitHandler = () => {

  }

  render() {
    const { vehicles, currentPage, pageSize, vehiclesColumns, pageIndex, total, equipments ,EquipmentChecklistsColumns } = this.props;
    const {
      selectedIds,
      categoryList,
      categoryName,
      categoryStatus,
      columnModal,
      isMultiSelected,
      isMatched,
      selectedItem,
      bulkActionOpen,
      dropdownOpen,
      description,
      isDescription
    } = this.state;

    console.log(equipments);

    return (
      <div>
        <Row className="table-actions">
          {this.state.show && 
          <ModalDialog
            show={this.state.show}
            title={"Add Category"}
            closeDialog={this.toggleModal}
            onSubmit={this.handleSubmit}
          >
            <Col sm="12">
              <Form.Group>
                <Form.Label column sm={"2"}></Form.Label>
                <Col sm={"4"}>
                  <Form.Control
                    value={categoryName}
                    type={"input"}
                    isInvalid={isMatched}
                    placeholder={"Add New Category"}
                    onChange={(e) => {
                      this.setNewCategory(e.target.value); this.clearCategory();
                    }}
                  />
                  {isMatched && (
                    <span column sm={`1`} className="danger-action">
                      {" "}
                      {categoryName + " is already in categories."}
                    </span>
                  )}
                </Col>
                {categoryName !== "" && (
                  <Button variant="primary" onClick={this.onAddCategory}>
                    Add Category
                  </Button>
                )}
              </Form.Group>
              {/* <Form.Group>
                <Form.Label column sm="2">
                  Description
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    value={description}
                    type={"input"}
                    isInvalid={isDescription}
                    placeholder={"description"}
                    name="description"
                    onChange={(e) => {
                      this.setDescription(e.target.value);
                    }}
                  />
                  {isDescription && (
                    <span column sm={`1`} className="danger-action">
                      {" Description is required"}
                    </span>
                  )}
                </Col>
              </Form.Group> */}
            </Col>
          </ModalDialog>}
          <Col lg="4" md="3" sm="2">
            {selectedIds.length === 0 ? null : (
              <BulkAction
                userModule
                handleSelectAction={this.handleSelectAction}
                toggleBulk={this.toggleBulk}
                bulkActionOpen={bulkActionOpen} />
            )}
          </Col>

          <Col lg="8" md="6" sm="4" className="table-inner-actions">
          <div>
              <Button variant="primary">
                <Link to="/addEquipmentChecklist"> + New</Link>
              </Button>
            </div>
            <div className="sync">
              <Button style={{padding:"2px 9px", backgroundColor:"#f5f5f5", borderColor:"#ddd"}} onClick={() => { this.sync(); this.clearFilter(); this.clearMultiselect(); }}>
                <i className="fas fa-sync"></i>
              </Button>
            </div>
            <div className="sync">
              <Button style={{padding:"2px 9px", backgroundColor:"#f5f5f5", borderColor:"#ddd", color:"#212529"}} onClick={this.handleColumnSelectorModal}>
                <i className="fas fa-cog"></i>
              </Button>
            </div>
            {columnModal && 
              <AssetsColumnSelector
                show={columnModal}
                title={'Add Columns'}
                closeDialog={this.handleColumnSelectorModal}
                getColumns={this.getColumns}
                columns={EquipmentChecklistsColumns}
              />
            }
          </Col>
        </Row>
        <div className="drugs-main-table">
          <EquipmentChecklistsTableContainer
            getMultiSelected={this.getMultiSelected}
            tableData={equipments}
            getSelectedIds={this.getSelectedIds}
            selectedIdProps={selectedIds}
            isMultiSelectedProps={isMultiSelected}
            columns={EquipmentChecklistsColumns}
            pageSize={pageSize}
            pageIndex={pageIndex}
            total={total}
            pages={total / pageSize}
            currentPage={currentPage}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
            onSortChange={this.onSortChange}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.equipmentReducer)
  const { equipments, total, currentPage, 
    pageSize, pageIndex, rows, columns, sortInfo } = state.equipmentReducer;
  return {
    sortInfo,
    equipments,
    pageIndex,
    pageSize,
    total,
    currentPage,
    rows,
    EquipmentChecklistsColumns: columns,
    userData: state.loginReducer.userData,
  }
};

/**
 * To perform add,get,delete and update action on drugs
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getEquipmentCheckList: (obj) => dispatch(equipmentChecklistsService.getEquipmentCheckList(obj)),
  //  getVehicleList: (obj) => dispatch(vehiclesService.getVehicleList(obj)),
    updsteSortInfo: (obj)=> dispatch(equipmentChecklistsService.updsteSortInfo(obj)),
    deleteBulkChecklists: (ids) => dispatch(equipmentChecklistsService.deleteBulkChecklists(ids)), 
    getProductFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductStatus(ids, isActive)),
    getFilteredDrugList: (type) =>
      dispatch(drugsService.getFilteredDrugList(type)),
    getProductCategory: (obj, productType) =>
      dispatch(ProductServices.addProductCategory(obj, productType)),
    filterProductByCategory: (category, obj) =>
      dispatch(drugsService.filterProductByCategory(category, obj)),
    changePage : (pageNo) => dispatch(equipmentChecklistsService.changePageChecklists(pageNo)),
    changePageSize : (pageSize) => dispatch(equipmentChecklistsService.changePageSizeChecklists(pageSize)),
    updateColumns : (columns) => dispatch(equipmentChecklistsService.updateColumns({columns})),
    markStausBulkActiveInActive : (ids, active) => dispatch(equipmentChecklistsService.markStausBulkEquipments(ids, active)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EquipmentChecklists);
