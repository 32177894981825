import {getDateTime} from "commonMethod/common";
import {millisToMinutesAndSeconds} from "commonMethod/common"


export const VehicleChecklistHistoryColumns = [
    {
      width: 170,
      Header: 'Timestamp',
      accessor: 'timestamp',
      Cell: ({ row }) => (
        <span>{getDateTime(row._original.timestamp)}</span>
      ),
    },
     {
      width: 120,
      Header: 'Checklist',
      accessor: 'equipmentChecklistName',
    }, 
    {
      width: 120,
      Header: 'Vehicle',
      accessor: 'vehicleName',
    },
    {
      width: 120,
      Header: 'duration',
      accessor: 'timeIntervalMillis',
      Cell: ({ row }) => (
        <span>{millisToMinutesAndSeconds(row._original.timeIntervalMillis)}</span>
      ),
      
    },
    {
      width: 120,
      Header: 'Qty Expected',
      accessor: 'qtyExpected',
    },
    {
        width: 120,
        Header: 'Qty Recorded',
        accessor: 'qtyRecorded',
      }, {
        width: 120,
        Header: 'Completed',
        accessor: 'isCompleted',
      },
      {
        width: 120,
        Header: 'Person',
        accessor: 'person.name',
      },
      {
        width: 120,
        Header: 'Supervisor',
        accessor: 'supervisor.name',
      },
      {
        width: 120,
        Header: 'Comment',
        accessor: 'comments',
      },
  ];