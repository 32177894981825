import { VehiclesColumns } from "Constant/Column";

/**
 * Initial state for the drugs which is empty.
 */
const initialState = {
  inventory: [],
};

export default function inventoryReducer(state = initialState, action) {
    switch(action.type) {
     
      case 'GET_CURRENT_INVENTORY' : 
      return {...state, inventory :  action.payload }

      default:
        return state;
    }
  }