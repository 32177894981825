import { ProductColumns } from "Constant/Column";

/**
 * Initial state for the drugs which is empty.
 */
const initialState = {
  products: [],
  columns : [...ProductColumns],
  sortInfo: { field: 'name', sortOrder: 'ASC' }
};

export default function productReducer(state = initialState, action) {
    switch(action.type) {
      case 'GET_PRODUCT_LIST_SUCCESS':
        let { data, count, items, page, rows } = action.payload;
        return {...state, products: data, total: count, currentPage: page, rows: rows };


      default:
        return state;
    }
  }