import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import Input  from '../commonComponents/Input';
import ProductServices from "../service/CommonServices";

const AllFilter = (props) => {
  const { handleItemSelect, toggleFilter, dropdownOpen, selectedItem, selectedKey,
    selectStartDate, selectEndDate, id, lotNumber, locationList } = props;

    useEffect(async() => {
      await props.getAllLotNumber(id);
      await props.getAllLocation(id);
    },[id]);

  return (
    
      <Dropdown  className="drop-down">
      <Dropdown.Toggle>
        {
         selectedKey === "lotNumber" ? 'Lot: ' + selectedItem
        : "Type: All"
        }
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item value={"All Items"} className={`${selectedItem === 'All Items' && 'active'}`} onClick={(e) => handleItemSelect("AllItems", e.target.value)}>All Items</Dropdown.Item>
        <Dropdown.Item header>By Lots</Dropdown.Item>
          {lotNumber.length > 0 && lotNumber.map(lotNum =>
            <Dropdown.Item className={`lot_nom_item ${selectedItem === lotNum && 'active'}`} value={lotNum} 
            onClick={(e) => handleItemSelect("lotNumber", e.target.value)}>
              {lotNum}
            </Dropdown.Item>
          )}


      </Dropdown.Menu>
    </Dropdown>

   


  );
}

const mapStateToProps = (state) => {
  const { lotNumber, locationList } = state.lotNumberReducer;
  return {
      lotNumber,
      locationList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLotNumber: (id) => dispatch(ProductServices.getAllLotNumber(id)),
    getAllLocation: (id) => dispatch(ProductServices.getAllLocations(id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AllFilter)

// export default AllItemFilter;