import React from "react";
import Placeholder from "../../assets/img/placeholder-picture-image.png";
import { Col, Container } from "react-bootstrap";
import Badge from "react-bootstrap/Badge";
import { BulkDeleteAction } from "../../commonComponents/ItemDropDown";
import { connect } from "react-redux";
import ProductServices from "../../service/CommonServices";
import PackingsService from "../../service/PackingsService";
import ReceivingService from "../../service/ReceivingService";
import { Button as DxButton } from "devextreme-react/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { jsPDF } from "jspdf";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  TotalItem,
  Position,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import { BaseURLDev, BaseURLMock } from "../../Constant/BaseURL";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid } from "devextreme/excel_exporter";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getTime } from "commonMethod/common";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";
import { Popup } from "devextreme-react";
import { ToolbarItem } from "devextreme-react/popup";
import { toast, ToastContainer } from "react-toastify";
import { toLower } from "lodash";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const position = { of: "#gridReceivingContainer" };

const renderGridCell = (data) => {
  var color =
    toLower(data.text) == toLower("shipped")
      ? "badge-primary"
      : toLower(data.text) == toLower("delivered")
      ? "badge-success"
      : "badge-secondary";
  return data.text == "" ? "" : <Badge className={color}>{data.text}</Badge>;
};

const exportFormats = ["pdf", "xlsx"];

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class Packings extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.dataSource = createStore({
      key: "GUID",
      loadUrl: `${BaseURLMock}/ShipmentManifests?companyGuid=${localStorage.companyGuid}`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.custodyToken}`,
        };
      },
      onAjaxError: function (error) {
        localStorage.removeItem("@login_details");
        localStorage.removeItem("custodyToken");
        window.location.reload();
      },
    });
    this.state = {
      show: false,
      isMultiSelected: false,
      selectedFilter: "",
      columnModal: false,
      name: "",
      selectedIds: [],
      selectedItem: "",
      categoryStatus: "Active",
      categoryName: "",
      categoryList: [],
      isMatched: false,
      dropdownOpen: false,
      bulkActionOpen: false,
      description: "",
      isDescription: false,
      openDelete: false,
    };
    this.onExporting = this.onExporting.bind(this);
  }
  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
    if (this.props.userData.systemRole == "Worker") {
      alert("Web access is not available for your account");
      window.localStorage.setItem(
        "@login_details",
        '{"userData":{},"msg":"","token":null}'
      );
      // window.location.reload(false);
    }
  }

  sync = async (params = {}) => {
    const { pageSize, pageIndex } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "drug",
      ...params,
    };

    await this.props.getPackingList(obj);
    await this.props.getActiveCategory("drug");
  };
  handleClose = () => {
    this.setState({ openDelete: false });
  };
  onDeletePackingsHandled = () => {
    const { deleteBulkReceivings } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      deleteBulkReceivings(selectedIds)
        .then((res) => {
          toast.success(`Records Deleted`);
          this.sync();
          this.dataGrid.current.instance.refresh();
        })
        .catch(function (error) {
          toast.error(`An error occured while deleting the Records`);
        });
    }
    this.setState({ selectedIds: [], isMultiSelected: false });
    this.setState({ openDelete: false });
  };
  deleteButtonOptions = {
    text: "Ok",
    onClick: this.onDeletePackingsHandled,
  };
  closeButtonOptions = {
    text: "Cancel",
    onClick: this.handleClose,
  };
  handleDelete = () => {
    this.setState({
      openDelete: true,
    });
  };

  handleActiveFilter = async (val) => {
    const { getProductFilter } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      let filterActive = val === "0" ? false : true;
      await getProductFilter(selectedIds, filterActive).then(() => {
        this.sync();
      });
    }
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
      selectedItem: "",
    });
  };

  handleSelectAction = async (value) => {
    value === "Category"
      ? this.setState({ show: !this.state.show })
      : value === "Delete"
      ? this.handleDelete()
      : value === "0"
      ? this.handleActiveFilter(value)
      : value === "1"
      ? this.handleActiveFilter(value)
      : this.sync();
  };

  handleSelect = async (value) => {
    this.setState({
      selectedItem: value,
    });
    await this.props.changePage(0);
    value === "All Packings"
      ? this.sync()
      : value === "0" || value === "1"
      ? this.sync({
          isActive: parseInt(value),
        })
      : this.sync({
          isActive: "1",
          category: value,
        });
  };

  getMultiSelected = (e) => {
    this.setState({ isMultiSelected: e });
  };

  handleColumnSelectorModal = () => {
    this.setState({ columnModal: !this.state.columnModal });
  };

  getColumns = async (columns) => {
    localStorage.setItem("drugsColumns", JSON.stringify(columns));
    this.setState({
      columnModal: false,
    });
  };

  getSelectedIds = (ids) => {
    this.setState({ selectedIds: ids });
  };

  clearFilter = () => {
    this.setState({
      selectedItem: "",
    });
  };

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
    });
  };
  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.setState({ pageIndex }, () => this.sync());
  };
  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = selectedRowKeys;
    let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
    this.setState({ isMultiSelected: !_isMultiSelec, selectedIds: ids });
  };
  onRowClick(e) {
    if (e.rowType != "group") {
      this.props.history.push({
        pathname: `/receiving/${e.key}`,
        state: {},
      });
    }
  }
  onExporting(e) {
    var PromiseArray = [];
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: async ({ gridCell, excelCell }) => {
          if (gridCell.rowType === "data") {
            if (gridCell.column.dataField === "image") {
              excelCell.value = undefined;
              if (gridCell.data.image != null || gridCell.data.image != "") {
                PromiseArray.push(
                  new Promise((resolve, reject) => {
                    this.addImageExcel(
                      gridCell.data.image,
                      workbook,
                      worksheet,
                      excelCell,
                      resolve
                    );
                  })
                );
              }
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "packings.xlsx"
            );
          });
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        customDrawCell: (e) => {
          if (e.gridCell.rowType === "data") {
            if (e.gridCell.column.dataField === "image") {
              PromiseArray.push(
                new Promise((resolve, reject) => {
                  if (
                    e.gridCell.data.image != null ||
                    e.gridCell.data.image != ""
                  ) {
                    this.addImagePdf(
                      e.gridCell.data.image,
                      resolve,
                      function (myBase64) {
                        if (
                          myBase64.includes("image/png") ||
                          myBase64.includes("image/jpg") ||
                          myBase64.includes("image/jpeg")
                        ) {
                          console.log(myBase64, e.gridCell.data.image);
                          doc.addImage(
                            myBase64,
                            "png",
                            e.rect.x,
                            e.rect.y,
                            e.rect.w,
                            e.rect.h
                          );
                          e.cancel = true;
                        }
                      }
                    );
                  }
                })
              );
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          doc.save("packings.pdf");
        });
      });
    }
  }
  DiffCell(cellData) {
    return (
      <div>
        {cellData.data.image ? (
          <img
            src={cellData.data.image}
            id={cellData.data.GUID}
            height="50"
            width="50"
          ></img>
        ) : (
          <img src={Placeholder} height="50" width="50"></img>
        )}
      </div>
    );
  }
  addImageExcel(url, workbook, worksheet, excelCell, resolve) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        const image = workbook.addImage({
          base64: base64data,
          extension: "png",
        });
        worksheet.getRow(excelCell.row).height = 75;
        worksheet.addImage(image, {
          tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
          br: { col: excelCell.col, row: excelCell.row },
        });
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  addImagePdf(url, resolve, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        callback(base64data);
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  render() {
    const {
      categories,
      currentPage,
      pageSize,
      activeCategories,
      pageIndex,
      total,
    } = this.props;
    const {
      show,
      selectedIds,
      categoryList,
      categoryName,
      categoryStatus,
      columnModal,
      isMultiSelected,
      isMatched,
      selectedItem,
      bulkActionOpen,
      dropdownOpen,
      description,
      isDescription,
    } = this.state;

    const options =
      categories.length > 0
        ? categories.map((i) => {
            return { name: i.name, id: i.id };
          })
        : [];
    let drugsColumns = JSON.parse(localStorage.getItem("drugsColumns"));
    return (
      <>
        <ToastContainer autoClose={1000} />
        <Popup
          visible={this.state.openDelete}
          onHiding={this.handleClose}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={false}
          width={400}
          height={140}
        >
          <Position at="center" my="center" of={this.state.positionOf} />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            options={this.deleteButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            options={this.closeButtonOptions}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <ErrorOutlinedIcon fontSize="large" color="error" />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <p>Are you sure you want to delete the selected Shipments?</p>
            </div>
          </div>
        </Popup>
        <div className="drugs-main-table pt-2">
          <Container fluid>
            <Col md="">
              <DataGrid
                id="gridReceivingContainer"
                dataSource={this.dataSource}
                keyExpr="GUID"
                hoverStateEnabled={true}
                showRowLines={false}
                selectedRowKeys={selectedIds}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                defaultSelectedRowKeys={selectedIds}
                onCellPrepared={(e) => {
                  // console.log(e?.data?.isComplete);
                  const isComplete = e?.data?.isComplete;
                  if (isComplete) e.cellElement.classList.add("text-darkgreen");
                }}
                customizeColumns={this.customizeColumns}
                onSelectionChanged={this.onSelectionChanged}
                onExporting={this.onExporting}
                ref={this.dataGrid}
                onRowClick={(e) => {
                  this.onRowClick(e);
                }}
                onRowDblClick={(e) => {
                  this.onRowClick(e);
                }}
                columnAutoWidth={true}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="receiving-main-grid"
                  savingTimeout={100}
                />
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <LoadPanel enabled position={position} />
                <ColumnChooser enabled={true} />
                <SearchPanel enabled={true} visible={true} width={300} />
                <Toolbar>
                  <Item location="before" locateInMenu={"never"}>
                    <div style={{ display: "flex" }}>
                      {selectedIds.length > 0 ? (
                        <BulkDeleteAction
                          bulkActionOpen={this.state.bulkActionOpen}
                          toggleBulk={this.toggleBulk}
                          handleSelectAction={this.handleSelectAction}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Item>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton 
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.dataGrid.current.instance.state(null);
                        this.sync();
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" locateInMenu={"auto"} /> */}
                  <Item name="exportButton" location="after" locateInMenu={"auto"} />
                  <Item location="after" locateInMenu={"auto"}>
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={() => {
                        this.sync();
                        this.dataGrid.current.instance.refresh();
                      }}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" locateInMenu={"auto"} />
                  <Item name="searchPanel" location="before" locateInMenu={"never"} />
                </Toolbar>
                <Column
                  dataField="purchaseOrderNumber"
                  caption="Purchase Order#"
                  allowHiding={false}
                />
                <Column
                  dataField="invoiceNumber"
                  caption="Invoice#"
                  allowHiding={false}
                />
                <Column
                  dataField="advancedShipmentNoticeNumber"
                  caption="ASN#"
                  allowHiding={false}
                />
                <Column
                  dataField="destinationParty.name"
                  caption="Buyer"
                  allowHiding={false}
                />
                <Column
                  dataField="sourceParty.name"
                  caption="Seller"
                  allowHiding={false}
                />
                <Column
                  dataField="shipFromLocation.name"
                  caption="Ship From"
                  allowHiding={false}
                />
                <Column
                  dataField="shipToLocation.name"
                  caption="Ship To"
                  allowHiding={false}
                />
                <Column
                  dataField="shipDate"
                  caption="Ship Date"
                  dataType="date"
                  visible={true}
                  customizeText={(cellInfo) => {
                    return getTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getTime(cellInfo.value);
                  }}
                />
                <Column
                  dataField="qtyShipped"
                  caption="Qty Shipped"
                  dataType="number"
                  alignment="center"
                />
                <Column
                  dataField="qtyReceived"
                  caption="Qty Received"
                  dataType="number"
                  alignment="center"
                />

                <Column
                  dataField="isComplete"
                  caption="Completed (?)"
                  dataType={"boolean"}
                />
                <Column
                  dataField="statusCode"
                  caption="Status"
                  cellRender={renderGridCell}
                />
                <Column
                  dataField="receiveDate"
                  caption="Receive Date"
                  dataType={"date"}
                />
                <Column
                  dataField="shipmentNumber"
                  caption="Shipment ID"
                  allowHiding={false}
                />
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <HeaderFilter visible={true} allowSearch={true} />
                <Selection mode="multiple" />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging
                  defaultPageSize={this.props.pageSize}
                  onPageSizeChange={this.onPageSizeChange}
                />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
              </DataGrid>
            </Col>
          </Container>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    categories,
    drugs,
    activeCategories,
    total,
    currentPage,
    pageSize,
    pageIndex,
    rows,
    columns,
  } = state.packingReducer;
  return {
    categories,
    drugs,
    pageIndex,
    activeCategories,
    pageSize,
    total,
    currentPage,
    rows,
    columns,
    userData: state.loginReducer.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getPackingList: (obj) => dispatch(PackingsService.getPackingList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    deleteBulkReceivings: (id) =>
      dispatch(ReceivingService.deleteBulkReceivings(id)),
    getProductFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductStatus(ids, isActive)),
    getFilteredDrugList: (type) =>
      dispatch(PackingsService.getFilteredDrugList(type)),
    getProductCategory: (obj, productType) =>
      dispatch(ProductServices.addProductCategory(obj, productType)),
    filterProductByCategory: (category, obj) =>
      dispatch(PackingsService.filterProductByCategory(category, obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    changePage: (pageNo) => dispatch(PackingsService.changePage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(PackingsService.changePageSize(pageSize)),
    updateColumns: (columns) =>
      dispatch(PackingsService.updateColumns(columns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Packings);
