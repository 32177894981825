import { EquipmentChecklistsColumns } from "Constant/Column";

/**
 * Initial state for the drugs which is empty.
 */
const initialState = {
  equipments: [],
  total: 0,
  pageSize : 20,
  pageIndex : 0,
  columns : [...EquipmentChecklistsColumns],
  equipmentDetails: {},
  sortInfo: { field: 'name', sortOrder: 'ASC' }
};

export default function equipmentReducer(state = initialState, action) {
    switch(action.type) {
      case 'GET_EQUIPMENT_CHECKLIST_SUCCESS':
        let { data, count, items, page, rows } = action.payload;
        return {...state, equipments: data, total: count, currentPage: page, rows: rows };

      case 'CHANGE_PAGE_SIZE_CHECKLISTS': 
        return {...state, pageSize : action.payload, pageIndex : 0}

      case 'CHANGE_PAGE_CHECKLISTS':
        return {...state, pageIndex : action.payload}  

      case 'EQUIPMENT_CHECKLIST_CHANGE_PAGE':
        return { ...state, pageIndex : action.payload }

      case 'UPDATE_EQUIPMENT_CHECKLIST_COLUMNS' : 
        return {...state, columns : [...action.payload]}

      case 'GET_EQUIPMENT_DETAILS_SUCCESS' : 
       return {...state, equipmentDetails : action.payload}

      case 'UPDATE_EQUIPMENT_CHECKLIST_SORT_INFO' : {
        return { ...state, sortInfo : { ...action.payload }}

       }

      default:
        return state;
    }
  }