import { VehiclesColumns } from "Constant/Column";

/**
 * Initial state for the drugs which is empty.
 */
 const gridInitialState = {
//  rows: [],
  sorting: [],
  grouping: [],
  expandedGroups: [],
  selection: [],
  expandedRowIds: [],
  filters: [],
  currentPage: 0,
  pageSize: 100,
  pageSizes: [5, 10, 15],
  columnOrder: ['title', 'checkListFieldType', 'qtyExpected', 'qtyRecorded', 'comment'],
  columnWidths: [
    
    { columnName: 'title', width: 300 },
    { columnName: 'checkListFieldType', width: 150 },
    { columnName: 'qtyExpected', width: 140 },
    { columnName: 'qtyRecorded', width: 140 },
  // { columnName: 'qtyRFIDTags', width: 100 },
    { columnName: 'comment', width: 950 },
  ],
};
const initialState = {
  checklistHistory: {},
  checklistHistoryDetails :{},
  checklistHistoryGrid : gridInitialState
};

export default function equipmentChecklistHistoryReducer(state = initialState, action) {
    switch(action.type) {
     
      case 'GET_EQUIPMENT_CHECKLIST_HISTORY' : 
      return {...state, checklistHistory : action.payload }

      case 'GET_EQUIPMENT_CHECKLIST_HISTORY_DETAILS' : 
      return {...state, checklistHistoryDetails : action.payload }

      case 'CHECKLIST_HISTORY_GRID_STATE_CHANGE_ACTION' : 
      return {
        ...state,
        checklistHistoryGrid : {
          ...state.checklistHistoryGrid,
          [action.partialStateName]: action.partialStateValue
        },
     
      };

      default:
        return state;
    }
  }

  
  
  const gridReducer = (state = gridInitialState, action) => {
    if (action.type === GRID_STATE_CHANGE_ACTION) {
      return {
        ...state,
        [action.partialStateName]: action.partialStateValue,
      };
    }
    return state;
  };