import axios from 'axios';
import {
    getAllCategoryURL, addCategoryURL, editDrugStatusURL,
    deleteDrugURL, editDrugItemStatusURL, deleteDrugItemURL
} from 'Constant/ApiRoutes';
import { BaseURL } from 'Constant/BaseURL';
import { trackPromise } from 'react-promise-tracker';

/**
 * To perform network request for the drugs
 */
class VehiclesService {

    constructor({ url = BaseURL }) {
        this.url = url;
    }

    getVehicleDetails = (vehicleId) => {
        return (dispatch) => {
            dispatch({ type: 'GET_VEHICLE_DETAILS_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/${vehicleId}`, { method: "GET" }).then((res) => {
                        dispatch({ type: 'GET_VEHICLE_DETAILS_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_VEHICLE_DETAILS_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getProductDetails = (id) => {
        return (dispatch) => {
            dispatch({ type: 'GET_PRODUCT_DETAILS_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/products/${id}`, { method: "GET" }).then((res) => {
                        dispatch({ type: 'GET_PRODUCT_DETAILS_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_PRODUCT_DETAILS_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getVehicleList = ({ page = 1, rows = 20, field = 'GUID', sortOrder = 'ASC' }) => {
        const self = this;
        return (dispatch) => {
            dispatch({ type: 'GET_VEHICLE_LIST_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${this.url}/vehicle`, {
                        method: "GET",
                        params: {
                            rows,
                            page: page + 1,
                            field,
                            sortOrder,

                        },
                    }).then((res) => {
                        dispatch({ type: 'GET_VEHICLE_LIST_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_VEHICLE_LIST_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getVehiclesNameList = ({ page = 1, rows = 20, field = 'GUID', sortOrder = 'ASC' }) => {
        const self = this;
        return (dispatch) => {
            dispatch({ type: 'GET_VEHICLES_NAME_LIST_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${this.url}/vehicle`, {
                        method: "GET",
                        params: {
                            rows,
                            page: page + 1,
                            field,
                            sortOrder,

                        },
                    }).then((res) => {
                        dispatch({ type: 'GET_VEHICLES_NAME_LIST_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_VEHICLES_NAME_LIST_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    addVehicle = (objVehicle) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle`, {
                        method: "POST",
                        data: objVehicle,
                    }).then((res) => {
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }
    updateVehicle = (objVehicle, vehicleId) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/${vehicleId}`, {
                        method: "PUT",
                        data: objVehicle,
                    }).then((res) => {
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }

    // clear item tags associated to vehicle
    clearItemTag = (vehicleId) => { 
        return (dispatch) => {
            return trackPromise(
              new Promise((resolve, reject) => {
                axios(`${BaseURL}/vehicle/clear-item-tags`, {
                  method: "PUT",
                  data: {
                    'GUID': vehicleId,
                  },
                })
                  .then((res) => { 
                    resolve(res.data);
                  })
                  .catch(function (error) { 
                    console.log("clearerror")
                    reject(error);
                  });
              })
            );
        };
    }; 

     updateVehicleStatus = (ids, status) => {
        return function(dispatch) {
            let url = status ? `/vehicle/bulk-mark-active` : `/vehicle/bulk-mark-inActive`;
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}${url}`, {
                        method: "PUT",
                        data: {
                            'GUIDS': ids
                        }
                    }).then((res) => {
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }

    getCurrentInventory = (vehicleId) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/inventory/${vehicleId}`, { method: "GET" })
                        .then((res) => {
                            dispatch({ type: "GET_CURRENT_INVENTORY", payload: res.data.data });
                            resolve(res.data.data);
                        })
                        .catch((error) => {
                            dispatch({ type: "GET_CURRENT_INVENTORY", payload: [] });
                            reject(error);
                        });
                })
            );
        };
    };

    getVehicleMovementHistory = (vehicleId) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/movement-history/${vehicleId}`, { method: "GET" })
                        .then((res) => {
                            dispatch({ type: "GET_MOVEMENT_HISTORY", payload: res.data.data });
                            resolve(res.data.data);
                        })
                        .catch((error) => {
                            dispatch({ type: "GET_MOVEMENT_HISTORY", payload: [] });
                            reject(error);
                        });
                })
            );
        };
    };

    getVehicleChecklistHistory = (vehicleId) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/checklist-result-history/${vehicleId}`, { method: "GET" })
                        .then((res) => {
                            dispatch({ type: "GET_CHECKLIST_HISTORY", payload: res.data.data });
                            resolve(res.data.data);
                        })
                        .catch((error) => {
                            dispatch({ type: "GET_CHECKLIST_HISTORY", payload: [] });
                            reject(error);
                        });
                })
            );
        };
    };

    deleteBulkVehicles = (ids) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios.delete(`${BaseURL}/vehicle/bulk-delete-vehicles`, {
                        data: { GUIDS: ids }
                    }).then((res) => {
                        resolve();
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }

    markStausBulkVehicles = (ids, active = true) => {
        const url = active
          ? `${BaseURL}/vehicle/bulk-mark-active`
          : `${BaseURL}/vehicle/bulk-mark-inactive`;
    
        return (dispatch) => {
          return trackPromise(
            new Promise((resolve, reject) => {
              axios(url, {
                method: "PUT",
                data: {
                  GUIDS: ids,
                },
              })
                .then((res) => {
                  resolve(res.data);
                })
                .catch(function (error) { 
                  reject(error);
                });
            })
          );
        };
    };

    getVehicleFleet = () => {
        return (dispatch) => {
            dispatch({ type: 'GET_VEHICLE_FLEET_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/vehicleFleet`, { method: "GET" }).then((res) => {
                        dispatch({ type: 'GET_VEHICLE_FLEET_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_VEHICLE_FLEET_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getVehicleType = () => {
        return (dispatch) => {
            dispatch({ type: 'GET_VEHICLE_TYPE_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/vehicle/vehicleType`, { method: "GET" }).then((res) => {
                        dispatch({ type: 'GET_VEHICLE_TYPE_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_VEHICLE_TYPE_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }



    updateColumns = ({ columns }) => (dispatch) => {
        dispatch({ type: 'UPDATE_VEHICLE_LIST_COLUMNS', payload: columns })
    }

    updsteSortInfo = ({ field, sortOrder }) => (dispatch) => {
        dispatch({ type: 'UPDATE_VEHICLE_SORT_INFO', payload: { field, sortOrder } })
    }

    changePageSizeVehicles = (pageSize) => {
        return { type: 'CHANGE_PAGE_SIZE_VEHICLES', payload: pageSize }
    }
    changePageVehicles = (pageNumber) => {
        return { type: 'CHANGE_PAGE_VEHICLES', payload: pageNumber }
    }

    changePageSizeVehiclesList = (pageSize) => {
        return { type: 'CHANGE_PAGE_SIZE_VEHICLES_LIST', payload: pageSize }
    }
    changePageVehiclesList = (pageNumber) => {
        return { type: 'CHANGE_PAGE_VEHICLES_LIST', payload: pageNumber }
    }
}

const vehiclesService = new VehiclesService({ url: BaseURL });
export default vehiclesService;