import axios from "axios";
import { BaseURL, BaseURLDev } from "Constant/BaseURL";
import { get } from "lodash";
import { trackPromise } from "react-promise-tracker";

export const loginUser = (userObj) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURLDev}/user/login`, {
          method: "POST",
          data: userObj,
        })
          .then((res) => {
            // debugger;
            const response = {
              userData: res.data.data.userData,
              msg: res.data.msg,
              token: res.data.data.token,
              companyGuid: res.data.data.userData.companyGuid,
            };
            resolve(response);
            dispatch({ type: "@LOGIN_USER", payload: response });
          })
          .catch((error) => {
            const errorMessage = get(
              error,
              "response.data.msg",
              "Login failed"
            );
            dispatch({
              type: "@LOGIN_USER",
              payload: { userData: {}, msg: errorMessage, token: null },
            });
          });
      })
    );
  };
};

export const logoutUser = (userObj) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        resolve({});
        dispatch({ type: "@LOGUT_USER", payload: { success: true } });
        //  axios(`${BaseURL}/user/logout`, {
        //    method: "POST",
        //    data: userObj,
        //  })
        //   .then((res) => {
        //     // const response = { userData: res.data.data.userData, msg: res.data.msg, token: res.data.data.token }
        //     resolve(response)
        //     dispatch({ type: '@LOGUT_USER', payload: { success: true } })
        //    })
        //   .catch((error) => {
        //      reject(error);
        //      dispatch({ type: '@LOGUT_USER', payload: { success: false } })
        //    });
      })
    );
  };
};
