import OverviewProductTable from "commonComponents/OverviewProductTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import UserServices from "service/UserServices";
import ProductServices from "service/CommonServices";
import { Col, Row } from "react-bootstrap";
import OverviewHeader from "commonComponents/Overview/OverviewHeader";
import OverviewProductTabs from "commonComponents/OverviewProductTabs";
import { OverviewMovementHistory } from "commonComponents/OverviewMovementHistory";
import VehicleCurrentInventory from "../../commonComponents/VehicleCurrentInventory";
import EquipmentChecklistHistory from "../../commonComponents/EquipmentChecklistHistory";
import OverViewTabCheck from "commonComponents/overviewChecklist";
import { getDateTime } from "commonMethod/common";
import { get } from "lodash";
import vehiclesService from "../../service/VehiclesServices";
//import VehicleList from "../vehicles/VehicleList";
import EquipmentList from "./EquipmentList";
import equipmentChecklistsService from "service/EquipmentChecklistsServices";


export class ChecklistDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      columnModal: false,
      selectedItem: "",
      selectedUser: "",
      categoryStatus: "",
      key: "overview",
      pageSize: 20,
      pageIndex: 1,
    };
  }

  // componentDidMount() {
  //   this.sync();
  // }

  sync = async (newObj = {}) => {
    const { pageSize, pageIndex, match, equipmentList } = this.props;
    // const { vehicleId } = match.params;
    const { checklistId } = match.params;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "GUID",
      sortOrder: "ASC",
      ...newObj,
    };
    // await this.props.getVehicleList(obj);
    await this.props.getEquipmentCheckList(obj);
    await this.handleGetEquipmentDetails(checklistId);
    await this.handleGetEquipmentChecklistHistory(checklistId);
  };

  onSortedChange = (sorted) => {
    const { pageSize, pageIndex } = this.props;
    const { id, desc } = sorted[0];
    const obj = {
      rows: pageSize,
      page: pageIndex,
      field: id,
      sortOrder: desc ? "DES" : "ASC",
    };
    this.sync(obj);
  };

  // onMovementSortedChange = (sorted) => {
  //   const { usersDetails } = this.props;
  //   const { pageSize, pageIndex } = this.state;
  //   const { id, desc } = sorted[0];
  //   const obj = {
  //     rows: pageSize,
  //     page: pageIndex,
  //     field: id,
  //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
  //     sortOrder: desc ? "DES" : "ASC",
  //   };
  //   this.handleGetMovementHistory(obj);
  // };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.setState({ pageIndex }, () => this.sync());
  };

  handleSelectDropDown = async (val) => {
    // await this.props.changePage(0);
    val === "All Checklists"
      ? this.sync()
      : this.sync({
        isActive: parseInt(val),
      });
  };

  handleSelect = async (val) => {
    // await this.props.changePage(0);
    val === "All Checklists"
      ? this.sync()
      : this.sync({
        isActive: parseInt(val),
      });
  };

  handleGetEquipmentDetails = (id) => {
    const { getEquipmentDetails } = this.props;

    getEquipmentDetails(id);
  };

  handleGetEquipmentChecklistHistory = (id) => {
    const { getEquipmentChecklistHistory } = this.props;

    getEquipmentChecklistHistory(id);
  };

  // handleGetMovementHistory = (newObj) => {
  //   const { getMovementHistory, usersDetails } = this.props;
  //   const { pageSize, pageIndex } = this.state;
  //   let obj = {
  //     rows: pageSize,
  //     page: pageIndex,
  //     field: "firstSeen",
  //     sortOrder: "DES",
  //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
  //     ...newObj,
  //   };
  //   getMovementHistory(obj);
  // };

  handleTabs = (k) => {
    this.setState({ key: k });
  };

  onHandleMoreOperation = async (key) => {
    const { equipmentDetails } = this.props;
    if (key === "markActive") {
      await this.props.updateUserStatus(
        [usersDetails.GUID],
        !usersDetails.isActive
      );
      await this.handleGetEquipmentDetails(equipmentDetails.GUID);
    } else if (key === "delete") {
      await this.props.deleteBulkChecklists([equipmentDetails.GUID]);
      this.props.history.push("/equipmentChecklists");
    }
  };

  handleTdProps = (rowInfo) => {

    this.handleGetEquipmentDetails(rowInfo.original.GUID);
    this.props.history.push({
      pathname: `/equipmentChecklist/${rowInfo.original.GUID}`,
      state: {
        assetName: rowInfo.original.name,
      },
    });
    this.handleGetEquipmentChecklistHistory(rowInfo.original.GUID)
  };


  handleTrProps = (rowInfo) => {
    const { checklistId } = this.props.match.params;


    return {
      style: {
        background: rowInfo.original.GUID === checklistId && "#EBECF0",
      },
      className: "table-row",
    };

  };

  handleTrPropsCheck = (rowInfo) => {
    const { checklistId } = this.props.match.params;
    return {
      style: {
        background: rowInfo.original.GUID === checklistId && "#EBECF0",
      },
      className: "table-row",
    };

  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.key !== this.state.key) {
      const routePath = this.props.location.pathname + '#' + this.state.key;
      this.props.history.push(routePath);
    }
  }

  componentDidMount() {
    if (this.props.location.hash) {
      this.setState({
        key: this.props.location.hash.slice(1)
      })
    }
    this.sync();
    console.log(this.props.location)

  }

  render() {
    const {
      historyList,
      historyCount,
      vehicleList,
      equipmentList,
      usersDetails,
      checklistHistory,
      checklistHistoryDetails,
      vehicleDetails,
      equipmentDetails,
      pageIndex,
      total,
      match,
      pageSize,
      movementList,
      isActive, isActiveFlage,
      loginUser

    } = this.props;
    const list = (<div style={{paddingLeft:"0px"}}>
      {checklistHistory.vehicleList.map(item => {
        return <div style={{paddingBottom:"5px"}} >{item.name}</div>
      })}
    </div>)
    const latestTime = getDateTime(checklistHistory.latest)
    const { key } = this.state;
    const { checklistId } = match.params;
    const OverViewTabsData = [
      {
        type: "image",
        value: "",
      },
      {
        name: "Name",
        value: equipmentDetails.name,
      },
      {
        name: "description",
        value: equipmentDetails.description,
      },
      {
        name:"Number of Vehicles",
        value: checklistHistory.vehicleCount
      },
      {
        name: "Assigned Vehicles",
        value: list,
      },
      {
        name: "History Count",
        value: historyCount,
      },
      {
        name: "latest",
        value: latestTime,
      },
      

    ];
    const TabsDetails = [
      {
        name: "Overview",
        EventKey: "overview",
        Component: <div><OverViewTabCheck OverViewTabsData={OverViewTabsData} />
        <div className="checklistSummary">
           <h6>Summary</h6>
           {/* <div>            <div className="check-div-sum">
              <div className="check-label-summary">Drugs</div> <div style={{paddingTop:"20px"}}>:{''}</div>
              <div className="check-value-sum">5</div>
            </div>
            <div className="check-div-sum">
              <div className="check-label-summary">Assets</div> <div style={{paddingTop:"20px"}}>:{''}</div>
              <div className="check-value-sum">5</div>
            </div>
            <div className="check-div-sum">
              <div className="check-label-summary">Disposables</div><div style={{paddingTop:"20px"}}>:{''}</div>
              <div className="check-value-sum">5</div>
            </div>
            <div className="check-div-sum">
              <div className="check-label-summary">Others</div> <div style={{paddingTop:"20px"}}>:{''}</div>
              <div className="check-value-sum">5</div>
            </div>
            
            <div className="check-div-sum">
              <div className="check-label-summary">Total</div> <div style={{paddingTop:"20px"}}>:{''}</div>
              <div className="check-value-sum">5</div>
            </div>
            </div> */}

           </div>
           </div>,
      },

      {
        name: "Checklist History",
        EventKey: "checklist",
        Component: (
          <EquipmentChecklistHistory
          //   onPageSizeChange={this.onPageSizeChange}
          //   onPageChange={this.onPageChange}
          //   pageSize={pageSize}
          // total={total}
          // pageIndex={pageIndex}
            historyData={historyList}
            handleTrPropsCheck={this.handleTrPropsCheck}
          />
        ),
      },
    ];

    const role = this.props.systemRole;
    console.log(role)
    return (
      <>
        <Row className="drug-detail-table-actions">
          <Col lg="3 quick-navigation">
            <EquipmentList
              tableCol={12}
              handleTrProps={this.handleTrProps}
              getVehicleDetails={this.getVehicleDetails}
              handleTdProps={this.handleTdProps}
              name={"Checklist"}
              history={this.props.history}
              addButtonURl={"/addEquipmentChecklist"}
              tableData={equipmentList}
              pageSize={pageSize}
              total={total}
              pageIndex={pageIndex}
              onPageChange={this.onPageChange}
              onPageSizeChange={this.onPageSizeChange}
              handleSelect={this.handleSelect}
              onSortedChange={this.onSortedChange}
              userId={checklistId}
              onHandleMoreOperation={this.onHandleMoreOperation}
              selectedProduct={usersDetails}
              handleTabs={this.handleTabs}
              tabKey={key}
              pages={total / pageSize}
              module="equipmentChecklist"
            />
          </Col>

          <Col lg="9" className="drug-item-detail-overview">

            <OverviewHeader
              editPath={`/editEquipmentChecklist/${checklistId}`}
              history={this.props.history}
              title={equipmentDetails && equipmentDetails.name}
              isActive={equipmentDetails.isActive}
              onHandleMoreOperation={this.onHandleMoreOperation}
              closeButtonPath={`/${"EquipmentChecklists".toLowerCase()}`}
            />

            <OverviewProductTabs
              TabsDetails={TabsDetails}
              handleTabs={this.handleTabs}
              tabKey={key}
            />

           
            
          </Col>

        </Row>

      </>
    );
  }
}

const makeChecklistDetails = (checklistHistory) => {
  return {
    GUID: null,
    "name": "",
    "description": "",
    "vehicleCount": '',
    "historyCount": "",
    "latest":"",
    "vehicleList": [],
    "historyList": [],
    "typewiseSummary": [],
    ...checklistHistory
  }
}

const mapStateToProps = (state) => {
  console.log(state.equipmentReducer)
  const {
    usersList,
    userColumns,
    pageSize,
    total,
    rows,
    pageIndex,
    currentPage,
    usersMovementHistory,
   
    usersDetails,
    
  } = state.usersReducer;
  const { vehicleDetails, vehicles } = state.vehicleReducer;
  const { equipments, equipmentDetails, columns, sortInfo } = state.equipmentReducer;
  const { checklistHistory, checklistHistoryDetails } = state.equipmentChecklistHistoryReducer;
  console.log(state.equipmentChecklistHistoryReducer);
  return {
    usersList,
    //  vehicleList: vehicles,
    equipmentList: equipments,
    userColumns,
    checklistHistory: makeChecklistDetails(checklistHistory),
    historyList: checklistHistory.historyList,
    historyCount: checklistHistory.historyCount,
    vehicleList: checklistHistory.vehicleList,
    checklistHistoryDetails,
    pageSize,
    pageIndex,
    currentPage,
    usersMovementHistory,
    usersDetails,
    vehicleDetails,
    equipmentDetails: equipmentDetails,
    rows,
    total,
    userData: state.loginReducer.userData,
    systemRole: get(state, 'loginReducer.userData.systemRole')
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsersList: (obj) => dispatch(UserServices.getAllUserList(obj)),
    getVehicleList: (obj) => dispatch(vehiclesService.getVehicleList(obj)),
    getEquipmentCheckList: (obj) => dispatch(equipmentChecklistsService.getEquipmentCheckList(obj)),

    getVehicleDetails: (vehicleId) => dispatch(vehiclesService.getVehicleDetails(vehicleId)),
    getEquipmentDetails: (checklistId) => dispatch(equipmentChecklistsService.getEquipmentDetails(checklistId)),
    getEquipmentChecklistHistory: (checklistId) => dispatch(equipmentChecklistsService.getEquipmentChecklistHistory(checklistId)),
    updateUserStatus: (ids, isActive) =>
      dispatch(UserServices.updateUserStatus(ids, isActive)),
      changePage: (pageNo) => dispatch(UserServices.changeUsersPage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(UserServices.changeUsersPageSize(pageSize)),
      deleteBulkChecklists: (ids) => dispatch(equipmentChecklistsService.deleteBulkChecklists(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChecklistDetails);