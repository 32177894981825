import React from "react";
import { Row, Badge } from "react-bootstrap";
import CategoryChip from "../categoryChips";
import { AiOutlineClockCircle } from 'react-icons/ai';

function ChecklistTitle({title, isActive, complete , duration , isActiveFlage}) {
    return(
        <Row className="drug_title">
            <h3 className="drug_name">{title}</h3>
            {isActiveFlage && <h4 className={`drug_status ${isActive ? "" : 'drug_inactive' }`}>{isActive ? "" : <CategoryChip variant="secondary" name="INACTIVE" />}</h4>}
            <Badge bg="success" variant="success" className="badge-over">{complete}</Badge>
        <Badge bg="success" variant="secondary" className="badge-over"> <AiOutlineClockCircle className="clock"></AiOutlineClockCircle>{duration}</Badge>
        
        </Row>
    )
}


export default ChecklistTitle;