import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import Select from "commonComponents/Select";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../commonComponents/Input";
import Multiselect from "multiselect-react-dropdown";
import DragAndDrop from "../../commonComponents/DragAndDrop";
import drugsService from ".././../service/DrugsService";

import ProductServices from '.././../service/CommonServices';
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Title from "commonComponents/Title";
import vehiclesService from "service/VehiclesServices";

function EditVehicle(props) {
  props && props.match && props.match.params && props.match.params.vehicleId
  ? true
  : false;
const vehicleId =
props && props.match && props.match.params && props.match.params.vehicleId;
  const [image, setImage] = useState(
      props.vehicleDetails.name?props.vehicleDetails.name :""
  );
  const [name, setName] = useState("");
  const [vehicleUnitId, setVehicleUnitId] = useState("");
  const [locationType , setLocationType] = useState("");
  const [radioId, setRadioId] = useState("");
  const [fleet, setFleet] = useState("");
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [vehicleModelYear, setVehicleModelYear] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [serviceDate, setServiceDate] = useState("");
  const [equipmentChecklistGuid, setEquipmentChecklistGuid] = useState([]);
  const [isMatched, setIsMatched] = useState(false);
  const [description, setDescription] = useState("");
  const [isFormSubmitted, setForm] = useState(false);

  /**
   * Return the selected category
   * @param {*} selectedList
   * @param {*} selectedItem
   */
  const onSelect = (selectedList, selectedItem) => {
    setCategories(selectedList);
  };

  useEffect(async () => {
    if (props.match.params.vehicleId) {
      let { vehicleId } = props.match.params;
      props.getVehicleDetails(vehicleId).then((data) => {
        setName(props.vehicleDetails.name);
        setVehicleUnitId(props.vehicleDetails.vehicleUnitId);
        setRadioId(props.vehicleDetails.radioId);
        setFleet(props.vehicleDetails.fleet);
        setLicensePlateNumber(props.vehicleDetails.licensePlateNumber);
        setVehicleStatus(props.vehicleDetails.vehicleStatus);
        setVehicleModelYear(props.vehicleDetails.vehicleModelYear);
        setServiceDate(props.vehicleDetails.serviceDate)
        setVehicleType(props.vehicleDetails.vehicleType);
        setVehicleModel(props.vehicleDetails.vehicleModel);
        setLocationType(props.vehicleDetails.locationType);
      });
    }
  }, [props.match.params.vehicleId]);

  console.log(vehicleId);

  const validateForm = () => {
   
    const flag = name &&
    vehicleUnitId  &&
    radioId  &&
    fleet  &&
    licensePlateNumber  &&
    vehicleType  &&
    vehicleStatus  &&
    vehicleModelYear  &&
    serviceDate &&
    locationType

    return flag;
  };

  const resetForm = () => {
    setName("");
    setVehicleUnitId("");
    setRadioId("");
    setFleet("");
    setLicensePlateNumber("");
    setVehicleStatus("");
    setVehicleModelYear("");
    setServiceDate("")
    setVehicleType("");
    setEquipmentChecklistGuid("");
    setVehicleModel("");
    setLocationType("");
    setForm(false);
  };


  const onSubmit = () => {
    const { editVehicle } = props;
    const isValidForm = validateForm();
    const obj = {
      name : name ,
      vehicleUnitId: vehicleUnitId,
      radioId: radioId,
      fleet : fleet,
      licensePlateNumber: licensePlateNumber,
      vehicleType: vehicleType,
      vehicleStatus: vehicleStatus,
      vehicleModelYear: vehicleModelYear,
      vehicleModel: vehicleModel,
      serviceDate: serviceDate,
      locationType: locationType,
    }
    // vehicle.append("name", name);
    // vehicle.append("vehicleUnitId", vehicleUnitId);
    // vehicle.append("radioId", radioId);
    // vehicle.append("fleet", fleet);
    // vehicle.append("licensePlateNumber", licensePlateNumber);
    // vehicle.append("vehicleType", vehicleType);
    // vehicle.append("vehicleStatus", vehicleStatus);
    // vehicle.append("vehicleModelYear", vehicleModelYear);
    // vehicle.append("serviceDate", serviceDate);
    // vehicle.append("equipmentChecklistGuid", equipmentChecklistGuid);
    // image && vehicle.append("image", image);
    setForm(true);
    if (isValidForm) {
        editVehicle(obj, vehicleId)
        .then((res) => {
          if (res) {
            if(res.isError){
              toast.error(res.msg)
            }
            setTimeout(() => {
              resetForm();
              props.history.push("/vehicles");
            }, 1000);
          }
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.msg);
        });
    
  }

    setForm(true);
  };

  const getImage = (file, imageData) => {
    imageData ? setImage(imageData) : "";
  };


console.log(props.vehicleDetails);

  return (
    <div>
      <ToastContainer autoClose={1000}/>
      <Title isActiveFlage={false} title={"New Vehicle"} />
      <div className="registration_form">
        <div>
          <DragAndDrop imageUpload={getImage}></DragAndDrop>
        </div>
        <Form className="form-group-action">
          <Input
            label="NAME"
            value={name}
            isInvalid={isFormSubmitted && name === ""}
            type="input"
            placeholder="Enter vehicle name"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Location Type"
            value={locationType}
            isInvalid={isFormSubmitted && locationType === ""}
            type="input"
            placeholder="Enter Location Type"
            id="locationType"
            onChange={(e) => setLocationType(e.target.value)}
          />
          <Input
            label="Unit ID"
            value={vehicleUnitId}
            isInvalid={isFormSubmitted && vehicleUnitId === ""}
            type="input"
            placeholder="Enter Unit ID"
            id="vehicleUnitId"
            onChange={(e) => setVehicleUnitId(e.target.value)}
          />
          <Input
            label="Radio ID"
            value={radioId}
            isInvalid={isFormSubmitted && radioId === ""}
            type="input"
            placeholder="Enter Radio ID"
            id="radioId"
            onChange={(e) => setRadioId(e.target.value)}
          />
          <Input
                value={fleet}
                isInvalid={isFormSubmitted && fleet === ""}
                label="Fleet"
                optionValue=""
                placeholder="Select fleet"
                id="fleet"
                onChange={(e) => setFleet(e.target.value)}
              ></Input>
          <Input
            label="Licence Plate Number"
            value={licensePlateNumber}
            isInvalid={isFormSubmitted && licensePlateNumber === ""}
            type="input"
            placeholder="Enter license Plate Number"
            id="licensePlateNumber"
            onChange={(e) => setLicensePlateNumber(e.target.value)}
          />
          <Input
            label="Type"
            value={vehicleType}
            isInvalid={isFormSubmitted && vehicleType === ""}
            type="input"
            placeholder="Enter vehicleType"
            id="vehicleType"
            onChange={(e) => setVehicleType(e.target.value)}
          />
          <Input
            label="Vehicle Status"
            value={vehicleStatus}
            isInvalid={isFormSubmitted && vehicleStatus === ""}
            type="input"
            placeholder="Enter Vehicle Status"
            id="vehicleStatus"
            onChange={(e) => setVehicleStatus(e.target.value)}
          />
          <Input
            label="Vehicle Model"
            value={vehicleModel}
            isInvalid={isFormSubmitted && vehicleModel === ""}
            type="input"
            placeholder="Enter Vehicle Model"
            id="vehicleModel"
            onChange={(e) => setVehicleModel(e.target.value)}
          />
          <Input
            label="Vehicle Model Year"
            value={vehicleModelYear}
            isInvalid={isFormSubmitted && vehicleModelYear === ""}
            type="input"
            placeholder="Enter Vehicle Model Year"
            id="vehicleModelYear"
            onChange={(e) => setVehicleModelYear(e.target.value)}
          />
         
          <Input
            label="Service Date"
            value={serviceDate}
            isInvalid={isFormSubmitted && serviceDate === ""}
            type="input"
            placeholder="Enter Service Date"
            id="serviceDate"
            onChange={(e) => setServiceDate(e.target.value)}
          ></Input>
          {/* <Input
            label="Equipment Checklist Guid"
            value={equipmentChecklistGuid}
            isInvalid={isFormSubmitted && equipmentChecklistGuid === ""}
            type="input"
            placeholder="Enter Equipment Checklist Guid"
            id="equipmentChecklistGuid"
            onChange={(e) => setEquipmentChecklistGuid(e.target.value)}
          ></Input> */}
          <div className="form-action">
            <Button variant="primary" onClick={onSubmit}>
              Save
            </Button>
            <Button variant="secondary" className="closeBtn">
              <Link to="/vehicles">Close</Link>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
    
  const { categories } = state.drugsReducer;
  const { vehicleDetails, vehicles } = state.vehicleReducer;
  return {
    categories,
    vehicleList: vehicles,
    vehicleDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    editVehicle: (obj, vehicleId) =>
    dispatch(vehiclesService.updateVehicle(obj, vehicleId)),
    getVehicleDetails: (vehicleId) => dispatch(vehiclesService.getVehicleDetails(vehicleId)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EditVehicle)
);
