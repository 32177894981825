import axios from 'axios';
import { getAllCategoryURL, addCategoryURL, editDrugStatusURL,
    deleteDrugURL, editDrugItemStatusURL, deleteDrugItemURL } from 'Constant/ApiRoutes';
import { BaseURL } from 'Constant/BaseURL';
import { trackPromise } from 'react-promise-tracker';

/**
 * To perform network request for the drugs
 */
class EquipmentChecklistsService {
    
    constructor({ url = BaseURL }) {
        this.url = url;
    }
    getEquipmentDetails = (checklistId) => {
        return (dispatch) => {
            dispatch({ type: 'GET_EQUIPMENT_DETAILS_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/EquipmentChecklists/web/${checklistId}`, { method: "GET" }).then((res) => {
                        dispatch({ type: 'GET_EQUIPMENT_DETAILS_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_EQUIPMENT_DETAILS_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getEquipmentCheckList = ({ page = 1, rows = 20, field = 'GUID', sortOrder = 'ASC' }) => {
        const self = this;
        let urll = `${BaseURL}/EquipmentChecklists/web/`
        return (dispatch) => {
            // dispatch({ type: 'GET_EQUIPMENT_CHECKLIST_STARTS' })
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(urll, {
                        method: "GET", 
                         params: {
                            rows,
                            page: page+1,
                            field,
                            sortOrder,
                        
                        }
                        }).then((res) => {
                        dispatch({ type: 'GET_EQUIPMENT_CHECKLIST_SUCCESS', payload: res.data.data })
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            dispatch({ type: 'GET_EQUIPMENT_CHECKLIST_ERROR', payload: [] })
                            reject(error);
                        }
                    )
                })
            )
        }
    }

    getEquipmentChecklistHistory = (checklistId) => {
        return (dispatch) => {
          return trackPromise(
            new Promise((resolve, reject) => {
              axios(`${BaseURL}/EquipmentChecklists/summary/${checklistId}`, { method: "GET" })
                .then((res) => {
                  dispatch({ type: "GET_EQUIPMENT_CHECKLIST_HISTORY", payload: res.data.data});
                  resolve(res.data);
                })
                .catch((error) => {
                  dispatch({ type: "GET_EQUIPMENT_CHECKLIST_HISTORY", payload: [] });
                  reject(error);
                });
            })
          );
        };
      };

      getEquipmentChecklistHistoryDetails = (historyId) => {
        return (dispatch) => {
          return trackPromise(
            new Promise((resolve, reject) => {
              axios(`${BaseURL}/EquipmentChecklists/checklist-result-details/${historyId}`, { method: "GET" })
                .then((res) => {
                  dispatch({ type: "GET_EQUIPMENT_CHECKLIST_HISTORY_DETAILS", payload: res.data.data});
                  resolve(res.data);
                })
                .catch((error) => {
                  dispatch({ type: "GET_EQUIPMENT_CHECKLIST_HISTORY_DETAILS", payload: [] });
                  reject(error);
                });
            })
          );
        };
      };  
      addEquipmentChecklist = (obj) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/EquipmentChecklists/`, {
                        method: "POST",
                        data: obj,
                    }).then((res) => {
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }
    updateEquipmentChecklist = (obj, checklistId) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios(`${BaseURL}/EquipmentChecklists/${checklistId}`, {
                        method: "POST",
                        data: obj,
                    }).then((res) => {
                        resolve(res.data);
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }

    deleteBulkChecklists = (ids) => {
        return (dispatch) => {
            return trackPromise(
                new Promise((resolve, reject) => {
                    axios.delete(`${BaseURL}/EquipmentChecklists/`, {
                        data: { GUIDS: ids }
                    }).then((res) => {
                        resolve();
                    }).catch(
                        function (error) {
                            reject(error);
                        }
                    )
                })
            )
        };
    }


    updateColumns = ({ columns }) => (dispatch) => {
        dispatch({ type: 'UPDATE_EQUIPMENT_CHECKLIST_COLUMNS', payload: columns })
    }

    updsteSortInfo = ({ field, sortOrder }) => (dispatch) => {
        dispatch({ type: 'UPDATE_EQUIPMENT_CHECKLIST_SORT_INFO', payload: { field, sortOrder } })
    }

    changePageSizeChecklists = (pageSize) => {
        return { type: 'CHANGE_PAGE_SIZE_CHECKLISTS', payload: pageSize }   
    }

    changePageChecklists = (pageNumber) => {
        return { type: 'CHANGE_PAGE_CHECKLISTS', payload: pageNumber }   
    }
    markStausBulkEquipments = (ids, active = true) => { 
        const url = active
          ? `${BaseURL}/equipmentchecklists/bulk-mark-active`
          : `${BaseURL}/equipmentchecklists/bulk-mark-inactive`;
    
        return (dispatch) => {
          return trackPromise(
            new Promise((resolve, reject) => {
              axios(url, {
                method: "POST",
                data: {
                  GUIDS: ids,
                },
              })
                .then((res) => {
                  resolve(res.data);
                })
                .catch(function (error) { 
                  reject(error);
                });
            })
          );
        };
    };
}

const equipmentChecklistsService = new EquipmentChecklistsService({ url: BaseURL });
export default equipmentChecklistsService;