import React from "react";
import { Row as RowDetail } from "./Row";
import Checklist from '../assets/img/paste-regular.svg'; 

export default function OverViewTabCheck({ OverViewTabsData }) {
  return (
    <>
      {OverViewTabsData.map((data) =>
        data.type === "image" ? (
          <div className="drug-detail-action">
            {data.value ? (
              <img src={data.value}></img>
            ) : (
                <img src={Checklist}></img>
            )}
          </div>
        ) : (
          <RowDetail name={data.name} value={data.value}></RowDetail>
        )
      )}
    </>
  );
}
