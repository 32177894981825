import { VehicleCurrentInventoryColumns } from "columns/VehicleCurrentInventory";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { Col, Row , Button, Badge} from "react-bootstrap";
import ReactTable from "react-table-6";
import Pagination from "commonComponents/Pagination";

export class VehicleCurrentInventory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
    };
  }

  //  getCurrentInventory = (Id) => {
  //   this.props.getCurrentInventory(Id)
  // }

  render() {
    let {
      name,
      inventory,
      clearOnClick,
      onPageSizeChange,
      onPageChange,
      onSortedChange,
      pageIndex,
      pageSize,
      total,
      currentPage
    } = this.props;

    console.log(inventory)

    return (
      <>
      <div className="drug-detail-tag-title">
        
        <div>
        <Button
            className="addItemBtn"
            variant="primary"
            style={{padding:"5px"}}
            onClick={clearOnClick}
          >
               <i className="fas fa-sync" style={{marginRight:"10px"}}></i>
              CLEAR
          </Button>
          <Badge bg="light" text="primary" style={{ color:"blue", fontSize:"20px",marginLeft:"20px"}}>
          {Object.keys(inventory).length}
  </Badge>{' '}
        </div>
        {/* <div className="sync">
              <a href="#">
                <i className="fas fa-cog" style={{marginRight:"20px"}}></i>
              </a>
            </div> */}
  
 
          
        </div>
        <ReactTable
          manual
          data={inventory}
          columns={VehicleCurrentInventoryColumns}
          onSortedChange={onSortedChange}
          // pageSize={pageSize}
          loadingText={`Loading the ${name}`}
          noDataText={`Couldn't find ${name}`}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
        <Pagination total={total} pageSize={pageSize} currentPage={currentPage} />
      </>
    );
  }
}


export default withRouter(VehicleCurrentInventory);
