import { getTime } from "commonMethod/common";

const castToType = ({ type, value }) => {
    switch (type) {
        case 'date': {
            return getTime(value)
        }
        default: {
            return value
        }
    }
}
export default function equipmentChecklistColumnBuilder({
    columns = [],
    data = [],
    isHederCheckboxChecxked,
    handleMultiSelect }) {

    let checkBoxColumn = {
        width: 40,
        accessor: "check",
        Header:
            <input type="checkbox"
                name='multi-select-box'
                disabled={data.length === 0}
                checked={isHederCheckboxChecxked}
                onChange={handleMultiSelect} />,
        sortable: false,
        filterable: false,
    }

    const tableColumns = columns.
        filter(col => col.checked).
        map(item => {
            let checklistColumn = []
            let tableColumn = {}
            if (item.checked) {
                if (item.header === 'Name') {
                    tableColumn = {
                        className: "column_width",
                        Header: item.header,
                        accessor: item.accessor,
                        Cell: ({ row }) => (
                            <div>{row._original.name}</div>
                        )
                    }
                } else if (item.header === "Image") {
                    tableColumn = {
                        width: 70,
                        Header: item.header,
                        accessor: item.accessor,
                        Cell: ({ row }) => (
                            <span className="table-image">
                                {row._original.image ? <img src={row._original.image} /> :
                                    <FontAwesomeIcon icon="fa-regular fa-paste" />
                                }
                            </span>
                        ),
                    };
                }
                else {
                    tableColumn = {
                        className: "column_width",
                        Header: item.header,
                        accessor: item.accessor,
                        Cell: ({ row }) => castToType({ value: row[item.accessor], type: item.type })

                    }
                }
            }

            return tableColumn;
            // if (item.checked) {
            //     switch(item.header) {
            //         case 
            //     }
            //     if (item.header === 'Name') {
            //         tableColumn = {
            //             ...tableColumn,
            //             Cell: ({ row }) => (
            //                 <div>{row.displayAccessor}</div>
            //             )
            //         }
            //     } else if (item.header === "Categories") {
            //         tableColumn = {
            //             className: "column_width",
            //             Header: item.header,
            //             accessor: item.accessor,
            //             Cell: ({ row }) => {
            //                 return row._original.productCategory.map((item) => (
            //                     <CategoryChip name={item.name} />
            //                 ));
            //             },
            //             sortable: false,
            //         };
            //     } else if (item.header === "Image") {
            //         tableColumn = {
            //             width: 120,
            //             Header: item.header,
            //             accessor: item.accessor,
            //             Cell: ({ row }) => (
            //                 <span className="table-image">
            //                     {row._original.image ? <img src={row._original.image} /> :
            //                         <img src='https://fakeimg.pl/640x360'></img>
            //                     }
            //                 </span>
            //             ),
            //         };
            //     } else if (item.header === 'Next Expiry') {
            //         tableColumn = {
            //             className: "column_width",
            //             Header: item.header,
            //             // sortable: false,
            //             accessor: item.accessor,
            //             Cell: ({ row }) =>
            //             (<span
            //                 className={
            //                     row._original.expiryDate &&
            //                     (getDateColor(row._original.expiryDate, 2)
            //                         ? "expiryRed"
            //                         : getDateColor(row._original.expiryDate, 5)
            //                             ? "expiryYellow"
            //                             : "expiryBlack")
            //                 }
            //             >
            //                 {row._original.expiryDate &&
            //                     getTime(row._original.expiryDate)}
            //             </span>)
            //         }
            //     }
            //     else {
            //         tableColumn = {
            //             className: "column_width",
            //             Header: item.header,
            //             accessor: item.accessor,
            //         }
            //     }
            // }
            // disposableColumns.push(tableColumn);
        });
    return [checkBoxColumn, ...tableColumns]
}
