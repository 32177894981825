import {getDateTime} from "commonMethod/common";

export const UserMovementHistoryColumns = [
  {
    width: 200,
    Header: "TYPE",
    accessor: "Type",
    // Cell: ({ row }) => <span>{row._original.bizLocationGuid}</span>,
  },
  
  {
    width: 200,
    Header: "NAME",
    accessor: "Name",
    // Cell: ({ row }) => <span>{row._original.bizLocationGuid}</span>,
  }, 
  
  // {
  //   width: 200,
  //   Header: "Event Time ",
  //   accessor: "Event Time",
  //   // Cell: ({ row }) => (
  //   //   <span>{getDateTime(row._original.Event Time)}</span>
  //   // ),
  // },
  {
    width: 200,
    Header: "First Seen ",
    accessor: "First Seen",
    // Cell: ({ row }) => (
    //   <span>{getDateTime(row._original.firstSeen)}</span>
    // ),
  },
  {
    width: 200,
    Header: "Last seen ",
    accessor: "Last Seen",
    // Cell: ({ row }) => (
    //   <span>{getDateTime(row._original.lastSeen)}</span>
    // ),
  },
 
  {
    width: 200,
    Header: "TAG ID ",
    accessor: "Item EPC",
    // Cell: ({ row }) => <span>{row._original.bizLocationGuid}</span>,
  },
];