import React, { Component, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import Select from "commonComponents/Select";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../commonComponents/Input";
import productsService from "service/ProductsService";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Title from "commonComponents/Title";
import vehiclesService from "service/VehiclesServices";
import equipmentChecklistsService from "service/EquipmentChecklistsServices";
import Section from "./sectionTable";
import { getDetailComuns } from "../../columns/detailColumn";
import { getSectionColumns } from "../../columns/detailColumn";
import FreeSoloCreateOption from "../Users/ProviderInput";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import ProductList from "./ProductList";
import { ProductColumns } from "../../Constant/Column";
import "../../assets/css/addChecklist.css";
import { Popup, Position, ToolbarItem } from "devextreme-react/popup";
import ErrorOutlinedIcon from "@mui/icons-material/ErrorOutlined";

const ADD = "ADD";
const EDIT = "EDIT";
const filter = createFilterOptions();

export class AddEquipmentChecklist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHide: false,
      showHideItem: false,
      sections: [],
      line: [],
      productName: null,
      manufacturer: "",
      title: "",
      otherName: "",
      unitOfMeasure: "",
      description: "",
      name: "",
      qtyExpected: "",
      sectionName: "",
      lineItemMode: ADD,
      sectionMode: ADD,
      title: "",
      brand: "",
      UNSPSCCode: "",
      ndc: "",
      productType: "",
      productGuid: "",
      image: "",
      strength: "",
      dossage: "",
      netContent: "",
      modelNumber: "",
      productCode: "",
      lineItemType: "product",
      fieldType: "",
      openDelete: false,
      isDelete: false,
      section: [],
      lineItem: [],
      deleteSection: false,
      positionOf: ".MuiPaper-root",
    };
    this.setValues = this.setValues.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onChangeValue = this.onChangeValue.bind(this);
    this.onProductChange = this.onProductChange.bind(this);
  }

  setValues(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onValueChange(event) {
    this.setState({
      lineItemType: event.target.value,
    });
  }
  onChangeValue(event) {
    this.setState({
      fieldType: event.target.value,
    });
  }
  onProductChange(event) {
    this.setState({
      productName: event.target.value,
    });
  }
  onGetInput(event, val) {
    console.log(val.manufacturer);
  }
  componentDidMount() {
    this.sync();
    if (this.props?.match?.params?.checklistId) {
      let { checklistId } = this.props.match.params;
      this.props.getEquipmentDetails(checklistId).then((response) => {
        this.setState({
          ...response.data,
        });
      });
      console.log("edit equipment");
    }
  }

  sync = async (params = {}) => {
    const { pageSize, pageIndex, sortInfo } = this.props;
    await this.props.getProductList({ ...sortInfo, ...params });
  };

  handleModalShowHide = (mode) => {
    this.setState({ showHide: !this.state.showHide, sectionMode: mode });
  };

  handleModalShowHideItem = (mode) => {
    this.setState({
      showHideItem: !this.state.showHideItem,
      lineItemMode: mode,
    });
  };

  closeModal = () => this.setState({ showHideItem: false });
  closeModalSec = () => this.setState({ showHide: false });

  saveHandler = () => {
    const state = this.state;
    const lineItems = {
      productName: state.productName,
      otherName: state.otherName,
    };
    this.state.line.push(lineItems);
    console.log(this.state.line);
  };

  addSections = ({ name }) => {
    const section = {
      name,
      lineItems: [],
    };
    this.setState({
      sections: [...this.state.sections, section],
    });
  };

  onAddSectionHandler = ({ name }) => {
    name && this.addSections({ name });
    this.handleModalShowHide(ADD);
  };

  onAddLineItemSaveHandler = ({ lineItem }) => {
    const sectionToBeAddedIn = this.state.sections.find(
      (section) => section === this.lineItemToBeAddedTo
    );
    if (Array.isArray(sectionToBeAddedIn?.lineItems)) {
      sectionToBeAddedIn.lineItems = [
        ...sectionToBeAddedIn.lineItems,
        lineItem,
      ];
      this.setState({
        sections: [...this.state.sections],
      });
    }

    this.handleModalShowHideItem(ADD);
  };

  onEditLineItemHandler = (section, lineItem) => {
    const fSec = this.state.sections.find((s) => s === section);
    if (Array.isArray(fSec?.lineItems)) {
      this.lineItemToBeAddedTo = fSec;
      lineItem = fSec.lineItems.find((fl) => fl === lineItem);
      this.lineItemToBeEdited = lineItem;
      this.setState(
        {
          ...lineItem,
        },
        () => {
          this.handleModalShowHideItem(EDIT);
        }
      );
    }
  };

  onEditLineItemSaveHandler = ({ lineItem }) => {
    const sectionToBeAddedIn = this.state.sections.find(
      (section) => section === this.lineItemToBeAddedTo
    );
    if (Array.isArray(sectionToBeAddedIn?.lineItems)) {
      const lineItemEditedIndex = sectionToBeAddedIn.lineItems.findIndex(
        (fl) => fl === this.lineItemToBeEdited
      );
      sectionToBeAddedIn.lineItems[lineItemEditedIndex] = { ...lineItem };
      this.setState(
        {
          sections: [...this.state.sections],
        },
        () => this.handleModalShowHideItem(EDIT)
      );
    }
  };

  onDeleteLineItemHandler = (section, lineItem) => {
    this.setState({
      openDelete: true,
      section: section,
      lineItem: lineItem,
    });
  };
  onDeleteLineItemHandled = () => {
    if (this.state.deleteSection) {
      this.setState({
        sections: this.state.sections.filter((s) => s !== this.state.section),
      });
      this.setState({ deleteSection: false });
    } else {
      const fSec = this.state.sections.find((s) => s === this.state.section);
      if (Array.isArray(fSec?.lineItems)) {
        fSec.lineItems = fSec.lineItems.filter(
          (fl) => fl !== this.state.lineItem
        );
        this.setState({
          sections: [...this.state.sections],
        });
      }
      this.setState({ section: [], lineItem: [] });
    }

    this.handleClose();
  };

  swapItems(lineItems, pos1, pos2) {
    const temp = lineItems[pos1];
    lineItems[pos1] = lineItems[pos2];
    lineItems[pos2] = temp;
    return lineItems;
  }

  onMoveLineItemHandler = (section, lineItem, moveDirtection) => {
    const fSec = this.state.sections.find((s) => s === section);
    if (Array.isArray(fSec?.lineItems)) {
      let lineItemToMoveIndex = fSec.lineItems.findIndex(
        (fl) => fl === lineItem
      );
      if (moveDirtection === "UP") {
        lineItemToMoveIndex > 0 &&
          this.swapItems(
            fSec.lineItems,
            lineItemToMoveIndex,
            --lineItemToMoveIndex
          );
      } else {
        lineItemToMoveIndex < fSec.lineItems.length - 1 &&
          this.swapItems(
            fSec.lineItems,
            lineItemToMoveIndex,
            ++lineItemToMoveIndex
          );
      }
      this.setState({
        sections: [...this.state.sections],
      });
    }
  };

  onDeleteSectionHandler = (section) => {
    this.setState({ deleteSection: true, openDelete: true, section: section });
  };

  onEditSectionHandler = (section) => {
    const { name } = section;
    this.lineItemToBeAddedTo = section;
    this.setState({
      sectionName: name,
    });
    this.handleModalShowHide(EDIT);
  };

  onEditSectionSaveHandler = ({ name }) => {
    const sectionToBeAddedInIndex = this.state.sections.findIndex(
      (section) => section === this.lineItemToBeAddedTo
    );

    if (sectionToBeAddedInIndex > -1) {
      this.state.sections[sectionToBeAddedInIndex] = {
        ...this.state.sections[sectionToBeAddedInIndex],
        name,
      };
      this.setState(
        {
          sections: [...this.state.sections],
        },
        () => this.handleModalShowHide(EDIT)
      );
    }
  };

  onMoveSectionHandler = (section, _, moveDirtection) => {
    const { sections } = this.state;
    let sectionToMoveIndex = sections.findIndex((fl) => fl === section);
    if (moveDirtection === "UP") {
      sectionToMoveIndex > 0 &&
        this.swapItems(sections, sectionToMoveIndex, --sectionToMoveIndex);
    } else {
      sectionToMoveIndex < sections.length - 1 &&
        this.swapItems(sections, sectionToMoveIndex, ++sectionToMoveIndex);
    }
    this.setState({
      sections: [...this.state.sections],
    });
  };

  sectionItems = () => {
    return (
      <Section
        data={this.state.sections}
        sectionColumns={getSectionColumns({
          onDelete: this.onDeleteSectionHandler,
          onEdit: this.onEditSectionHandler,
          onMove: this.onMoveSectionHandler,
        })}
        lineColumns={getDetailComuns}
        onAddLineItemView={({ row }) => {
          this.handleModalShowHideItem(ADD);
          this.lineItemToBeAddedTo = row;
        }}
        onDeleteLineItem={this.onDeleteLineItemHandler}
        onEditLineItem={this.onEditLineItemHandler}
        onMoveLineItem={this.onMoveLineItemHandler}
      />
    );
  };

  onSaveHandler = () => {
    const state = this.state;
    const sections = state.sections.map((section, index) => {
      if (Array.isArray(section.lineItems)) {
        section.lineItems = section.lineItems.map((lineItem, index) => {
          lineItem.position = index;
          if (lineItem.ndc === null) {
            lineItem.ndc = "";
          }
          if (lineItem.image === null) {
            lineItem.ndc = "";
          }
          if (lineItem.UNSPSCCode === null) {
            lineItem.UNSPSCCode = "";
          }
          if (lineItem.brand === null) {
            lineItem.brand = "";
          }
          if (lineItem.strength === null) {
            lineItem.strength = "";
          }
          if (lineItem.dossage === null) {
            lineItem.dossage = "";
          }
          if (lineItem.netContent === null) {
            lineItem.netContent = "";
          }
          if (lineItem.modelNumber === null) {
            lineItem.modelNumber = "";
          }
          if (lineItem.productCode === null) {
            lineItem.productCode = "";
          }
          if (lineItem.manufacturer === null) {
            lineItem.manufacturer = "";
          }
          return lineItem;
        });
      }
      section.position = index;
      return section;
    });
    const obj = {
      name: state.name,
      description: state.description,
      sections,
    };

    if (this.props?.match?.params?.checklistId) {
      let { checklistId } = this.props.match.params;
      this.props
        .updateEquipmentChecklist(obj, checklistId)
        .then((res) => {
          if (res) {
            if (res.isError) {
              toast.error(res.msg);
            } else {
              // toast.success(res.msg);
            }
            setTimeout(() => {
              this.props.history.push("/equipmentChecklists");
            }, 1000);
          }
        })
        .catch(function (error) {
          toast.error(error.response.data?.msg);
        });
    } else {
      console.log(sections);
      this.props
        .addEquipmentChecklist(obj)
        .then((res) => {
          if (res) {
            if (res.isError) {
              toast.error(res.msg);
            }
            setTimeout(() => {
              this.props.history.push("/equipmentChecklists");
            }, 1000);
          }
        })
        .catch(function (error) {
          toast.error(error?.response?.data?.msg);
        });
    }
  };
  handleClose = () => {
    this.setState({ openDelete: false });
  };
  deleteButtonOptions = {
    text: "Ok",
    onClick: this.onDeleteLineItemHandled,
  };
  closeButtonOptions = {
    text: "Cancel",
    onClick: this.handleClose,
  };
  render() {
    console.log();
    const {
      products,
      currentPage,
      pageSize,
      ProductColumns,
      pageIndex,
      total,
    } = this.props;
    const isEditPage =
      this.props &&
      this.props.match &&
      this.props.match.params &&
      this.props.match.params.checklistId
        ? true
        : false;
    const title = isEditPage
      ? "Edit Equipment Checklist"
      : "New Equipment Checklist";
    const filter = createFilterOptions();
    const sortedProducts = products.sort((a, b) => {
      let fa = a.name.toLowerCase(),
        fb = b.name.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });
    return (
      <div style={{ marginLeft: "20px" }}>
        <Popup
          visible={this.state.openDelete}
          onHiding={this.handleClose}
          dragEnabled={false}
          hideOnOutsideClick={false}
          showCloseButton={false}
          showTitle={false}
          width={400}
          height={140}
        >
          <Position at="top" my="center" of={this.state.positionOf} />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            options={this.deleteButtonOptions}
          />
          <ToolbarItem
            widget="dxButton"
            toolbar="bottom"
            options={this.closeButtonOptions}
          />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div>
              <ErrorOutlinedIcon fontSize="large" color="error" />
            </div>
            <div style={{ marginLeft: "15px" }}>
              <p>
                Once you delete, you won't be able to restore the deleted item.
                Are you sure you want to delete?
              </p>
            </div>
          </div>
        </Popup>

        <Modal
          show={this.state.showHide}
          onHide={this.closeModalSec}
          //    aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header
            closeButton
            //onClick={this.handleModalShowHide}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.sectionMode === ADD ? "Add" : "Edit"} Section
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ marginTop: "0px" }}>
            <Form className="form-group-action col-md-10">
              <Input
                // className="col-md-8"
                label="Title"
                value={this.state.sectionName}
                name="sectionName"
                className="checklistNameSection"
                //   isInvalid={isFormSubmitted && name === ""}
                type="input"
                placeholder="required"
                isInvalid={this.state.sectionName === ""}
                onChange={this.setValues}
                id="name"
                //   onChange={(e) => setName(e.target.value)}
              />
            </Form>
          </Modal.Body>
          <Modal.Footer className="modalFooter">
            <Button
              variant="primary"
              style={{ marginRight: "30px" }}
              onClick={() => {
                this.state.sectionMode === ADD
                  ? this.onAddSectionHandler({ name: this.state.sectionName })
                  : this.state.sectionName !== ""
                  ? this.onEditSectionSaveHandler({
                      name: this.state.sectionName,
                    })
                  : null;
              }}
            >
              Save
            </Button>
            <Button
              variant="secondary"
              onClick={() => this.handleModalShowHide(ADD)}
            >
              Cancel
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal 
          show={this.state.showHideItem}
          onHide={this.closeModal}
          aria-labelledby="contained-modal-title-vcenter"
          centered  
          dialogClassName="modal-50w"
        >
          <Modal.Header
            closeButton
            // onClick={() => this.handleModalShowHideItem()}
          >
            <Modal.Title id="contained-modal-title-vcenter">
              {this.state.lineItemMode === ADD ? "Add" : "Edit"} Line Item
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="lineItemModal">
            <Form className="form-group-action section-line">
              <div style={{ display: "flex" }}>
                {this.state.lineItemType === "other" ? (
                  <Form.Check
                    inline
                    value="product"
                    className="radioProduct"
                    name="product"
                    type="radio"
                    checked={this.state.lineItemType === "product"}
                    onChange={this.onValueChange}
                  />
                ) : (
                  <Form.Check
                    inline
                    value="product"
                    className="radioProduct"
                    name="product"
                    type="radio"
                    checked={true}
                    onChange={this.onValueChange}
                  />
                )}
                <div style={{ display: "flex" }} className="checklist-1">
                  {" "}
                  <label htmlFor="Product" className="product-label">
                    Product
                  </label>
                  {this.state.lineItemType === "other" ? (
                    <Autocomplete
                      disabled
                      id="productName"
                      name="productName"
                      value={this.state.productName}
                      options={sortedProducts}
                      getOptionLabel={(option) => option.name}
                      style={{
                        marginBottom: "20px",
                        marginLeft: "10px",
                        width: "435px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="product"
                          variant="outlined"
                        />
                      )}
                      onChange={(event, newValue) => {
                        console.log(newValue);

                        this.setState({
                          productName: newValue.name,
                          manufacturer: newValue.manufacturer,
                          image: newValue.image,
                          ndc: newValue.ndc,
                          productType: newValue.productType,
                          productGuid: newValue.GUID,
                          UNSPSCCode: newValue.UNSPSCCode,
                          productCode: newValue.productCode,
                          strength: newValue.strength,
                          brand: newValue.brand,
                          dossage: newValue.dossage,
                          netContent: newValue.netContent,
                          modelNumber: newValue.modelNumber,
                        });
                      }}
                    />
                  ) : (
                    <Autocomplete
                      value={this.state.productName}
                      onChange={(event, newValue) => {
                        if (!this.props.freeSolo && !newValue) {
                          this.setState({
                            productName: "",
                            manufacturer: "",
                            image: "",
                            ndc: "",
                            productType: "",
                            productGuid: "",
                            UNSPSCCode: "",
                            productCode: "",
                            strength: "",
                            brand: "",
                            dossage: "",
                            netContent: "",
                            modelNumber: "",
                          });
                        } else if (newValue) {
                          this.setState({
                            productName: newValue.name,
                            manufacturer: newValue.manufacturer,
                            image: newValue.image,
                            ndc: newValue.ndc,
                            productType: newValue.productType,
                            productGuid: newValue.GUID,
                            UNSPSCCode: newValue.UNSPSCCode,
                            productCode: newValue.productCode,
                            strength: newValue.strength,
                            brand: newValue.brand,
                            dossage: newValue.dossage,
                            netContent: newValue.netContent,
                            modelNumber: newValue.modelNumber,
                          });
                        }
                      }}
                      // selectOnFocus = {true}
                      clearOnBlur
                      handleHomeEndKeys
                      className="checklist-1"
                      autoHighlight={false}
                      id="free-solo-with-text-demo"
                      options={sortedProducts}
                      style={{
                        marginBottom: "20px",
                        marginLeft: "10px",
                        width: "435px",
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="product required"
                          variant="outlined"
                        />
                      )}
                      getOptionLabel={(option) => {
                        // Value selected with enter, right from the input
                        if (typeof option === "string") {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.name;
                      }}
                      renderOption={(props, option) =>
                        option.name === this.state.productName ? (
                          <li
                            {...props}
                            style={{
                              background: "#f8f8f8",
                            }}
                          >
                            {option.name}
                          </li>
                        ) : (
                          <li {...props}>{option.name}</li>
                        )
                      }
                    />
                  )}
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <Form.Check
                  inline
                  value="other"
                  name="other"
                  className="radioProduct1"
                  type="radio"
                  checked={this.state.lineItemType === "other"}
                  onChange={this.onValueChange}
                />

                {this.state.lineItemType === "product" ||
                this.state.lineItemType === "" ? (
                  <div style={{ display: "flex" }} className="checklist-1">
                    {" "}
                    <label htmlFor="Product" className="product-label">
                      Other
                    </label>
                    <input
                      label="Other"
                      className="checklistNameOther"
                      type="input"
                      placeholder=""
                      id="otherLine"
                      name="title"
                      disabled
                      style={{ width: "445px" }}
                      onChange={this.setValues}
                      value={this.state.title}
                    />
                  </div>
                ) : (
                  <div style={{ display: "flex" }} className="checklist-1">
                    {" "}
                    <label htmlFor="Product" className="product-label">
                      Other
                    </label>
                    <input
                      label="Other"
                      className="checklistNameOther"
                      type="input"
                      placeholder="Title or description text required"
                      id="otherLine"
                      name="title"
                      style={{ width: "445px" }}
                      onChange={this.setValues}
                      value={this.state.title}
                    />
                  </div>
                )}
              </div>

              <h6 style={{ paddingBottom: "10px", marginTop: "20px" }}>
                Specify field type :
              </h6>

              {this.state.lineItemType === "product" ? (
                <>
                  <div style={{ display: "flex" }} className="">
                    {this.state.lineItemType === "product" ? (
                      <Form.Check
                        inline
                        type="radio"
                        aria-label="radio 1"
                        name="quantity"
                        value="quantity"
                        className="radio-check-quantity"
                        checked={true}
                        onChange={this.onChangeValue}
                      />
                    ) : (
                      <Form.Check
                        inline
                        type="radio"
                        aria-label="radio 1"
                        name="quantity"
                        value="quantity"
                        className="radio-check-quantity"
                        disabled
                        checked={false}
                        onChange={this.onChangeValue}
                      />
                    )}
                    <div style={{ display: "flex" }} className="checklist-1">
                      {" "}
                      <label htmlFor="Product" className="product-label2">
                        Quantity
                      </label>
                      <input
                        label="Quantity"
                        className="checklistNameOther1"
                        type="input"
                        placeholder="Quantity required"
                        id="name"
                        name="qtyExpected"
                        //    style={{  }}
                        onChange={this.setValues}
                        value={this.state.qtyExpected}
                      />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      <div style={{ display: "flex" }} className="checklist-1">
                        {" "}
                        <label htmlFor="Product" className="product-label3">
                          Unit of Measure
                        </label>
                        <input
                          label="Unit of Measure"
                          className="checklistNameOther1"
                          type="input"
                          placeholder="optional"
                          disabled
                          id="name"
                          name="unitOfMeasure"
                          onChange={this.setValues}
                          value={this.state.unitOfMeasure}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    disabled
                    name="YesNo"
                    value="YesNo"
                    label="Simple Yes/No"
                    className="radio-check"
                    checked={this.state.fieldType === "YesNo"}
                    onChange={this.onChangeValue}
                  />
                </>
              ) : (
                <>
                  <div style={{ display: "flex" }} className="">
                    <Form.Check
                      inline
                      type="radio"
                      aria-label="radio 1"
                      name="quantity"
                      value="quantity"
                      disabled
                      className="radio-check-quantity"
                      onChange={this.onChangeValue}
                    />
                    <div style={{ display: "flex" }} className="checklist-1">
                      {" "}
                      <label htmlFor="Product" className="product-label2">
                        Quantity
                      </label>
                      <input
                        label="Quantity"
                        className="checklistNameOther1"
                        type="input"
                        placeholder="required"
                        id="name"
                        name="qtyExpected"
                        disabled
                        //    style={{ marginTop: "20px" }}
                        onChange={this.setValues}
                      />
                    </div>
                    <div style={{ marginLeft: "5px" }}>
                      <div style={{ display: "flex" }} className="checklist-1">
                        {" "}
                        <label htmlFor="Product" className="product-label3">
                          Unit of Measure
                        </label>
                        <input
                          label="Unit of Measure"
                          className="checklistNameOther1"
                          type="input"
                          placeholder="optional"
                          id="name"
                          name="unitOfMeasure"
                          disabled
                          onChange={this.setValues}
                        ></input>
                      </div>
                    </div>
                  </div>
                  <Form.Check
                    type="radio"
                    aria-label="radio 1"
                    label="Simple Yes/No"
                    className="radio-check"
                    name="YesNo"
                    value={this.state.fieldType}
                    checked={true}
                    onChange={this.onChangeValue}
                  />
                </>
              )}
              <Form.Check
                type="radio"
                aria-label="radio 1"
                disabled
                label="Text input"
              />
              <Form.Check
                type="radio"
                aria-label="radio 1"
                label="Date"
                disabled
              />
            </Form>
          </Modal.Body>
          {(this.state.lineItemType === "product" &&
            (this.state.productName === "" ||
              this.state.qtyExpected === "" ||
              this.state.qtyExpected === 0)) ||
          (this.state.lineItemType === "other" && this.state.title === "") ? (
            <Modal.Footer className="modalFooter">
              {this.state.lineItemType === "product" ? (
                <Button
                  disabled
                  variant="secondary"
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    const lineItem = {
                      productName: this.state.productName,
                      ndc: this.state.ndc,
                      UNSPSCCode: this.state.UNSPSCCode,
                      productType: this.state.productType,
                      manufacturer: this.state.manufacturer,
                      image: this.state.image,
                      brand: this.state.brand,
                      productCode: this.state.productCode,
                      strength: this.state.strength,
                      dossage: this.state.dossage,
                      netContent: this.state.netContent,
                      modelNumber: this.state.modelNumber,
                      lineItemType: this.state.lineItemType,
                      qtyExpected: this.state.qtyExpected,
                      productGuid: this.state.productGuid,
                    };
                    this.state.lineItemMode === ADD
                      ? this.onAddLineItemSaveHandler({ lineItem })
                      : this.onEditLineItemSaveHandler({ lineItem });
                  }}
                >
                  Save
                </Button>
              ) : this.state.lineItemType === "other" ? (
                <Button
                  disabled
                  variant="primary" 
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    const lineItem = {
                      title: this.state.title,
                      fieldType: "YesNo",
                      lineItemType: this.state.lineItemType,
                    };
                    this.state.lineItemMode === ADD
                      ? this.onAddLineItemSaveHandler({ lineItem })
                      : this.onEditLineItemSaveHandler({ lineItem });
                  }}
                >
                  Save
                </Button>
              ) : null}

              <Button
                variant="secondary"
                onClick={() => this.handleModalShowHideItem()}
                style={{display:"flex",alignItems:"center"}}
              >
                Cancel
              </Button>
            </Modal.Footer>
          ) : (
            <Modal.Footer className="modalFooter">
              {this.state.lineItemType === "product" ? (
                <Button
                  variant="primary"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    const lineItem = {
                      productName: this.state.productName,
                      ndc: this.state.ndc,
                      UNSPSCCode: this.state.UNSPSCCode,
                      productType: this.state.productType,
                      manufacturer: this.state.manufacturer,
                      image: this.state.image,
                      brand: this.state.brand,
                      productCode: this.state.productCode,
                      strength: this.state.strength,
                      dossage: this.state.dossage,
                      netContent: this.state.netContent,
                      modelNumber: this.state.modelNumber,
                      lineItemType: this.state.lineItemType,
                      qtyExpected: this.state.qtyExpected,
                      productGuid: this.state.productGuid,
                    };
                    this.state.lineItemMode === ADD
                      ? this.onAddLineItemSaveHandler({ lineItem })
                      : this.onEditLineItemSaveHandler({ lineItem });
                  }}
                >
                  Save
                </Button>
              ) : this.state.lineItemType === "other" ? (
                <Button
                  variant="primary"
                  style={{ marginRight: "30px" }}
                  onClick={() => {
                    const lineItem = {
                      title: this.state.title,
                      fieldType: "YesNo",
                      lineItemType: this.state.lineItemType,
                    };
                    this.state.lineItemMode === ADD
                      ? this.onAddLineItemSaveHandler({ lineItem })
                      : this.onEditLineItemSaveHandler({ lineItem });
                  }}
                >
                  Save 
                </Button>
              ) : null}

              <Button
                variant="secondary"
                onClick={() => this.handleModalShowHideItem()}
                style={{display:"flex",alignItems:"center"}}
              >
                Cancel
              </Button>
            </Modal.Footer>
          )}
        </Modal>

        <ToastContainer autoClose={1000} />
        <Title isActiveFlage={false} title={title} />
        <div className="registration_form-checklist ">
          <div className="col-md-7">
            <Form
              className="form-group-action-equip col-md-10"
              onSubmit={this.onSaveHandler}
            >
              <Input
                label="CHECKLIST NAME"
                className="checklistNameSection"
                type="input"
                placeholder="Required"
                id="name"
                name="name"
                isInvalid={this.state.name === ""}
                onChange={this.setValues}
                value={this.state.name}
              />
              <Input
                label="DESCRIPTION"
                isInvalid={this.state.description === ""}
                className="checklistNameSection"
                type="input"
                placeholder="description"
                id="description"
                name="description"
                onChange={this.setValues}
                value={this.state.description}
              />
            </Form>
          </div>

          <Form.Group
            className="form-checklist ml-5 mt-3"
            controlId="formBasicCheckbox"
          >
            {/* <Form.Check
              type="checkbox"
              label="Advanced Life Support (ALS) Checklist"
              disabled
            /> */}
          </Form.Group>
        </div>
        <hr />
        <div>
          <div>
            {this.sectionItems()}
            <br />
          </div>
        </div>
        <hr />
        <div>
          {/* this is section modal */}
          <Button
            variant="primary"
            onClick={() => {
              this.handleModalShowHide(ADD);
            }}
          >
            Add Section
          </Button>
        </div>
        <hr />

        <div className="form-action">
          <Button variant="primary" onClick={this.onSaveHandler}>
            Save
          </Button>
          <Button variant="secondary" className="closeBtn">
            <Link to="/equipmentChecklists">Close</Link>
          </Button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  console.log(state.productReducer.products);
  const { categories } = state.drugsReducer;
  const { vehicleDetails, vehicles } = state.vehicleReducer;
  const { products } = state.productReducer;
  const {
    equipments,
    total,
    currentPage,
    equipmentDetails,
    pageSize,
    pageIndex,
    rows,
    columns,
    sortInfo,
  } = state.equipmentReducer;
  console.log(state.equipmentReducer.equipmentDetails);
  return {
    categories,
    vehicleList: vehicles,
    vehicleDetails,
    equipments,
    equipmentDetails,
    products,
    rows,
    EquipmentChecklistsColumns: columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getEquipmentCheckList: (obj) =>
      dispatch(equipmentChecklistsService.getEquipmentCheckList(obj)),
    addEquipmentChecklist: (obj) =>
      dispatch(equipmentChecklistsService.addEquipmentChecklist(obj)),
    getEquipmentDetails: (checklistId) =>
      dispatch(equipmentChecklistsService.getEquipmentDetails(checklistId)),
    editVehicle: (objVehicle, vehicleId) =>
      dispatch(vehiclesService.updateVehicle(objVehicle, vehicleId)),
    updateEquipmentChecklist: (obj, checklistId) =>
      dispatch(
        equipmentChecklistsService.updateEquipmentChecklist(obj, checklistId)
      ),
    getVehicleDetails: (vehicleId) =>
      dispatch(vehiclesService.getVehicleDetails(vehicleId)),
    getProductList: (obj) => dispatch(productsService.getProductList(obj)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddEquipmentChecklist)
);
