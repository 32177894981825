import React, { useEffect, useState } from "react";
import { Button, Col, Form } from "react-bootstrap";
import Select from "commonComponents/Select";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../commonComponents/Input";
import Multiselect from "multiselect-react-dropdown";
import DragAndDrop from "../../commonComponents/DragAndDrop";
import drugsService from ".././../service/DrugsService";
import { selectVehicleStatus } from "Constant/Column";
import ProductServices from ".././../service/CommonServices";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { withRouter } from "react-router";
import Title from "commonComponents/Title";
import vehiclesService from "service/VehiclesServices";
import moment from "moment";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import "../../assets/css/addVehicle.css";

function AddVehicle(props) {
  const isEditPage =
    props && props.match && props.match.params && props.match.params.vehicleId
      ? true
      : false;
  const vehicleId =
    props && props.match && props.match.params && props.match.params.vehicleId;
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [vehicleUnitId, setVehicleUnitId] = useState("");
  const [locationType, setLocationType] = useState("");
  const [radioId, setRadioId] = useState("");
  const [fleet, setFleet] = useState("");
  const [licensePlateNumber, setLicensePlateNumber] = useState("");
  const [vehicleType, setVehicleType] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");
  const [vehicleModelYear, setVehicleModelYear] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [serviceDate, setServiceDate] = useState(
    moment().utc().format("YYYY-MM-DD")
  );
  const [equipmentChecklistGuid, setEquipmentChecklistGuid] = useState([]);
  const [isMatched, setIsMatched] = useState(false);
  const [description, setDescription] = useState("");
  const [isFormSubmitted, setForm] = useState(false);

  /**
   * Return the selected category
   * @param {*} selectedList
   * @param {*} selectedItem
   */
  const onSelect = (selectedList, selectedItem) => {
    setCategories(selectedList);
  };

  useEffect(async () => {
    if (props.match.params.vehicleId) {
      let { vehicleId } = props.match.params;
      props.getVehicleDetails(vehicleId).then((data) => {
        setImage(props.vehicleDetails.image);
        setName(props.vehicleDetails.name);
        setVehicleUnitId(props.vehicleDetails.vehicleUnitId);
        setRadioId(props.vehicleDetails.radioId);
        if (props.vehicleDetails.vehicleFleet) {
          setFleet(props.vehicleDetails.vehicleFleet.name);
        }
        setLicensePlateNumber(props.vehicleDetails.licensePlateNumber);
        setVehicleStatus(props.vehicleDetails.vehicleStatus);
        setVehicleModelYear(props.vehicleDetails.vehicleModelYear);
        setVehicleType(props.vehicleDetails.vehicleType);
        setLocationType(props.vehicleDetails.locationType);
        setVehicleModel(props.vehicleDetails.vehicleModel);
        setServiceDate(
          moment(props.vehicleDetails.serviceDate).utc().format("YYYY-MM-DD")
        );
      });
    }
    props.getVehicleType();
    props.getVehicleFleet();
  }, [props.match.params.vehicleId]);

  const validateForm = () => {
    const flag =
      name != undefined &&
      vehicleUnitId != "" &&
      radioId != undefined &&
      licensePlateNumber != undefined &&
      vehicleType != undefined &&
      vehicleStatus != undefined &&
      vehicleModelYear != undefined &&
      vehicleModel != "" &&
      vehicleModel != undefined &&
      serviceDate != "";

    return flag;
  };

  const resetForm = () => {
    setName("");
    setVehicleUnitId("");
    setRadioId("");
    setFleet("");
    setLicensePlateNumber("");
    setVehicleStatus("");
    setVehicleModelYear("");
    setVehicleType("");
    setEquipmentChecklistGuid("");
    setLocationType("");
    setVehicleModel;
    setForm(false);
  };

  const filter = createFilterOptions();

  const onSubmit = () => {
    const isValidForm = validateForm();
    const objVehicle = new FormData();
    objVehicle.append("name", name);
    objVehicle.append("vehicleUnitId", vehicleUnitId);
    objVehicle.append("radioId", radioId);  
    objVehicle.append("fleet", fleet == null || fleet == undefined ? '' : fleet ) 
    //  objVehicle.append("fleet", fleet.name);
    objVehicle.append("locationType", "Vehicle");
    objVehicle.append("licensePlateNumber", licensePlateNumber);
    props.vehicleDetails.vehicleType === vehicleType
      ? objVehicle.append("vehicleType", vehicleType)
      : objVehicle.append("vehicleType", vehicleType.vehicleType);
    //  objVehicle.append("vehicleType", vehicleType.vehicleType);
    objVehicle.append("vehicleStatus", vehicleStatus);
    objVehicle.append("vehicleModelYear", vehicleModelYear);
    objVehicle.append("serviceDate", serviceDate);
    objVehicle.append("vehicleModel", vehicleModel);
    // objVehicle.append("equipmentChecklistGuid", vehicleModelYear);
    objVehicle.append("image", image);

    if (isValidForm) {
      if (isEditPage) {
        props
          .editVehicle(objVehicle, vehicleId)
          .then((res) => {
            if (res) {
              if (res.isError) {
                toast.error(res.msg);
              } else {
                // toast.success(res.msg);
              }
              setTimeout(() => {
                resetForm();
                props.history.push("/vehicles");
              }, 1000);
            }
          })
          .catch(function (error) {
            toast.error(error.response.data?.msg);
          });
      } else {
        props
          .addVehicle(objVehicle)
          .then((res) => {
            if (res) {
              if (res.isError) {
                toast.error(res.msg);
              }
              setTimeout(() => {
                resetForm();
                props.history.push("/vehicles");
              }, 1000);
            }
          })
          .catch(function (error) {
            toast.error(error?.response?.data?.msg);
          });
      }
    }
    setForm(true);
  };

  const getImage = (file, imageData) => {
    imageData ? setImage(imageData) : "";
  };
  const title = isEditPage ? "Edit Location" : "New Location";
  const fleets = props.vehicleFleet;
  const types = props.vehicleTypes;
  return (
    <div>
      <ToastContainer autoClose={1000} />
      <Title isActiveFlage={false} title={title} />
      <div className="registration_form">
        <div>
          <DragAndDrop imageUpload={getImage} image={image}></DragAndDrop>
        </div>
        <Form className="form-group-action" onSubmit={onSubmit}>
          <Input
            label="NAME"
            value={name}
            isInvalid={isFormSubmitted && name === ""}
            type="input"
            placeholder="Enter vehicle name"
            id="name"
            onChange={(e) => setName(e.target.value)}
          />
          <Input
            label="Unit ID"
            value={vehicleUnitId}
            isInvalid={isFormSubmitted && vehicleUnitId === ""}
            type="input"
            placeholder="Enter Unit ID"
            id="vehicleUnitId"
            onChange={(e) => setVehicleUnitId(e.target.value)}
          />
          {/* <Input
            label="Location Type"
            value={locationType}
            isInvalid={isFormSubmitted && locationType === ""}
            type="input"
            placeholder="Enter Location Type"
            id="locationType"
            onChange={(e) => setLocationType(e.target.value)}
          /> */}
          <Input
            label="Radio ID"
            value={radioId}
            isInvalid={isFormSubmitted && radioId === ""}
            type="input"
            placeholder="Enter Radio ID"
            id="radioId"
            onChange={(e) => setRadioId(e.target.value)}
          />
          {/* <Input
            value={vehicleFleet}
            isInvalid={isFormSubmitted && vehicleFleet === ""}
            label="Fleet"
            type="input"
            placeholder="Select fleet"
            id="fleet"
            onChange={(e) => setVehicleFleet(e.target.value)}
          ></Input> */}

          <div className="providerRole-auto">
            <label htmlFor="fleet" className="col-sm-2">
              FLeet
            </label>
            <Autocomplete
              value={fleet}
              className="col-md-6"
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setFleet({
                    name: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setFleet({
                    name: newValue.inputValue,
                  });
                } else {
                  setFleet(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.name
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    name: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={fleets}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.name;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.name}</li>
              )}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="fleet" />}
            />
          </div>
          <Input
            label="Licence Plate Number"
            value={licensePlateNumber}
            isInvalid={isFormSubmitted && licensePlateNumber === ""}
            type="input"
            placeholder="Enter license Plate Number"
            id="licensePlateNumber"
            onChange={(e) => setLicensePlateNumber(e.target.value)}
          />
          {/* <Input
            label="Type"
            value={vehicleType}
            isInvalid={isFormSubmitted && vehicleType === ""}
            type="input"
            placeholder="Enter vehicleType"
            id="vehicleType"
            onChange={(e) => setVehicleType(e.target.value)}
          /> */}
          <div className="providerRole-auto">
            <label htmlFor="vehicle type" className="col-sm-2">
              Type
            </label>
            <Autocomplete
              value={vehicleType}
              className="col-md-6"
              onChange={(event, newValue) => {
                if (typeof newValue === "string") {
                  setVehicleType({
                    vehicleType: newValue,
                  });
                } else if (newValue && newValue.inputValue) {
                  // Create a new value from the user input
                  setVehicleType({
                    vehicleType: newValue.inputValue,
                  });
                } else {
                  setVehicleType(newValue);
                }
              }}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);

                const { inputValue } = params;
                // Suggest the creation of a new value
                const isExisting = options.some(
                  (option) => inputValue === option.vehicleType
                );
                if (inputValue !== "" && !isExisting) {
                  filtered.push({
                    inputValue,
                    vehicleType: `Add "${inputValue}"`,
                  });
                }

                return filtered;
              }}
              selectOnFocus
              autoHighlight
              clearOnBlur
              handleHomeEndKeys
              id="free-solo-with-text-demo"
              options={types}
              getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === "string") {
                  return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                  return option.inputValue;
                }
                // Regular option
                return option.vehicleType;
              }}
              renderOption={(props, option) => (
                <li {...props}>{option.vehicleType}</li>
              )}
              sx={{ width: 300 }}
              freeSolo
              renderInput={(params) => <TextField {...params} label="Type" />}
            />
          </div>
          {/* <Input
            label="Vehicle Status"
            value={vehicleStatus}
            isInvalid={isFormSubmitted && vehicleStatus === ""}
            type="input"
            placeholder="Enter Vehicle Status"
            id="vehicleStatus"
            onChange={(e) => setVehicleStatus(e.target.value)}
          /> */}
          <Select
            label="Vehicle Status"
            value={vehicleStatus}
            isInvalid={isFormSubmitted && vehicleStatus === ""}
            optionValue={selectVehicleStatus}
            placeholder="Enter Vehicle Status"
            id="vehicleStatus"
            onChange={(e) => setVehicleStatus(e.target.value)}
          ></Select>
          <Input
            label="Vehicle Model"
            value={vehicleModel}
            isInvalid={isFormSubmitted && vehicleModel === ""}
            type="input"
            placeholder="Enter Vehicle Model"
            id="vehicleModel"
            onChange={(e) => setVehicleModel(e.target.value)}
          />
          <Input
            label="Vehicle Model Year"
            value={vehicleModelYear}
            isInvalid={isFormSubmitted && vehicleModelYear === ""}
            type="input"
            placeholder="Enter Vehicle Model Year"
            id="vehicleModelYear"
            onChange={(e) => setVehicleModelYear(e.target.value)}
          />

          {/* <Input
            label="Service Date"
            value={serviceDate}
            isInvalid={isFormSubmitted && serviceDate === ""}
            type="input"
            placeholder="Enter Service Date"
            id="serviceDate"
            onChange={(e) => setServiceDate(e.target.value)}
          ></Input> */}
          <Input
            label="Service Date"
            value={serviceDate}
            isInvalid={isFormSubmitted && serviceDate === ""}
            type="date"
            placeholder="Enter Service Date"
            id="serviceDate"
            onChange={(e) => setServiceDate(e.target.value)}
          ></Input>
          {/* <Input
            label="Equipment Checklist Guid"
            value={equipmentChecklistGuid}
            isInvalid={isFormSubmitted && equipmentChecklistGuid === ""}
            type="input"
            placeholder="Enter Equipment Checklist Guid"
            id="equipmentChecklistGuid"
            onChange={(e) => setEquipmentChecklistGuid(e.target.value)}
          ></Input> */}
          <div className="form-action">
            <Button variant="primary" onClick={onSubmit}>
              Save
            </Button>
            <Button variant="secondary" className="closeBtn">
              <Link to="/vehicles">Close</Link>
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  console.log(state.vehicleReducer);
  const { categories } = state.drugsReducer;

  const {
    vehicles,
    total,
    currentPage,
    pageSize,
    pageIndex,
    rows,
    columns,
    sortInfo,
    vehicleDetails,
    vehicleFleet,
    vehicleTypes,
  } = state.vehicleReducer;
  console.log(state.vehicleReducer);
  return {
    categories,
    vehicleFleet,
    vehicleTypes,
    vehicles,
    vehicleDetails,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addVehicle: (objVehicle) =>
      dispatch(vehiclesService.addVehicle(objVehicle)),
    editVehicle: (objVehicle, vehicleId) =>
      dispatch(vehiclesService.updateVehicle(objVehicle, vehicleId)),
    getVehicleDetails: (vehicleId) =>
      dispatch(vehiclesService.getVehicleDetails(vehicleId)),
    getVehicleFleet: () => dispatch(vehiclesService.getVehicleFleet()),
    getVehicleType: () => dispatch(vehiclesService.getVehicleType()),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AddVehicle)
);
