import React, { useState } from "react";
import ReceivingDetailGrid from "./ReceivingDetailGrid";
import axios from "axios";
import { BaseURLMock } from "Constant/BaseURL";

const ReceivingPacking = ({ receiving_id, actionToggle }) => {
  const dataSource = {
    load(loadOptions) {
      const parentIdsParam = loadOptions.parentIds;
      let url = "";
      // if (parentIdsParam[0].length === 0) {
      //   url = new URL(
      //     `${BaseURLMock}/ShipmentManifests/${receiving_id}/Root-Objects`
      //   );
      // } else {
      //   url = new URL(
      //     `${BaseURLMock}/ShipmentManifests/${receiving_id}/Objects/${parentIdsParam[0]}/Children`
      //   );
      // }
      
      url = new URL(
        `${BaseURLMock}/ShipmentManifests/${receiving_id}/Objects`
      );
      if (parentIdsParam) {
        parentIdsParam.forEach((parentGuid) => {
          url.searchParams.append("parentGuid", parentGuid);
        });
      }

      return axios(url.toString(), {
        method: "GET",
        params: {
          companyGuid: localStorage.companyGuid,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
        },
      })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("@login_details");
            localStorage.removeItem("custodyToken");
            window.location.reload();
          }
          throw new Error("Data Loading Error");
        });
    },
  };

  return <ReceivingDetailGrid dataSource={dataSource} />;
};
function areEqual(prevProps, nextProps) {
  // Only re-render if the actionToggle prop changes
  return prevProps.actionToggle === nextProps.actionToggle;
}

export default React.memo(ReceivingPacking, areEqual);
