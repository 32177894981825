import DrugDetailMainTableContainer from "../../container/DrugDetailMainTableContainer";
import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import DrugDetailOverViewContainer from "../../container/DrugDetailOverviewContainer";
import DrugItemOverviewContainer from "../../container/DrugItemOverviewContainer";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import drugsService from "../../service/DrugsService";
import ProductServices from "../../service/CommonServices";
import { AllList } from "../../commonComponents/DropDown";
import { Button as DxButton } from "devextreme-react/button";
import DataGrid, {
  Scrolling,
  Selection,
  Pager,
  Paging,
  Column,
  HeaderFilter,
  StateStoring,
  ColumnChooser,
  Toolbar,
  Item,
} from "devextreme-react/data-grid";
import { Paper } from "@mui/material";
import { detailColumns } from "columns/detailColumn";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { BaseURL } from "Constant/BaseURL";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=drug`,
});

class DrugDetail extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
      categoryStatus: "Active",
    };
  }

  getDrugs = (params = {}) => {
    const { pageSize, pageIndex } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "drug",
      ...params,
    };
    this.props.getDrugList(obj);
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
  }

  sync = async (params = {}) => {
    await this.getDrugs(params);
    await this.props.getActiveCategory("drug");
  };

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  onRowClick(e) {
    this.props.history.push({
      pathname: `/drug/${e.key}`,
      state: {
        assetName: e.data.name,
      },
    });
    e.component.selectRows(e.key);
  }
  handleSelect = async (val) => {
    await this.setState({
      selectedItem: val,
    });
    await this.props.changePage(0);
    val === "All Drugs"
      ? this.getDrugs()
      : // this.sync({
      //   isActive : parseInt(val)
      // });
      val === "0" || val === "1"
      ? this.sync({
          isActive: parseInt(val),
        })
      : this.sync({
          isActive: "1",
          category: val,
        });
  };

  render() {
    const { match, location, activeCategories } = this.props;
    const { dropdownOpen, selectedItem, categoryStatus } = this.state;
    let isDrugItemTagPage = false;
    let drug_id;
    let drug_item_tag_id;
    let { drugName } = location?.state ? location?.state : "";
    /**
     * To display main table in all screen,
     * drug_id and drug_item_id is inserted into the URL to switch between the pages
     */
    drug_id = match.params.drug_id;
    if (match.params.drug_item_id) {
      drug_item_tag_id = match.params.drug_item_id;
    }
    return (
      <div>
        <Row className="drug-detail-table-actions">
          <Col lg="3 quick-navigation">
            <Paper>
              <DataGrid
                id="miniGridContainer"
                dataSource={dataSource}
                keyExpr="GUID"
                showBorders={false}
                hoverStateEnabled={true}
                // rowAlternationEnabled={true}
                ref={this.dataGrid}
                onRowClick={(e) => {
                  this.onRowClick(e);
                }}
                onRowDblClick={(e) => {
                  this.onRowClick(e);
                }}
                onContentReady={(e) => {
                  if (!e.component.getSelectedRowKeys().length) {
                    e.component.selectRows(drug_id);
                  }
                }}
              >
                <ColumnChooser enabled={true} mode={"select"} width={0} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="drug-main-grid"
                  savingTimeout={100}
                />
                <Column
                  dataField="IMAGE"
                  cssClass="hide-drug-detail-column"
                ></Column>
                <Column dataField="name" caption="NAME" />
                <Column
                  dataField="manufacturer"
                  cssClass="hide-drug-detail-column"
                />
                <Column
                  dataField="UNSPSCCode"
                  cssClass="hide-drug-detail-column"
                />
                <Column dataField="ndc" cssClass="hide-drug-detail-column" />
                <Column
                  dataField="dossage"
                  cssClass="hide-drug-detail-column"
                />
                <Column
                  dataField="strength"
                  cssClass="hide-drug-detail-column"
                />
                <Column
                  dataField="netContent"
                  cssClass="hide-drug-detail-column"
                />
                <Column
                  dataField="inventoryQuantity"
                  cssClass="hide-drug-detail-column"
                />
                <Column
                  dataField="expiryDate"
                  cssClass="hide-drug-detail-column"
                />
                <HeaderFilter visible={false} allowSearch={false} />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.props.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                {/* <Toolbar visible={false}>
                <Item name="columnChooserButton" location="after" />
              </Toolbar> */}
                <Toolbar>
                  <Item location="after">
                    <Button variant="primary">
                      <Link to="/addDrug"> + New</Link>
                    </Button>
                  </Item>
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={() => {
                        this.sync();
                        this.dataGrid.current.instance.refresh();
                      }}
                    />
                  </Item>
                </Toolbar>
              </DataGrid>
            </Paper>
          </Col>
          {match.params.drug_item_id ? (
            <Col lg="9" className="drug-item-detail-overview">
              <DrugItemOverviewContainer
                drugName={drugName}
                drug_id={drug_id}
                drug_item_tag_id={drug_item_tag_id}
              />
            </Col>
          ) : (
            <Col lg="9" className="detail-overview">
              <DrugDetailOverViewContainer
                drugName={drugName}
                drug_id={drug_id}
              />
            </Col>
          )}
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { drugs, activeCategories, rows, pageIndex, pageSize } =
    state.drugsReducer;
  return {
    activeCategories,
    drugs: drugs,
    rows,
    pageIndex,
    pageSize,
  };
};

/**
 * To perform add,get,delete and update action on drugs
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getDrugList: (obj) => dispatch(ProductServices.getAllProductList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    getFilteredDrugList: (type) =>
      dispatch(drugsService.getFilteredDrugList(type)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    changePage: (pageNo) => dispatch(drugsService.changePage(pageNo)),
    // allProductCategory: () => dispatch(drugsService.allProductCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(DrugDetail);
