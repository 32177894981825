import React from "react";
import { Row as RowDetail } from "./Row";
import Placeholder from '../assets/img/ambulance.svg';

export default function OverViewTabVehicle({ OverViewTabsData }) {
  return (
    <>
      {OverViewTabsData.map((data) =>
        data.type === "image" ? (
          <div className="drug-detail-action">
            {data.value ? (
              <img src={data.value} style={{width:"25%", height:"15%"}}></img>
            ) : (
              <img src={Placeholder} style={{color:"black"}}></img>
            )}
          </div>
        ) : (
          <RowDetail name={data.name} value={data.value}></RowDetail>
        )
      )}
    </>
  );
}
