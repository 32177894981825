import OverviewProductTable from "commonComponents/OverviewProductTable";
import React, { Component } from "react";
import { connect } from "react-redux";
import UserServices from "service/UserServices";
import ProductServices from "service/CommonServices";
import { Col, Row } from "react-bootstrap";
import ChecklistOverviewHeader from "commonComponents/Overview/ChecklistHistoryOverview";
import ChecklistOverviewHeaderIncomplete from "commonComponents/Overview/checklistOverviewIncomplete";
import OverviewProductTabs from "commonComponents/OverviewProductTabs";
import { OverviewMovementHistory } from "commonComponents/OverviewMovementHistory";
import VehicleCurrentInventory from "../../commonComponents/VehicleCurrentInventory";
import EquipmentChecklistHistory from "../../commonComponents/EquipmentChecklistHistory";
import OverViewTab from "commonComponents/OverViewTab";
import { getDateTime } from "commonMethod/common";
import { withRouter } from "react-router";
import { get } from "lodash";
import vehiclesService from "../../service/VehiclesServices";
import VehicleList from "../vehicles/VehicleList";
import EquipmentList from "./EquipmentList";
import equipmentChecklistsService from "service/EquipmentChecklistsServices";
import Demo from './HistoryDetailTable';
import { useRouter } from '../../hooks/routeHooks'
import { BaseURL } from "Constant/BaseURL";
import User from '../../assets/img/user_place.png'


export class ChecklistHistoryDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            columnModal: false,
            selectedItem: "",
            selectedUser: "",
            categoryStatus: "",
            key: "overview",
            pageSize: 10,
            pageIndex: 1,
        };
    }

    componentDidMount() {
        this.sync();
    }

    sync = async (newObj = {}) => {
        const { pageSize, pageIndex, match, vehicleList, equipmentList } = this.props;
        // const { vehicleId } = match.params;
        const { historyId } = match.params;
        let obj = {
            rows: pageSize,
            page: pageIndex,
            field: "GUID",
            sortOrder: "ASC",
            ...newObj,
        };
        // await this.props.getVehicleList(obj);
        await this.props.getEquipmentCheckList(obj);
        await this.handleGetEquipmentDetails(this.props.match.params.checklistId);
        // await this.handleGetVehicleDetails(vehicleId);
        await this.handleGetEquipmentChecklistHistoryDetails(historyId);
    };

    onSortedChange = (sorted) => {
        const { pageSize, pageIndex } = this.props;
        const { id, desc } = sorted[0];
        const obj = {
            rows: pageSize,
            page: pageIndex,
            field: id,
            sortOrder: desc ? "DES" : "ASC",
        };
        this.sync(obj);
    };

    // onMovementSortedChange = (sorted) => {
    //   const { usersDetails } = this.props;
    //   const { pageSize, pageIndex } = this.state;
    //   const { id, desc } = sorted[0];
    //   const obj = {
    //     rows: pageSize,
    //     page: pageIndex,
    //     field: id,
    //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
    //     sortOrder: desc ? "DES" : "ASC",
    //   };
    //   this.handleGetMovementHistory(obj);
    // };

    handleBack = () => {
        this.props.history.goBack()
    }

    onPageSizeChange = async (pageSize, pageIndex) => {
        await this.props.changePageSize(pageSize);
        this.sync();
    };

    onPageChange = async (pageIndex) => {
        await this.props.changePage(pageIndex);
        this.sync();
    };

    handleSelectDropDown = async (val) => {
        // await this.props.changePage(0);
        val === "All Checklists"
            ? this.sync()
            : this.sync({
                isActive: parseInt(val),
            });
    };

    handleSelect = async (val) => {
        // await this.props.changePage(0);
        val === "All Checklists"
            ? this.sync()
            : this.sync({
                isActive: parseInt(val),
            });
    };

    handleGetEquipmentChecklistHistoryDetails = (id) => {
        const { getEquipmentChecklistHistoryDetails } = this.props;
        //    this.setState({ key: "overview" });
        getEquipmentChecklistHistoryDetails(id);
    };

    handleGetEquipmentDetails = (id) => {
        const { getEquipmentDetails } = this.props;
        this.setState({ key: "overview" });
        getEquipmentDetails(id);
    };



    handleGetEquipmentChecklistHistory = (id) => {
        const { getEquipmentChecklistHistory } = this.props;
        this.setState({ key: "overview" });
        getEquipmentChecklistHistory(id);
    };

    // handleGetMovementHistory = (newObj) => {
    //   const { getMovementHistory, usersDetails } = this.props;
    //   const { pageSize, pageIndex } = this.state;
    //   let obj = {
    //     rows: pageSize,
    //     page: pageIndex,
    //     field: "firstSeen",
    //     sortOrder: "DES",
    //     GUID: usersDetails && usersDetails.badgeEPC && usersDetails.badgeEPC.GUID,
    //     ...newObj,
    //   };
    //   getMovementHistory(obj);
    // };

    handleTabs = (k) => {
        this.setState({ key: k });
    };

    onHandleMoreOperation = async (key) => {
        const { usersDetails } = this.props;
        if (key === "markActive") {
            await this.props.updateUserStatus(
                [usersDetails.GUID],
                !usersDetails.isActive
            );
            await this.handleGetEquipmentDetails(usersDetails.GUID);
        } else if (key === "delete") {
            await this.props.deleteBulkUsers([usersDetails.GUID]);
            this.props.history.push("/vehicles");
        }
    };

    handleTdProps = (rowInfo) => {

        this.handleGetEquipmentDetails(rowInfo.original.GUID);
        this.props.history.push({
            pathname: `/equipmentChecklist/${rowInfo.original.GUID}`,
            state: {
                assetName: rowInfo.original.name,
            },
        });
        this.handleGetEquipmentChecklistHistory(rowInfo.original.GUID)
    };

    onPageSizeChange = (pageSize, pageIndex) => {
        this.setState({ pageSize, pageIndex });
    };

    onPageChange = (pageIndex) => {
        this.setState({ pageIndex });
    };

    handleTrProps = (rowInfo) => {
        const { checklistId } = this.props.match.params;


        return {
            style: {
                background: rowInfo.original.GUID === checklistId && "#EBECF0",
            },
            className: "table-row",
        };

    };

    render() {
        const {
            vehicleList,
            equipmentList,
            usersDetails,
            checklistHistory,
            checklistHistoryDetails,
            vehicleDetails,
            equipmentDetails,
            pageIndex,
            total,
            match,
            pageSize,
            movementList,
            isActive, isActiveFlage,
            loginUser

        } = this.props;
        // const router = useRouter()
        // const previousPath = get(router, 'location.state.from.pathname')

        console.log(checklistHistoryDetails);
        console.log(equipmentDetails.GUID);

        const millisToMinutesAndSeconds = (millis) => {
            var minutes = Math.floor(millis / 60000);
            var seconds = ((millis % 60000) / 1000).toFixed(0);
            return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
        }



        const { key } = this.state;
        const { checklistId } = match.params;
        const OverViewTabsData = [
            {
                type: "image",
                value: vehicleDetails.image,
            },
            {
                name: "Name",
                value: equipmentDetails.name,
            },
            {
                name: "description",
                value: equipmentDetails.description,
            },
            {
                name: "Assigned Vehicles",
                value: equipmentDetails.assignedVehicles,
            },
            {
                name: "History Count",
                value: equipmentDetails.histtoryCount,
            },
            //   {
            //     name: "License Plate",
            //     value: vehicleDetails.licensePlateNumber,
            //   },
            //   {
            //     name: "Vehicle Status",
            //     value: vehicleDetails.vehicleStatus,
            //   },
            //   {
            //     name: "Vehicle Model",
            //     value: vehicleDetails.vehicleModel,
            //   },
            //   {
            //     name: "Model Year",
            //     value: vehicleDetails.vehicleModelYear,
            //   },
            //   {
            //     name: "Service Date",
            //     value: vehicleDetails?.serviceDate? getDateTime(vehicleDetails?.serviceDate) : '',
            //   },
        ];
        const TabsDetails = [
            {
                name: "Overview",
                EventKey: "overview",
                Component: <OverViewTab OverViewTabsData={OverViewTabsData} />,
            },

            //   {
            //     name: "Current Inventory",
            //     EventKey: "inventory",
            //     Component: (
            //       <VehicleCurrentInventory
            //         onPageSizeChange={this.onPageSizeChange}
            //         onPageChange={this.onPageChange}
            //       />
            //     ),

            //   },
            //   {
            //     name: "Movement History",
            //     EventKey: "movement",
            //     Component: (
            //       <OverviewMovementHistory
            //         onPageSizeChange={this.onPageSizeChange}
            //         onPageChange={this.onPageChange}
            //         movementList={movementList}
            //         handleGetMovementHistory={this.handleGetMovementHistory}
            //         onSortedChange={this.onMovementSortedChange}
            //       />
            //     ),

            //   },
            {
                name: "Checklist History",
                EventKey: "checklist",
                Component: (
                    <EquipmentChecklistHistory
                        onPageSizeChange={this.onPageSizeChange}
                        onPageChange={this.onPageChange}
                        historyData={checklistHistory}
                    />
                ),

            },

        ];


        const role = this.props.systemRole;
        console.log(role)
        return (
            <>
                <Row className="drug-detail-table-actions">
                    <Col lg="3 quick-navigation">
                        <EquipmentList
                            tableCol={12}
                            handleTrProps={this.handleTrProps}
                            getVehicleDetails={this.getVehicleDetails}
                            handleTdProps={this.handleTdProps}
                            name={"Checklist"}
                            history={this.props.history}
                            addButtonURl={"/addEquipmentChecklist"}
                            tableData={equipmentList}
                            pageSize={pageSize}
                            total={total}
                            pageIndex={pageIndex}
                            onPageChange={this.onPageChange}
                            onPageSizeChange={this.onPageSizeChange}
                            handleSelect={this.handleSelect}
                            onSortedChange={this.onSortedChange}
                            userId={checklistId}
                            onHandleMoreOperation={this.onHandleMoreOperation}
                            selectedProduct={usersDetails}
                            handleTabs={this.handleTabs}
                            tabKey={key}
                            pages={total / pageSize}
                            module="equipmentChecklist"
                        />
                    </Col>

                    <Col lg="9" className="drug-item-detail-overview">

                        {/* {
            (role == 'Staff' && usersDetails.systemRole == 'Staff') || (role == 'Owner' && usersDetails.systemRole == 'Owner') || (role == 'Staff' && usersDetails.systemRole == 'Owner')
              ? <Row className="drug_title">
                <h3 className="drug_name" style={{ marginBottom: "10px" }}>{vehicleDetails && vehicleDetails.name}</h3>
                {isActiveFlage && <h4 className={`drug_status ${isActive ? "" : 'drug_inactive'}`}>{isActive ? "" : <CategoryChip variant="secondary" name="INACTIVE" />}</h4>}
              </Row> */}
                        {
                            checklistHistoryDetails && checklistHistoryDetails.isCompleted === true
                                ? <ChecklistOverviewHeader
                    
                                    history={this.props.history}
                                    title={checklistHistoryDetails && checklistHistoryDetails.name}
                                    complete={"COMPLETE"}
                                    duration={checklistHistoryDetails && millisToMinutesAndSeconds(checklistHistoryDetails.durationMilliSecs)}
                               
                                />
                                : <ChecklistOverviewHeaderIncomplete
                                
                                    history={this.props.history}
                                    title={checklistHistoryDetails && checklistHistoryDetails.name}
                                    complete={"INCOMPLETE"}
                                    duration={checklistHistoryDetails && millisToMinutesAndSeconds(checklistHistoryDetails.durationMilliSecs)}
                                
                                />
                        }


                        <hr className="hr-margin" />

                        <div style={{ display: "flex", justifyContent: "flex-start", paddingTop: "10px" }} >
                            <div style={{ marginRight: "50px" }}>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <span className="history-label-check">DATE & TIME </span>
                                    <span className="history-value-check">{getDateTime(checklistHistoryDetails.eventTime)}</span>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <span className="history-label-check">SHIFT </span>
                                    <span className="history-value-checkShift"> Tuesday 7am-7pm</span>
                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <span className="history-label-check">EMPLOYEE </span>
                                    <span className="history-value-check">
                                        {
                                            checklistHistoryDetails.employee.avatar ?
                                                <img src={`${BaseURL}/images/users/${checklistHistoryDetails.employee.avatar}`} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                                : <img src={User} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                        }

                                        {checklistHistoryDetails.employee.name}

                                        <p className="history-label-1">{checklistHistoryDetails.employee.role}</p>
                                    </span>

                                </div>
                                <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                    <span className="history-label-check">SUPERVISOR</span>
                                    <span className="history-value-check">
                                        {
                                            checklistHistoryDetails.supervisor.avatar ?
                                                <img src={`${BaseURL}/images/users/${checklistHistoryDetails.supervisor.avatar}`} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                                : <img src={User} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                        }
                                        {checklistHistoryDetails.supervisor.name}

                                        <p className="history-label-1">{checklistHistoryDetails.supervisor.role}</p>
                                    </span>
                                </div>
                                {
                                    checklistHistoryDetails.crew.map((value) => {
                                        return (

                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label-check">CREW</span>
                                                <div className="history-value-check">
                                                    {
                                                        value.avatar ?
                                                            <img src={`${BaseURL}/images/users/${value.avatar}`} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                                            : <img src={User} alt="" srcset="" style={{ marginRight: "10px" }} className="user-img-profile" />
                                                    }
                                                    {value.name}

                                                    <p className="history-label-1">{value.role}</p>
                                                </div>


                                            </div>


                                        );
                                    })
                                }


                            </div>
                            {
                                checklistHistoryDetails.vehicle.map((value) => {
                                    return (

                                        <div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">VEHICLE </span>
                                                <span className="history-value">{value.name}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Unit No.</span>
                                                <span className="history-value">{value.vehicleUnitId}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Radio Id</span>
                                                <span className="history-value">{value.radioId}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Fleet</span>
                                                <span className="history-value">{value.vehicleFleet}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">License Plate</span>
                                                <span className="history-value">{value.licensePlateNumber}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Type</span>
                                                <span className="history-value">{value.vehicleType}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Model</span>
                                                <span className="history-value">{value.vehicleModel}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Model Year</span>
                                                <span className="history-value">{value.vehicleModelYear}</span>
                                            </div>
                                            <div style={{ display: "flex", justifyContent: "flex-start" }}>
                                                <span className="history-label">Status</span>
                                                <span className="history-value">{value.vehicleStatus}</span>
                                            </div>
                                        </div>

                                    );
                                })
                            }


                        </div>
                        <hr className="hr-margin" />
                        <div>
                            <div style={{ display: "flex", justifyContent: "flex-start", padding: "10px" }}>
                                <span className="history-label-c">COMMENTS</span>
                                <span className="history-value-c">{checklistHistoryDetails.comments}</span>
                            </div>
                            <hr className="hr-margin" />
                        </div>


                        <div className='section-table-container'>
                            <Demo data={checklistHistoryDetails.sectionResults} />
                        </div>

                    </Col>

                </Row>

            </>
        );
    }
}

const makeChecklistDetails = (checklistHistoryDetails) => {
    return {
        GUID: null,
        "name": "",
        "isCompleted": true,
        "durationMilliSecs": 0,
        "eventTime": "",
        "comments": "",
        "employee": {
            "GUID": null,
            "name": "",
            "role": ""
        },
        "supervisor": {
            "GUID": null,
            "name": "",
            "role": ""
        },
        "crew": [],
        "vehicle": [],
        "sectionResults": [],
        ...checklistHistoryDetails
    }
}

const mapStateToProps = (state) => {
    console.log(state.equipmentReducer)
    const {
        usersList,
        userColumns,
        pageIndex,
        usersMovementHistory,
        pageSize,
        total,
        currentPage,
        usersDetails,
        rows,
    } = state.usersReducer;
    const { vehicleDetails, vehicles } = state.vehicleReducer;
    const { equipments, equipmentDetails, columns, sortInfo } = state.equipmentReducer;
    const { checklistHistory, checklistHistoryDetails } = state.equipmentChecklistHistoryReducer;
    console.log(state.equipmentChecklistHistoryReducer);
    return {
        usersList,
        vehicleList: vehicles,
        equipmentList: equipments,
        userColumns,
        checklistHistory,
        checklistHistoryDetails: makeChecklistDetails(checklistHistoryDetails),
        pageSize,
        pageIndex,
        currentPage,
        usersMovementHistory,
        usersDetails,
        vehicleDetails,
        equipmentDetails: equipmentDetails,
        rows,
        total,
        userData: state.loginReducer.userData,
        systemRole: get(state, 'loginReducer.userData.systemRole'),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getUsersList: (obj) => dispatch(UserServices.getAllUserList(obj)),
        getVehicleList: (obj) => dispatch(vehiclesService.getVehicleList(obj)),
        getEquipmentCheckList: (obj) => dispatch(equipmentChecklistsService.getEquipmentCheckList(obj)),

        getVehicleDetails: (vehicleId) => dispatch(vehiclesService.getVehicleDetails(vehicleId)),
        getEquipmentDetails: (checklistId) => dispatch(equipmentChecklistsService.getEquipmentDetails(checklistId)),
        getEquipmentChecklistHistory: (checklistId) => dispatch(equipmentChecklistsService.getEquipmentChecklistHistory(checklistId)),
        getEquipmentChecklistHistoryDetails: (historyId) => dispatch(equipmentChecklistsService.getEquipmentChecklistHistoryDetails(historyId)),
        updateUserStatus: (ids, isActive) =>
            dispatch(UserServices.updateUserStatus(ids, isActive)),
        changePage: (pageNo) => dispatch(UserServices.changeUsersPage(pageNo)),
        changePageSize: (pageSize) =>
            dispatch(UserServices.changeUsersPageSize(pageSize)),
        deleteBulkUsers: (id) => dispatch(UserServices.deleteBulkUsers(id)),
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ChecklistHistoryDetails));