import DrugsTableContainer from "../../container/DrugsTableContainer";
import React from "react";
import Placeholder from "../../assets/img/placeholder-picture-image.png";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import ModalDialog from "../../commonComponents/ModalDialog";
import { Link } from "react-router-dom";
import { AllList } from "../../commonComponents/DropDown";
import { BulkAction } from "../../commonComponents/ItemDropDown";
import { connect } from "react-redux";
import ProductServices from "../../service/CommonServices";
import drugsService from "../../service/DrugsService";
import AssetsColumnSelector from "commonComponents/AssetsColumnSelector";
import { DrugsColumns } from "Constant/Column";
import Multiselect from "multiselect-react-dropdown";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../service/LoginServices";
import { Switch, useHistory } from "react-router";
import { Button as DxButton } from "devextreme-react/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { jsPDF } from "jspdf";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Button as GridButton,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import { BaseURL } from "../../Constant/BaseURL";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid } from "devextreme/excel_exporter";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getTime } from "commonMethod/common";
import { getDateColor } from "commonMethod/common";
// import { SelectionState } from "@devexpress/dx-react-grid";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const position = { of: "#gridAssetContainer" };

const renderGridCell = (data) => {
  return data.text == "" ? (
    ""
  ) : getDateColor(data.text, 2) ? (
    <div style={{ color: "red" }}>{getTime(data.text)}</div>
  ) : getDateColor(data.text, 5) ? (
    <div style={{ color: "#E8912D" }}>{getTime(data.text)}</div>
  ) : (
    <div style={{ color: "black" }}>{getTime(data.text)}</div>
  );
};
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=drug`,
});

const exportFormats = ["pdf", "xlsx"];

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class Drugs extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      show: false,
      isMultiSelected: false,
      selectedFilter: "",
      columnModal: false,
      name: "",
      selectedIds: [],
      selectedItem: "",
      categoryStatus: "Active",
      categoryName: "",
      categoryList: [],
      isMatched: false,
      dropdownOpen: false,
      bulkActionOpen: false,
      description: "",
      isDescription: false,
    };
    this.onExporting = this.onExporting.bind(this);
    if (localStorage.getItem("drugsColumns")) {
      localStorage.getItem("drugsColumns");
    } else {
      localStorage.setItem("drugsColumns", JSON.stringify(DrugsColumns));
    }
    // if(this.props.userData && this.props.userData.systemRole === 'Worker'){
    //   this.props.history.push('/login');
    // }
  }

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  /**
   * Perform network request to get drugs list data
   */
  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
    if (this.props.userData.systemRole == "Worker") {
      alert("Web access is not available for your account");
      window.localStorage.setItem(
        "@login_details",
        '{"userData":{},"msg":"","token":null}'
      );
      window.location.reload(false);
    }
  }

  /**
   * handle sync action to get latest data from server
   */
  sync = async (params = {}) => {
    const { pageSize, pageIndex } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "drug",
      ...params,
    };

    await this.props.getDrugList(obj);
    await this.props.getActiveCategory("drug"); //.then(this.setState({categoryStatus: 'Active'}))
  };

  /**
   * Toggle add category modal
   */
  toggleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  handleSubmit = async (e) => {
    const { getProductCategory } = this.props;
    const { selectedIds, categoryList } = this.state;
    // let isDescription = description.length === 0;
    let isMatched = categoryList.length === 0;

    if (!isMatched) {
      let category = categoryList[0].name; // categoryList.map((item) => item.name);
      let obj = {
        GUIDS: selectedIds,
        name: category,
        // description : description
      };
      await getProductCategory(obj, "drug").then(() => {
        this.sync();
      });
      this.setState({
        show: !this.state.show,
        categoryList: [],
        selectedIds: [],
      });
    } else {
      this.setState({ isMatched });
    }
  };

  handleDelete = () => {
    const { deleteBulkDrugs } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      deleteBulkDrugs(selectedIds).then(() => {
        this.sync();
        this.dataGrid.current.instance.refresh();
      });
    }
    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleActiveFilter = async (val) => {
    const { getProductFilter } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      let filterActive = val === "0" ? false : true;
      await getProductFilter(selectedIds, filterActive).then(() => {
        this.sync();
      });
    }
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
      selectedItem: "",
    });
  };

  handleSelectAction = async (value) => {
    // await this.props.changePage(0);
    value === "Category"
      ? this.setState({ show: !this.state.show })
      : value === "Delete"
      ? this.handleDelete()
      : value === "0"
      ? this.handleActiveFilter(value)
      : value === "1"
      ? this.handleActiveFilter(value)
      : this.sync();
  };

  handleSelect = async (value) => {
    this.setState({
      selectedItem: value,
    });
    await this.props.changePage(0);
    value === "All Drugs"
      ? this.sync()
      : value === "0" || value === "1"
      ? this.sync({
          isActive: parseInt(value),
        })
      : this.sync({
          isActive: "1",
          category: value,
        });
    // if(value === '1'){
    //   this.props.getActiveCategory('drug').then(this.setState({categoryStatus: 'Active'}))
    // }else if(['All Drugs', '0'].includes(value)){
    //   this.setState({categoryStatus: ''})
    // }
  };

  // Return the bulk action value
  getMultiSelected = (e) => {
    this.setState({ isMultiSelected: e });
  };

  handleColumnSelectorModal = () => {
    this.setState({ columnModal: !this.state.columnModal });
  };

  getColumns = async (columns) => {
    localStorage.setItem("drugsColumns", JSON.stringify(columns));
    // await this.props.updateColumns(columns);
    this.setState({
      columnModal: false,
    });
  };

  setNewCategory = (categoryName) => {
    this.setState({ categoryName, isMatched: false });
  };

  // setDescription = (text) => {
  //   this.setState({ "description" : text, isDescription: false });
  // };

  getSelectedIds = (ids) => {
    this.setState({ selectedIds: ids });
  };

  clearFilter = () => {
    this.setState({
      selectedItem: "",
    });
  };

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
    });
  };

  clearCategory = () => {
    this.setState({
      categoryList: "",
    });
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      categoryName: "",
      categoryList: selectedList,
      isMatched: false,
    });
  };

  onAddCategory = () => {
    let text = this.state.categoryName;
    let newCategory = [...this.state.categoryList];
    let isMatched = newCategory.find((i) => i.name === text);
    if (!isMatched) {
      newCategory.push({ name: text, id: "" });
      this.setState({
        categoryList: [...newCategory],
        categoryName: "",
      });
    } else {
      this.setState({
        isMatched: true,
      });
    }
  };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.setState({ pageIndex }, () => this.sync());
  };
  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = selectedRowKeys;
    let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
    this.setState({ isMultiSelected: !_isMultiSelec, selectedIds: ids });
  };
  onRowClick(e) {
    if (e.rowType != "group") {
      this.props.history.push({
        pathname: `/drug/${e.key}`,
        state: {
          assetName: e.data.name,
        },
      });
    }
  }
  onExporting(e) {
    var PromiseArray = [];
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: async ({ gridCell, excelCell }) => {
          if (gridCell.rowType === "data") {
            if (gridCell.column.dataField === "image") {
              excelCell.value = undefined;
              if (gridCell.data.image != null || gridCell.data.image != "") {
                PromiseArray.push(
                  new Promise((resolve, reject) => {
                    this.addImageExcel(
                      gridCell.data.image,
                      workbook,
                      worksheet,
                      excelCell,
                      resolve
                    );
                  })
                );
              }
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "drugs.xlsx"
            );
          });
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        customDrawCell: (e) => {
          if (e.gridCell.rowType === "data") {
            if (e.gridCell.column.dataField === "image") {
              PromiseArray.push(
                new Promise((resolve, reject) => {
                  if (
                    e.gridCell.data.image != null ||
                    e.gridCell.data.image != ""
                  ) {
                    this.addImagePdf(
                      e.gridCell.data.image,
                      resolve,
                      function (myBase64) {
                        if (
                          myBase64.includes("image/png") ||
                          myBase64.includes("image/jpg") ||
                          myBase64.includes("image/jpeg")
                        ) {
                          console.log(myBase64, e.gridCell.data.image);
                          doc.addImage(
                            myBase64,
                            "png",
                            e.rect.x,
                            e.rect.y,
                            e.rect.w,
                            e.rect.h
                          );
                          e.cancel = true;
                        }
                      }
                    );
                  }
                })
              );
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          doc.save("drugs.pdf");
        });
      });
    }
  }
  DiffCell(cellData) {
    return (
      <div>
        {cellData.data.image ? (
          <img
            src={cellData.data.image}
            id={cellData.data.GUID}
            height="50"
            width="50"
          ></img>
        ) : (
          <img src={Placeholder} height="50" width="50"></img>
        )}
      </div>
    );
  }
  addImageExcel(url, workbook, worksheet, excelCell, resolve) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        const image = workbook.addImage({
          base64: base64data,
          extension: "png",
        });
        worksheet.getRow(excelCell.row).height = 75;
        worksheet.addImage(image, {
          tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
          br: { col: excelCell.col, row: excelCell.row },
        });
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  addImagePdf(url, resolve, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        callback(base64data);
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  render() {
    const {
      categories,
      currentPage,
      pageSize,
      activeCategories,
      pageIndex,
      total,
    } = this.props;
    const {
      show,
      selectedIds,
      categoryList,
      categoryName,
      categoryStatus,
      columnModal,
      isMultiSelected,
      isMatched,
      selectedItem,
      bulkActionOpen,
      dropdownOpen,
      description,
      isDescription,
    } = this.state;

    const options =
      categories.length > 0
        ? categories.map((i) => {
            return { name: i.name, id: i.id };
          })
        : [];
    let drugsColumns = JSON.parse(localStorage.getItem("drugsColumns"));
    return (
      // <div>
      //   <Row className="table-actions">
      //     {this.state.show &&
      //     <ModalDialog
      //       show={this.state.show}
      //       title={"Add Category"}
      //       closeDialog={this.toggleModal}
      //       onSubmit={this.handleSubmit}
      //     >
      //       <Col sm="12">
      //         <Form.Group>
      //           <Form.Label column sm="2">
      //             Categories
      //           </Form.Label>
      //           <Col sm="6">
      //             <Multiselect
      //               options={options}
      //               selectedValues={categoryList.length > 0 ? categoryList : ""}
      //               onSelect={this.onSelect}
      //               onRemove={this.onSelect}
      //               singleSelect={true}
      //               placeholder={
      //                 categoryList.length > 0 ? "" : "Select Category"
      //               }
      //               displayValue="name"
      //             />
      //             {isMatched && (
      //               <span column sm={`1`} className="danger-action">
      //                 {" "}
      //                 {"Category not selected."}
      //               </span>
      //             )}
      //           </Col>
      //         </Form.Group>
      //         <Form.Group>
      //           <Form.Label column sm={"2"}></Form.Label>
      //           <Col sm={"4"}>
      //             <Form.Control
      //               value={categoryName}
      //               type={"input"}
      //               isInvalid={isMatched}
      //               placeholder={"Add New Category"}
      //               onChange={(e) => {
      //                 this.setNewCategory(e.target.value); this.clearCategory();
      //               }}
      //             />
      //             {isMatched && (
      //               <span column sm={`1`} className="danger-action">
      //                 {" "}
      //                 {categoryName + " is already in categories."}
      //               </span>
      //             )}
      //           </Col>
      //           {categoryName !== "" && (
      //             <Button variant="primary" onClick={this.onAddCategory}>
      //               Add Category
      //             </Button>
      //           )}
      //         </Form.Group>
      //         {/* <Form.Group>
      //           <Form.Label column sm="2">
      //             Description
      //           </Form.Label>
      //           <Col sm="6">
      //             <Form.Control
      //               value={description}
      //               type={"input"}
      //               isInvalid={isDescription}
      //               placeholder={"description"}
      //               name="description"
      //               onChange={(e) => {
      //                 this.setDescription(e.target.value);
      //               }}
      //             />
      //             {isDescription && (
      //               <span column sm={`1`} className="danger-action">
      //                 {" Description is required"}
      //               </span>
      //             )}
      //           </Col>
      //         </Form.Group> */}
      //       </Col>
      //     </ModalDialog>}
      //     <Col lg="4" md="3" sm="2">
      //       {selectedIds.length === 0 ? (
      //         <AllList
      //           handleSelect={this.handleSelect}
      //           selectedItem={selectedItem}
      //           categoryStatus={categoryStatus}
      //           list={activeCategories}
      //           name={"Drugs"}
      //           toggleFilter={this.toggleFilter}
      //           dropdownOpen={dropdownOpen}
      //         />
      //       ) : (
      //         <BulkAction
      //           handleSelectAction={this.handleSelectAction}
      //           toggleBulk={this.toggleBulk}
      //           bulkActionOpen={bulkActionOpen} />
      //       )}
      //     </Col>

      //     <Col lg="8" md="6" sm="4" className="table-inner-actions">
      //       <div>
      //         <Button variant="primary">
      //           <Link to="/addDrug"> + New</Link>
      //         </Button>
      //       </div>
      //       <div className="sync">
      //         <Button style={{padding:"2px 9px", backgroundColor:"#f5f5f5", borderColor:"#ddd"}} onClick={() => { this.sync(); this.clearFilter(); this.clearMultiselect(); }}>
      //           <i className="fas fa-sync"></i>
      //         </Button>
      //       </div>
      //       <div className="sync">
      //         <Button style={{padding:"2px 9px", backgroundColor:"#f5f5f5", borderColor:"#ddd", color:"#212529"}} onClick={this.handleColumnSelectorModal}>
      //           <i className="fas fa-cog"></i>
      //         </Button>
      //       </div>
      //       {columnModal &&
      //         <AssetsColumnSelector
      //           show={columnModal}
      //           title={'Add Columns'}
      //           closeDialog={this.handleColumnSelectorModal}
      //           getColumns={this.getColumns}
      //           columns={drugsColumns}
      //           onSubmit={this.handleColumnDrugs}
      //         />
      //       }
      //     </Col>
      //   </Row>
      //   <div className="drugs-main-table">
      //     <DrugsTableContainer
      //       getMultiSelected={this.getMultiSelected}
      //       // getChangedRowSelect={this.getChangedRowSelect}
      //       getSelectedIds={this.getSelectedIds}
      //       selectedIdProps={selectedIds}
      //       isMultiSelectedProps={isMultiSelected}
      //       columns={drugsColumns}
      //       pageSize={pageSize}
      //       pageIndex={pageIndex}
      //       total={total}
      //       pages={total / pageSize}
      //       currentPage={currentPage}
      //       onPageSizeChange={this.onPageSizeChange}
      //       onPageChange={this.onPageChange}
      //     />
      //   </div>
      // </div>
      <>
        {/* <div className="drugs-main-table"> */}
        {/* <AssetsContainerTable
            pageSize={pageSize}
            pageIndex={pageIndex}
            getSelectedIds={this.getSelectedIds}
            getMultiSelected={this.getMultiSelected}
            isMultiSelectedProps={this.state.isMultiSelected}
            selectedIdProps={this.state.selectedIds}
            columns={AssetColumns}
            total={total}
            pages={total / pageSize}
            currentPage={currentPage}
            onPageSizeChange={this.onPageSizeChange}
            onPageChange={this.onPageChange}
          /> */}
        <>
          <Container fluid>
            <Row>
              {show && (
                <ModalDialog
                  show={show}
                  title={"Add Category"}
                  closeDialog={this.toggleModal}
                  onSubmit={this.handleSubmit}
                >
                  <Col sm="12">
                    <Form.Group>
                      <Form.Label column sm="2">
                        Categories
                      </Form.Label>
                      <Col sm="6">
                        <Multiselect
                          options={options}
                          selectedValues={
                            categoryList.length > 0 ? categoryList : ""
                          }
                          onSelect={this.onSelect}
                          onRemove={this.onSelect}
                          singleSelect={true}
                          placeholder={
                            categoryList.length > 0 ? "" : "Select Category"
                          }
                          displayValue="name"
                        />
                        {isMatched && (
                          <span column sm={`1`} className="danger-action">
                            {" "}
                            {"Category not selected."}
                          </span>
                        )}
                      </Col>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label column sm={"2"}></Form.Label>
                      <Col sm={"4"}>
                        <Form.Control
                          value={categoryName}
                          type={"input"}
                          isInvalid={isMatched}
                          placeholder={"Add New Category"}
                          onChange={(e) => {
                            this.setNewCategory(e.target.value);
                            this.clearCategory();
                          }}
                        />
                        {isMatched && (
                          <span column sm={`1`} className="danger-action">
                            {categoryName + " is already in categories."}
                          </span>
                        )}
                      </Col>
                      {categoryName !== "" && (
                        <Button variant="primary" onClick={this.onAddCategory}>
                          Add Category
                        </Button>
                      )}
                    </Form.Group>
                    {/* <Form.Group>
                  <Form.Label column sm="2">
                    Description
                  </Form.Label>
                  <Col sm="6">
                    <Form.Control
                      value={description}
                      type={"input"}
                      isInvalid={isDescription}
                      placeholder={"description"}
                      name="description"
                      onChange={(e) => {
                        this.setDescription(e.target.value);
                      }}
                    />
                    {isDescription && (
                      <span column sm={`1`} className="danger-action">
                        {" Description is required"}
                      </span>
                    )}
                  </Col>
                </Form.Group> */}
                  </Col>
                </ModalDialog>
              )}
              <Col lg="2" md="2" sm="4">
                {/* <div style={{ display: "flex" }}>
                      {selectedIds.length > 0 ? (
                        <BulkAction
                          bulkActionOpen={this.state.bulkActionOpen}
                          toggleBulk={this.toggleBulk}
                          handleSelectAction={this.handleSelectAction}
                        />
                      ) : (
                        ""
                      )}
                    </div> */}
              </Col>
              <Col lg="10" md="10" sm="8" className="table-inner-actions">
                {/* <div>
                      <Button variant="primary">
                        <Link to="/addAsset"> + New</Link>
                      </Button>
                    </div> */}
              </Col>
            </Row>
            <Col md="">
              <DataGrid
                id="gridDrugContainer"
                dataSource={dataSource}
                keyExpr="GUID"
                hoverStateEnabled={true}
                showRowLines={false}
                selectedRowKeys={selectedIds}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                defaultSelectedRowKeys={selectedIds}
                customizeColumns={this.customizeColumns}
                onSelectionChanged={this.onSelectionChanged}
                onExporting={this.onExporting}
                ref={this.dataGrid}
                onRowClick={(e) => {
                  this.onRowClick(e);
                }}
                onRowDblClick={(e) => {
                  this.onRowClick(e);
                }}
                columnAutoWidth={true}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="drug-main-grid"
                  savingTimeout={100}
                />
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <LoadPanel enabled position={position} />
                <FilterRow visible={false} applyFilter={"Immediately"} />
                <ColumnChooser enabled={true} mode={"select"} />
                <SearchPanel enabled={true} visible={true} width={300} />
                <Toolbar>
                  <Item location="before">
                    <div style={{ display: "flex" }}>
                      {selectedIds.length > 0 ? (
                        <BulkAction
                          bulkActionOpen={this.state.bulkActionOpen}
                          toggleBulk={this.toggleBulk}
                          handleSelectAction={this.handleSelectAction}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </Item>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.dataGrid.current.instance.state(null);
                        this.sync();
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" /> */}
                  <Item location="after">
                    <Button variant="primary">
                      <Link to="/addDrug"> + New</Link>
                    </Button>
                  </Item>
                  <Item name="exportButton" location="after" />
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={() => {
                        this.sync();
                        this.dataGrid.current.instance.refresh();
                      }}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" />
                  <Item name="searchPanel" location="before" />
                </Toolbar>

                <Column
                  dataField="image"
                  caption="IMAGE"
                  cellRender={this.DiffCell}
                  width="70"
                  allowFiltering={false}
                  allowGrouping={false}
                  allowSorting={false}
                ></Column>

                <Column
                  dataField="name"
                  caption="NAME"
                  allowHiding={false}
                  width="140"
                ></Column>
                <Column
                  dataField="manufacturer"
                  caption="MANUFACTURER"
                  visible={true}
                  width="140"
                />
                <Column
                  dataField="UNSPSCCode"
                  caption="UNPSC CODE"
                  width="110"
                  visible={true}
                />
                <Column
                  dataField="ndc"
                  caption="NDC"
                  width="110"
                  visible={true}
                />
                <Column dataField="dossage" caption="DOSAGE" visible={true} />
                <Column
                  dataField="strength"
                  caption="STREGTH"
                  width="120"
                  alignment="left"
                  visible={true}
                />
                <Column
                  dataField="netContent"
                  caption="NET CONTENT"
                  visible={true}
                  width="120"
                />
                <Column
                  dataField="inventoryQuantity"
                  caption="INVENTORY QUANTITY"
                  dataType="number"
                  width="180"
                  alignment="center"
                  visible={true}
                />
                <Column
                  dataField="expiryDate"
                  caption="NEXT EXPIRY"
                  dataType="date"
                  cellRender={renderGridCell}
                  visible={true}
                  customizeText={(cellInfo) => {
                    return getTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getTime(cellInfo.value);
                  }}
                />
                    <Column
                      width={125}
                      type="buttons"
                      allowFiltering={false}
                      allowGrouping={false}
                      allowSorting={false}
                    >
                      <GridButton render={renderReorderButton} />
                    </Column>
                <Export
                  enabled={true}
                  allowExportSelectedData={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <HeaderFilter visible={true} allowSearch={true} />
                <Selection mode="multiple" />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging
                  defaultPageSize={this.props.pageSize}
                  onPageSizeChange={this.onPageSizeChange}
                  // onPageIndexChange={this.onPageIndexChange}
                  // pageIndex={this.state.pageSize}
                />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Summary>
                  <GroupItem
                    column="name"
                    summaryType="count"
                    displayFormat="{0}"
                  />
                  {/* <GroupItem
                        column="inventoryQuantity"
                        summaryType="sum"
                        showInGroupFooter={true}
                        displayFormat={"Sub-total: {0}"}
                      />
                      <GroupItem
                        name="expiryDateGroupSummary"
                        showInColumn="expiryDate"
                        summaryType="custom"
                        showInGroupFooter={true}
                      /> */}
                  <TotalItem
                    column="inventoryQuantity"
                    summaryType="sum"
                    displayFormat={"TOTAL: {0}"}
                  />
                </Summary>
              </DataGrid>
            </Col>
          </Container>
        </>
        {/* </div> */}
      </>
    );
  }
}
const calculateCustomSummary = (options) => {
  // REF: https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/summary/
  if (options.name == "expiryDateGroupSummary") {
    // Enforce blank/empty value in group summary for 'EXPIRY DATE' column
    options.totalValue = {};
  }
};

const renderReorderButton = (button) => {
  return <Button variant="primary" size="sm" disabled style={{ fontFamily: "Inter, \"Source Sans Pro\", Helvetica, Arial, sans-serif", fontSize: "11px", verticalAlign: "middle" }}>REORDER</Button>;
  // return <Button variant="primary" size="sm" disabled style={{ fontFamily: "\"Open Sans\", sans-serif", fontSize: "13px", fontWeight: "400", verticalAlign: "middle", backgroundColor: "#337ab7", borderColor: "#337ab7" }}>REORDER</Button>;
  // return <DxButton type="default" text="REORDER"></DxButton>
};

const mapStateToProps = (state) => {
  const {
    categories,
    drugs,
    activeCategories,
    total,
    currentPage,
    pageSize,
    pageIndex,
    rows,
    columns,
  } = state.drugsReducer;
  return {
    categories,
    drugs,
    pageIndex,
    activeCategories,
    pageSize,
    total,
    currentPage,
    rows,
    columns,
    userData: state.loginReducer.userData,
  };
};

/**
 * To perform add,get,delete and update action on drugs
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getDrugList: (obj) => dispatch(ProductServices.getAllProductList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    deleteBulkDrugs: (id) => dispatch(ProductServices.deleteBulkProducts(id)),
    getProductFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductStatus(ids, isActive)),
    getFilteredDrugList: (type) =>
      dispatch(drugsService.getFilteredDrugList(type)),
    getProductCategory: (obj, productType) =>
      dispatch(ProductServices.addProductCategory(obj, productType)),
    filterProductByCategory: (category, obj) =>
      dispatch(drugsService.filterProductByCategory(category, obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    changePage: (pageNo) => dispatch(drugsService.changePage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(drugsService.changePageSize(pageSize)),
    updateColumns: (columns) => dispatch(drugsService.updateColumns(columns)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Drugs);
