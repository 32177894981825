import React from "react";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import { Container, Row, Col } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import AssetsService from "../../service/AssetsService";
import Pagination from "commonComponents/Pagination";
import { getDateTime } from "commonMethod/common";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Button as DxButton } from "devextreme-react/button";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const exportFormats = ["xlsx", "pdf"];
const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class AssetLifeCycleList extends React.Component {
  constructor(props) {
    super(props);
    this.assetLifeCycleGrid = React.createRef();
    this.state = {
      pageSize: 20,
      pageIndex: 0,
      initialData: [],
    };
    this.refreshDataGrid = this.refreshDataGrid.bind(this);
  }

  getAllLifeCycleList = () => {
    const { asset_item_tag_id } = this.props;
    let obj = {
      itemTagId: asset_item_tag_id,
    };
    this.props.getAllLifeCycleList(obj);
  };
  /**
   * Perform network request to get AssetLifeCycleList data
   */
  componentDidMount() {
    this.getAllLifeCycleList();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.asset_item_tag_id !== prevProps.asset_item_tag_id) {
      this.getAllLifeCycleList();
    }
  }

  /**
   *  When user click on column this will sorting the table data
   * @param {*} sorted
   */

  /**
   *  Return the selected pageSize
   * @param {*} pageSize
   * @param {*} pageIndex
   */

  onPageSizeChange = (pageSize, pageIndex) => {
    this.setState({ pageSize, pageIndex });
  };

  /**
   *  When user click on column this will sorting the table data
   * @param {*} sorted
   */
  onSortedChange = (sorted) => {
    AssetLifeCycleList.sort = sorted;
  };

  /**
   * Call back for the page changes
   * @param {*} pageIndex
   */

  onPageChange = (pageIndex) => {
    this.setState({ pageIndex });
  };

  getAssetItems = async (params = {}) => {
    const { pageSize, pageIndex } = this.state;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "itemEPC",
      sortOrder: "ASC",
      id: this.props.asset_id,
      productType: "asset",
      ...params,
    };
  };

  onExporting(e) {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "MovementHistory.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("MovementHistory.pdf");
      });
    }
  }
  refreshDataGrid() {
    this.assetLifeCycleGrid.current.instance.refresh();
    this.getAllLifeCycleList();
  }

  render() {
    const columns = [
      {
        width: 230,
        Header: "Time Stamp",
        accessor: "eventTime",
        Cell: ({ row }) => (
          <span>
            {row._original.eventTime && getDateTime(row._original.eventTime)}
          </span>
        ),
      },
      {
        width: 200,
        Header: "Person",
        accessor: "person",
      },
      {
        width: 200,
        Header: "Old Status",
        accessor: "oldStatus",
      },
      {
        width: 120,
        Header: "New Status",
        accessor: "newStatus",
      },
    ];

    const { assetLifeCycleList } = this.props;
    const { pageSize, pageIndex } = this.state;

    let lifeCycleList = assetLifeCycleList.data
      ? assetLifeCycleList.data.map((list) => {
          return list;
        })
      : "";

    return (
      <>
        <Container fluid className="table">
          <Row>
            <Col md="">
              {/* <ReactTable
                data={lifeCycleList || []}
                columns={columns}
                onSortedChange={this.onSortedChange}
                defaultPageSize={pageSize}
                loadingText="Loading the assets"
                noDataText="Couldn't find asset life cycle data"
                onPageSizeChange={this.onPageSizeChange}
                onPageChange={this.onPageChange}
                onSortedChange={this.onSortedChange}
                getTrGroupProps={(state, rowInfo, column, instance) => {
                  if (rowInfo !== undefined) {
                    return {
                      style: {
                        background: rowInfo.index % 2 ? "#fffff" : "#F5F5F5",
                      },
                      className: "table-row",
                    };
                  }
                }}
              />
              {lifeCycleList !== "" && (
                <Pagination
                  pageSize={pageSize}
                  currentPage={pageIndex + 1}
                  total={lifeCycleList.length}
                />
              )} */}

              <DataGrid
                id="girdMovementHistory"
                dataSource={lifeCycleList}
                keyExpr="GUID"
                showBorders={false}
                rowAlternationEnabled={false}
                ref={this.assetLifeCycleGrid}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                onExporting={this.onExporting}
              >
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <FilterRow visible={false} applyFilter={"Immediately"} />
                {/* <Selection mode="multiple" /> */}
                <HeaderFilter visible={true} allowSearch={true} />
                <ColumnChooser enabled={true} mode={"select"} />
                <SearchPanel enabled={true} visible={true} width={300} />

                <Column
                  dataField="eventTime"
                  dataType="datetime"
                  caption="Record Change Date"
                  customizeText={(cellInfo) => {
                    return getDateTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getDateTime(cellInfo.value);
                  }}
                  visible={false}
                />
                <Column dataField="user.name" caption="Record Changed By" />
                <Column dataField="oldStatus" caption="Old Status" />
                <Column dataField="newStatus" caption="New Status" />

                <Summary>
                  <GroupItem
                    column="eventTime"
                    summaryType="count"
                    displayFormat="{0}"
                  />
                </Summary>
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="asset-item-lifecycle-record-grid"
                />
                <Export
                  enabled={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.state.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={this.state.pageSize}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Toolbar>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.assetLifeCycleGrid.current.instance.state(null);

                        this.getAssetItems();
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" /> */}
                  <Item name="exportButton" location="after" />
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={this.refreshDataGrid}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" />
                  <Item name="searchPanel" location="before" />
                </Toolbar>
              </DataGrid>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assetLifeCycleList: state.assetLifeCycleListReducer.assetLifeCycleList,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLifeCycleList: (obj) =>
      dispatch(AssetsService.getAllLifeCycleList(obj)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AssetLifeCycleList)
);
