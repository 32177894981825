import React from 'react';
import { useLocation } from 'react-router-dom';
import { Navbar, Container, Nav, Button } from 'react-bootstrap';
import Search from '../../commonComponents/Search';
import routes from 'routes.js';
import Profile from './Profile';
import UserProfile from './UserProfile';
import '../../assets/css/main.css';
import NavIcons from './navIcons';
import { BsBell } from 'react-icons/bs';
import { FaRegFolderOpen } from 'react-icons/fa';
import { BsGear } from 'react-icons/bs';
import { VscQuestion } from 'react-icons/vsc';

function Header({ onLogout, toggleDrawer, setToggleDrawer }) {
  const location = useLocation();
  const mobileSidebarToggle = (e) => {
    // e.preventDefault();
    // document.documentElement.classList.toggle("nav-open");
    // var node = document.createElement("div");
    // node.id = "bodyClick";
    // node.onclick = function () {
    //   this.parentElement.removeChild(this);
    //   document.documentElement.classList.toggle("nav-open");
    // };
    // document.body.appendChild(node);
  };

  // const getBrandText = () => {
  //   for (let i = 0; i < routes.length; i++) {
  //     if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
  //       return routes[i].name;
  //     }
  //   }
  //   return "Brand";
  // };

  return (
    <Navbar bg='light' expand='lg'>
      <Container fluid>
        <div className='d-flex justify-content-center align-items-center ml-2 ml-lg-0'>
          {/* <Button
            variant="dark"
            className="d-lg-none btn-fill d-flex justify-content-center align-items-center rounded-circle p-2"
            onClick={mobileSidebarToggle}
          >
            <i className="fas fa-ellipsis-v"></i>
          </Button> */}
          {/* <Navbar.Brand
            href="#home"
            onClick={(e) => e.preventDefault()}
            className="mr-2"
          >
          </Navbar.Brand> */}
        </div>
        <Navbar.Toggle aria-controls='basic-navbar-nav' className='mr-2' onClick={() => setToggleDrawer(true)}>
          <span className='navbar-toggler-bar burger-lines'></span>
          <span className='navbar-toggler-bar burger-lines'></span>
          <span className='navbar-toggler-bar burger-lines'></span>
        </Navbar.Toggle>
        <Navbar.Collapse id='basic-navbar-nav' className='justify-content-between'>
          <Nav className='nav ' navbar>
            {/* <Nav.Item>
              <Nav.Link
                className="m-0 navbar-collapse-logout"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
              </Nav.Link>
            </Nav.Item> */}
            <div>
              <Search />
            </div>
          </Nav>
          <Nav className='flex-row'>
            <div className='icons-nav d-none d-sm-block'>
              <BsBell className='nav-bell'></BsBell>
            </div>
            <div className='icons-nav d-none d-sm-block'>
              <FaRegFolderOpen className='nav-folder'></FaRegFolderOpen>
            </div>
            <div className='icons-nav d-none d-sm-block'>
              <BsGear className='nav-bell'></BsGear>
            </div>
            <div className='icons-nav d-none d-sm-block'>
              <VscQuestion className='nav-question'></VscQuestion>
            </div>
            {/* </Nav>
          <Nav style={{ paddingBottom: "10px" }}> */}
            <Nav.Item>
              <div className='userprofile'>
                <UserProfile onLogout={onLogout} />
              </div>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
