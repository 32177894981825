import axios from "axios";
import {
  getAllCategoryURL,
  addCategoryURL,
  editDrugStatusURL,
  deleteDrugURL,
  editDrugItemStatusURL,
  deleteDrugItemURL,
} from "Constant/ApiRoutes";
import { BaseURL } from "Constant/BaseURL";
import { trackPromise } from "react-promise-tracker";

/**
 * To perform network request for the drugs
 */
class ProductsService {
  getProductList = ({ field = "GUID", sortOrder = "ASC" }) => {
    const self = this;
    return (dispatch) => {
      dispatch({ type: "GET_PRODUCT_LIST_STARTS" });
      return trackPromise(
        new Promise((resolve, reject) => {
          axios(`${BaseURL}/products`, {
            method: "GET",
            params: {
              field,
              sortOrder,
            },
          })
            .then((res) => {
              dispatch({
                type: "GET_PRODUCT_LIST_SUCCESS",
                payload: res.data.data,
              });
              resolve(res.data);
            })
            .catch(function (error) {
              dispatch({ type: "GET_PRODUCT_LIST_ERROR", payload: [] });
              reject(error);
            });
        })
      );
    };
  };
}

const productsService = new ProductsService();
export default productsService;
