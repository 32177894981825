import React from "react";
import { Button, Form, Row, Col, Container } from "react-bootstrap";
import ModalDialog from "../../commonComponents/ModalDialog";
import { Link } from "react-router-dom";
import { BulkAction } from "../../commonComponents/ItemDropDown";
import { connect } from "react-redux";
import disposablesService from "../../service/DisposablesService";
import DisposableTableContainer from "container/Disposable/DisposableTableContainer";
import { DisposableColumns } from "Constant/Column";
import Multiselect from "multiselect-react-dropdown";
import ProductServices from "service/CommonServices";
import { AllDrugs, AllAssets } from "commonComponents/DropDown";
import AssetsColumnSelector from "commonComponents/AssetsColumnSelector";
import { AllList } from "commonComponents/DropDown";
import { Button as DxButton } from "devextreme-react/button";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { jsPDF } from "jspdf";
import Placeholder from "../../assets/img/placeholder-picture-image.png";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  Pager,
  Column,
  Paging,
  Toolbar,
  StateStoring,
  Button as GridButton,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
  TotalItem,
} from "devextreme-react/data-grid";
import { LoadPanel } from "devextreme-react/load-panel";
import { BaseURL } from "../../Constant/BaseURL";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { exportDataGrid } from "devextreme/excel_exporter";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getDateColor } from "commonMethod/common";
import { getTime } from "commonMethod/common";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const position = { of: "#gridAssetContainer" };

const renderGridCell = (data) => {
  return data.text == "" ? (
    ""
  ) : getDateColor(data.text, 2) ? (
    <div style={{ color: "red" }}>{getTime(data.text)}</div>
  ) : getDateColor(data.text, 5) ? (
    <div style={{ color: "#E8912D" }}>{getTime(data.text)}</div>
  ) : (
    <div style={{ color: "black" }}>{getTime(data.text)}</div>
  );
};
const dataSource = createStore({
  key: "GUID",
  loadUrl: `${BaseURL}/products?productType=disposable`,
});

const exportFormats = ["pdf", "xlsx"];

const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
class Disposable extends React.Component {
  constructor(props) {
    super(props);
    this.dataGrid = React.createRef();
    this.state = {
      show: false,
      isMultiSelected: false,
      selectedFilter: "",
      columnModal: false,
      name: "",
      selectedIds: [],
      categoryList: [],
      selectedItem: "",
      categoryStatus: "Active",
      categoryName: "",
      isMatched: false,
      bulkActionOpen: false,
      dropdownOpen: false,
      description: "",
      isDescription: false,
    };
    this.onExporting = this.onExporting.bind(this);
    if (localStorage.getItem("disposableColumns")) {
      localStorage.getItem("disposableColumns");
    } else {
      localStorage.setItem(
        "disposableColumns",
        JSON.stringify(DisposableColumns)
      );
    }
  }

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  toggleBulk = () => {
    this.setState({ bulkActionOpen: !this.state.bulkActionOpen });
  };

  /**
   * Perform network request to get disposables list data
   */
  componentDidMount() {
    this.sync();
  }

  /**
   * handle sync action to get latest data from server
   */
  sync = async (newObj) => {
    const { pageSize, pageIndex } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "name",
      sortOrder: "ASC",
      productType: "disposable",
      ...newObj,
    };
    await this.props.getDisposableList(obj);
    await this.props.getActiveCategory("disposable");
    // .then(this.setState({ categoryStatus: "Active" }));
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
  }

  /**
   * Toggle add category modal
   */
  toggleModal = (e) => {
    this.setState({ show: !this.state.show });
  };

  handleSubmit = async (e) => {
    const { getProductCategory } = this.props;
    const { selectedIds, categoryList } = this.state;
    // let isDescription = description.length === 0;
    let isMatched = categoryList.length === 0;

    if (!isMatched) {
      let category = categoryList[0].name; // categoryList.map((item) => item.name);
      let obj = {
        GUIDS: selectedIds,
        name: category,
      };
      await getProductCategory(obj, "disposable").then(() => {
        this.sync();
      });
      this.setState({
        show: !this.state.show,
        categoryList: [],
        selectedIds: [],
      });
    } else {
      this.setState({ isMatched });
    }
  };

  // setDescription = (text) => {
  //   this.setState({ "description" : text, isDescription: false });
  // };

  handleDelete = () => {
    const { deleteBulkDisposable } = this.props;
    const { selectedIds } = this.state;
    if (selectedIds !== []) {
      deleteBulkDisposable(selectedIds).then(() => {
        this.sync();
        this.dataGrid.current.instance.refresh();
      });
    }
    this.setState({ selectedIds: [], isMultiSelected: false });
  };

  handleActiveFilter = (val) => {
    const { getProductFilter } = this.props;
    const { selectedIds, isMultiSelected } = this.state;

    if (selectedIds !== []) {
      let filterActive = val === "0" ? false : true;

      getProductFilter(selectedIds, filterActive).then(() => {
        this.sync();
      });
    }

    this.setState({
      selectedIds: [],
      isMultiSelected: false,
      selectedItem: "",
    });
  };

  handleSelectAction = async (value) => {
    // await this.props.changePage(0);
    value === "Category"
      ? this.setState({ show: !this.state.show })
      : value === "Delete"
      ? this.handleDelete()
      : value === "0"
      ? this.handleActiveFilter(value)
      : value === "1"
      ? this.handleActiveFilter(value)
      : this.sync();
  };

  // Display bulk action or page filter base on multi selection
  handleSelect = async (value) => {
    this.setState({
      selectedItem: value,
    });

    await this.props.changePage(0);

    value === "All Disposables"
      ? this.sync()
      : value === "0" || value === "1"
      ? this.sync({
          isActive: parseInt(value),
        })
      : this.sync({
          isActive: "1",
          category: value,
        });
    // if (value === "1") {

    // } else if (["All Disposables", "0"].includes(value)) {
    //   this.setState({ categoryStatus: "" });
    // }
  };

  // Return the bulk action value
  getMultiSelected = (e) => {
    this.setState({ isMultiSelected: e });
  };

  /**
   * Toggle Column Selector modal
   */
  handleColumnSelectorModal = () => {
    this.setState({ columnModal: !this.state.columnModal });
  };

  getColumns = (column) => {
    localStorage.setItem("disposableColumns", JSON.stringify(column));
    //this.props.updateDisposableColumns(column);
    this.setState({
      columnModal: false,
    });
  };

  setNewCategory = (categoryName) => {
    this.setState({ categoryName, isMatched: false });
  };

  clearCategory = () => {
    this.setState({
      categoryList: "",
    });
  };

  getSelectedIds = (ids) => {
    this.setState({ selectedIds: ids });
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({
      categoryName: "",
      categoryList: selectedList,
      isMatched: false,
    });
  };

  onAddCategory = () => {
    let text = this.state.categoryName;
    let newCategory = [...this.state.categoryList];
    let isMatched = newCategory.find((i) => i.name === text);
    if (!isMatched) {
      newCategory.push({ name: text, id: "" });
      this.setState({
        categoryList: [...newCategory],
        categoryName: "",
      });
    } else {
      this.setState({
        isMatched: true,
      });
    }
  };

  clearFilter = () => {
    this.setState({
      selectedItem: "",
    });
  };

  clearMultiselect = () => {
    this.setState({
      selectedIds: [],
      isMultiSelected: false,
    });
  };

  onPageSizeChange = async (pageSize, pageIndex) => {
    await this.props.changePageSize(pageSize);
    this.sync();
  };

  onPageChange = async (pageIndex) => {
    await this.props.changePage(pageIndex);
    this.sync();
  };
  onRowClick(e) {
    if (e.rowType != "group") {
      this.props.history.push({
        pathname: `/disposable/${e.key}`,
        state: {
          assetName: e.data.name,
        },
      });
    }
  }
  DiffCell(cellData) {
    return (
      <div>
        {cellData.data.image ? (
          <img
            src={cellData.data.image}
            id={cellData.data.GUID}
            height="50"
            width="50"
          ></img>
        ) : (
          <img src={Placeholder} height="50" width="50"></img>
        )}
      </div>
    );
  }
  onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    const { pageIndex, pageSize, isMultiSelected, selectedIds } = this.state;
    let ids = selectedRowKeys;
    let _isMultiSelec = selectedRowKeys.length > 0 ? true : false;
    this.setState({ isMultiSelected: !_isMultiSelec, selectedIds: ids });
  };
  addImageExcel(url, workbook, worksheet, excelCell, resolve) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        const image = workbook.addImage({
          base64: base64data,
          extension: "png",
        });
        worksheet.getRow(excelCell.row).height = 75;
        worksheet.addImage(image, {
          tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
          br: { col: excelCell.col, row: excelCell.row },
        });
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }
  addImagePdf(url, resolve, callback) {
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.onload = function () {
      var reader = new FileReader();
      reader.readAsDataURL(xhr.response);
      reader.onloadend = function () {
        var base64data = reader.result;
        callback(base64data);
        resolve();
      };
    };
    xhr.onerror = function () {
      console.error("could not add image to excel cell");
    };
    xhr.send();
  }

  onExporting(e) {
    var PromiseArray = [];
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Main sheet");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
        topLeftCell: { row: 2, column: 2 },
        customizeCell: async ({ gridCell, excelCell }) => {
          if (gridCell.rowType === "data") {
            if (gridCell.column.dataField === "IMAGE") {
              excelCell.value = undefined;
              if (gridCell.data.image != null || gridCell.data.image != "") {
                PromiseArray.push(
                  new Promise((resolve, reject) => {
                    this.addImageExcel(
                      gridCell.data.image,
                      workbook,
                      worksheet,
                      excelCell,
                      resolve
                    );
                  })
                );
              }
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          workbook.xlsx.writeBuffer().then(function (buffer) {
            saveAs(
              new Blob([buffer], { type: "application/octet-stream" }),
              "disposal.xlsx"
            );
          });
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
        customDrawCell: (e) => {
          if (e.gridCell.rowType === "data") {
            if (e.gridCell.column.dataField === "IMAGE") {
              PromiseArray.push(
                new Promise((resolve, reject) => {
                  if (
                    e.gridCell.data.image != null ||
                    e.gridCell.data.image != ""
                  ) {
                    console.log(this, "this");
                    this.addImagePdf(
                      e.gridCell.data.image,
                      resolve,
                      function (myBase64) {
                        if (
                          myBase64.includes("image/png") ||
                          myBase64.includes("image/jpg") ||
                          myBase64.includes("image/jpeg")
                        ) {
                          console.log(myBase64, e.gridCell.data.image);
                          doc.addImage(
                            myBase64,
                            "png",
                            e.rect.x,
                            e.rect.y,
                            e.rect.w,
                            e.rect.h
                          );
                          e.cancel = true;
                        }
                      }
                    );
                  }
                })
              );
            }
          }
        },
      }).then(() => {
        Promise.all(PromiseArray).then(() => {
          doc.save("disposal.pdf");
        });
      });
    }
  }
  render() {
    const {
      categories,
      currentPage,
      activeCategories,
      pageSize,
      pageIndex,
      total,
    } = this.props;
    const options =
      categories.length > 0
        ? categories.map((i) => {
            return { name: i.name, id: i.id };
          })
        : [];
    const {
      selectedIds,
      categoryName,
      isMatched,
      categoryList,
      show,
      selectedItem,
      categoryStatus,
      bulkActionOpen,
      dropdownOpen,
      isMultiSelected,
      columnModal,
      description,
      isDescription,
    } = this.state;
    let disposableColumns = JSON.parse(
      localStorage.getItem("disposableColumns")
    );
    return (
      <>
        <div className="drugs-main-table" style={{ paddingRight: "15px" }}>
          <Row>
            {show && (
              <ModalDialog
                show={show}
                title={"Add Category"}
                closeDialog={this.toggleModal}
                onSubmit={this.handleSubmit}
              >
                <Col sm="12">
                  <Form.Group>
                    <Form.Label column sm="2">
                      Categories
                    </Form.Label>
                    <Col sm="6">
                      <Multiselect
                        options={options}
                        selectedValues={
                          categoryList.length > 0 ? categoryList : ""
                        }
                        onSelect={this.onSelect}
                        onRemove={this.onSelect}
                        singleSelect={true}
                        placeholder={
                          categoryList.length > 0 ? "" : "Select Category"
                        }
                        displayValue="name"
                      />
                      {isMatched && (
                        <span column sm={`1`} className="danger-action">
                          {" "}
                          {"Category not selected."}
                        </span>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label column sm={"2"}></Form.Label>
                    <Col sm={"4"}>
                      <Form.Control
                        value={categoryName}
                        type={"input"}
                        isInvalid={isMatched}
                        placeholder={"Add New Category"}
                        onChange={(e) => {
                          this.setNewCategory(e.target.value);
                          this.clearCategory();
                        }}
                      />
                      {isMatched && (
                        <span column sm={`1`} className="danger-action">
                          {" "}
                          {categoryName + " is already in categories."}
                        </span>
                      )}
                    </Col>
                    {categoryName !== "" && (
                      <Button variant="primary" onClick={this.onAddCategory}>
                        Add Category
                      </Button>
                    )}
                  </Form.Group>
                  {/* <Form.Group>
                <Form.Label column sm="2">
                  Description
                </Form.Label>
                <Col sm="6">
                  <Form.Control
                    value={description}
                    type={"input"}
                    isInvalid={isDescription}
                    placeholder={"description"}
                    name="description"
                    onChange={(e) => {
                      this.setDescription(e.target.value);
                    }}
                  />
                  {isDescription && (
                    <span column sm={`1`} className="danger-action">
                      {" Description is required"}
                    </span>
                  )}
                </Col>
              </Form.Group> */}
                </Col>
              </ModalDialog>
            )}
          </Row>
          <div className="drugs-main-table">
            <Container fluid>
              <Row className="table-actions">
                <Col md="">
                  <DataGrid
                    id="gridDisposalContainer"
                    dataSource={dataSource}
                    keyExpr="GUID"
                    hoverStateEnabled={true}
                    // rowAlternationEnabled={true}
                    showRowLines={false}
                    selectedRowKeys={selectedIds}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={"widget"}
                    width={"100%"}
                    defaultSelectedRowKeys={selectedIds}
                    customizeColumns={this.customizeColumns}
                    onSelectionChanged={this.onSelectionChanged}
                    onExporting={this.onExporting}
                    ref={this.dataGrid}
                    onRowClick={(e) => {
                      this.onRowClick(e);
                    }}
                    onRowDblClick={(e) => {
                      this.onRowClick(e);
                    }}
                    columnAutoWidth={true}
                  >
                    <StateStoring
                      enabled={true}
                      type="localStorage"
                      storageKey="disposal-main-grid"
                      savingTimeout={100}
                    />
                    <FilterPanel visible={true} texts={filterPanelTexts} />
                    <FilterBuilderPopup position={filterBuilderPopupPosition} />
                    {/* <GroupPanel visible={true} /> */}
                    {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                    <LoadPanel enabled position={position} />
                    <FilterRow visible={false} applyFilter={"Immediately"} />
                    <ColumnChooser enabled={true} mode={"select"} />
                    <SearchPanel enabled={true} visible={true} width={300} />
                    <Toolbar>
                      <Item location="before">
                        <div style={{ display: "flex" }}>
                          {selectedIds.length > 0 ? (
                            <BulkAction
                              bulkActionOpen={this.state.bulkActionOpen}
                              toggleBulk={this.toggleBulk}
                              handleSelectAction={this.handleSelectAction}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </Item>
                      <Item location="before" locateInMenu={"always"}>
                        <DxButton
                          text="Reset Grid"
                          hint="Reset Grid"
                          icon="revert"
                          onClick={() => {
                            this.dataGrid.current.instance.state(null);
                            this.sync();
                          }}
                        />
                      </Item>
                      {/* <Item name="groupPanel" location="before" /> */}
                      <Item location="after">
                        <Button variant="primary">
                          <Link to="/AddDisposable"> + New</Link>
                        </Button>
                      </Item>
                      <Item name="exportButton" location="after" />
                      <Item location="after">
                        <DxButton
                          hint="Refresh"
                          icon="refresh"
                          onClick={() => {
                            this.sync();
                            this.dataGrid.current.instance.refresh();
                          }}
                        />
                      </Item>
                      <Item name="columnChooserButton" location="after" />
                      <Item name="searchPanel" location="before" />
                    </Toolbar>
                    <Column
                      dataField="IMAGE"
                      caption="IMAGE"
                      cellRender={this.DiffCell}
                      width="70"
                      allowFiltering={false}
                      allowGrouping={false}
                      allowSorting={false}
                    ></Column>
                    <Column
                      dataField="name"
                      caption="NAME"
                      allowHiding={false}
                    ></Column>
                    <Column
                      dataField="manufacturer"
                      caption="MANUFACTURER"
                      width="120"
                      visible={true}
                    />
                    <Column
                      dataField="UNSPSCCode"
                      caption="UNSPSC CODE"
                      visible={true}
                      width="130"
                    />
                    <Column
                      dataField="modelNumber"
                      caption="MODEL/PART NUMBER"
                      width="130"
                      visible={true}
                    />
                    <Column dataField="brand" caption="BRAND" visible={true} />
                    <Column
                      dataField="netContent"
                      caption="NET CONTENT"
                      width="130"
                      alignment="left"
                      visible={true}
                    />
                    <Column
                      dataField="inventoryQuantity"
                      caption="INVENTORY QUANTITY"
                      alignment="center"
                      visible={true}
                    />
                    <Column
                      dataField="expiryDate"
                      caption="NEXT EXPIRY"
                      dataType="date"
                      cellRender={renderGridCell}
                      visible={true}
                      customizeText={(cellInfo) => {
                        return getTime(cellInfo.value) == "Invalid date"
                          ? ""
                          : getTime(cellInfo.value);
                      }}
                    />
                      <Column
                        width={125}
                        type="buttons"
                        allowFiltering={false}
                        allowGrouping={false}
                        allowSorting={false}
                      >
                        <GridButton render={renderReorderButton} />
                      </Column>
                    <Export
                      enabled={true}
                      allowExportSelectedData={true}
                      formats={exportFormats}
                      excelWrapTextEnabled={true}
                    />
                    <HeaderFilter visible={true} allowSearch={true} />
                    <Selection mode="multiple" />
                    <Scrolling rowRenderingMode="virtual"></Scrolling>
                    <Paging
                      defaultPageSize={this.props.pageSize}
                      onPageSizeChange={this.onPageSizeChange}
                      // onPageIndexChange={this.onPageIndexChange}
                      // pageIndex={this.state.pageSize}
                    />
                    <Pager
                      visible={true}
                      allowedPageSizes={allowedPageSizes}
                      displayMode={"adaptive"}
                      showPageSizeSelector={true}
                      showInfo={true}
                      showNavigationButtons={true}
                    />
                    <Summary>
                      <GroupItem
                        column="name"
                        summaryType="count"
                        displayFormat="{0}"
                      />
                      {/* <GroupItem
                          column="inventoryQuantity"
                          summaryType="sum"
                          showInGroupFooter={true}
                          displayFormat={"Sub-total: {0}"}
                        />
                        <GroupItem
                          name="expiryDateGroupSummary"
                          showInColumn="expiryDate"
                          summaryType="custom"
                          showInGroupFooter={true}
                        /> */}
                      <TotalItem
                        column="inventoryQuantity"
                        summaryType="sum"
                        displayFormat={"TOTAL: {0}"}
                      />
                    </Summary>
                  </DataGrid>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </>
    );
  }
}

const renderReorderButton = (button) => {
  return <Button variant="primary" size="sm" disabled style={{ fontFamily: "Inter, \"Source Sans Pro\", Helvetica, Arial, sans-serif", fontSize: "11px", verticalAlign: "middle" }}>REORDER</Button>;
};

const mapStateToProps = (state) => {
  const {
    disposables,
    categories,
    activeCategories,
    disposableColumns,
    pageIndex,
    pageSize,
    total,
    currentPage,
    rows,
  } = state.disposablesReducer;
  return {
    disposables: disposables,
    categories: categories,
    disposableColumns,
    activeCategories,
    pageIndex,
    pageSize,
    total,
    currentPage,
    rows,
  };
};

/**
 * To perform add,get,delete and update action on disposable
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getDisposableList: (obj) =>
      dispatch(ProductServices.getAllProductList(obj)),
    getActiveCategory: (obj) =>
      dispatch(ProductServices.getActiveCategory(obj)),
    getFilteredDisposableList: (type) =>
      dispatch(disposablesService.getFilteredDisposableList(type)),
    deleteBulkDisposable: (id) =>
      dispatch(ProductServices.deleteBulkProducts(id)), //(disposablesService.deleteBulkDisposable(id)
    getProductFilter: (ids, isActive) =>
      dispatch(ProductServices.updateProductStatus(ids, isActive)), //dispatch(disposablesService.getProductFilter(ids, isActive)),
    getProductCategory: (obj, productType) =>
      dispatch(ProductServices.addProductCategory(obj, productType)),
    filterProductByCategory: (category, obj) =>
      dispatch(disposablesService.filterProductByCategory(category, obj)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    updateDisposableColumns: (columns) =>
      dispatch(disposablesService.updateDisposableColumns(columns)),
    changePage: (pageNo) =>
      dispatch(disposablesService.changeDisposablePage(pageNo)),
    changePageSize: (pageSize) =>
      dispatch(disposablesService.changeDisposablePageSize(pageSize)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Disposable);
