import React, { useState } from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
import AdminNavbar from 'components/Navbars/AdminNavbar';
import Footer from 'components/Footer/Footer';
import Sidebar from 'components/Sidebar/Sidebar';
import { sidebarRoutes, dashboardRoutes } from '../routes';
import { Spinner } from '../commonComponents/Spinner';
import { useAuth } from 'views/authProvider/authProvider';
import { logoutUser } from '../service/LoginServices';
import { useDispatch } from 'react-redux';
function Admin() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [toggleDrawer, setToggleDrawer] = useState(false);
  const mainPanel = React.useRef(null);
  const getRoutes = (routes) => {
    return dashboardRoutes.map((prop, key) => {
      return <Route path={prop.path} render={(props) => <prop.component {...props} />} exact key={key} />;
    });
  };

  // React.useEffect(() => {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   mainPanel.current.scrollTop = 0;
  //   if (
  //     window.innerWidth < 993 &&
  //     document.documentElement.className.indexOf("nav-open") !== -1
  //   ) {
  //     document.documentElement.classList.toggle("nav-open");
  //     var element = document.getElementById("bodyClick");
  //     element.parentNode.removeChild(element);
  //   }
  // }, [location]);

  const onLogout = () => {
    dispatch(logoutUser());
    window.localStorage.removeItem('custodyToken');
  };

  return (
    <>
      <div className='wrapper'>
        <Sidebar routes={sidebarRoutes} toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} />
        <div className='main-panel' ref={mainPanel}>
          <AdminNavbar onLogout={onLogout} toggleDrawer={toggleDrawer} setToggleDrawer={setToggleDrawer} />
          <div className='content'>
            <Switch>{getRoutes(dashboardRoutes)}</Switch>
            <Spinner />
          </div>
          {/* <Footer /> */}
        </div>
      </div>
    </>
  );
}

export default Admin;
