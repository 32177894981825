import React, { useRef, useState } from "react";
import {
  TreeList,
  Column,
  RemoteOperations,
  Sorting,
  Scrolling,
  Paging,
  Pager,
  SearchPanel,
  HeaderFilter,
  StateStoring,
  Selection,
  Toolbar,
  ColumnChooser,
  Item,
  FilterPanel,
} from "devextreme-react/tree-list";
import { Button as DxButton } from "devextreme-react/button";

const allowedPageSizes = [5, 10, 20];
const storageKey = "receiving-packings-grid-v2";

const ReceivingDetailGrid = ({ dataSource }) => {
  const pakingRef = useRef();
  // Custom save callback
  const customSaveCallback = (state) => {
    // Exclude expandedRowKeys from the state
    const { expandedRowKeys, ...stateWithoutExpandedKeys } = state;

    // Save the modified state to localStorage
    localStorage.setItem(storageKey, JSON.stringify(stateWithoutExpandedKeys));
  };

  // Custom load callback
  const customLoadCallback = () => {
    // Retrieve the state from localStorage
    const storedState = localStorage.getItem(storageKey);

    // Parse the stored state and include expandedRowKeys with an empty array
    const parsedState = storedState ? JSON.parse(storedState) : {};
    const stateWithEmptyExpandedKeys = {
      ...parsedState,
      expandedRowKeys: [],
    };

    return stateWithEmptyExpandedKeys;
  };

  // Custom remove callback
  const customRemoveCallback = () => {
    // Remove the state from localStorage
    localStorage.removeItem(storageKey);
  };

  return (
    <TreeList
      id="treelist"
      dataSource={dataSource}
      showBorders={false}
      showRowLines={false}
      showColumnLines={true}
      allowColumnResizing={true}
      columnResizingMode={"widget"}
      columnMinWidth={50}
      columnAutoWidth={true}
      columnHidingEnabled={true}
      allowColumnReordering={true}
      keyExpr="GUID"
      parentIdExpr="parentGuid"
      hasItemsExpr="hasChildren"
      rootValue=""
      ref={pakingRef}
      // autoExpandAll="true"
      width={'100%'}
      hoverStateEnabled={true}
      // rowAlternationEnabled={true}
    >
      <RemoteOperations filtering={false} sorting={false} grouping={false} />
      <HeaderFilter visible={true} allowSearch={true} />
      <SearchPanel visible={true} width={300} />
      <FilterPanel visible={true} />

      <Scrolling mode="standard" />
      <Paging enabled={true} defaultPageSize={10} />
      <Pager
        showPageSizeSelector={true}
        allowedPageSizes={allowedPageSizes}
        showInfo={true}
      />
      <Sorting mode="multiple" />
      <StateStoring
        enabled={true}
        type="custom"
        customSave={customSaveCallback}
        customLoad={customLoadCallback}
        customRemove={customRemoveCallback}
        storageKey={storageKey}
        savingTimeout={100}
      />
      <ColumnChooser enabled={true} mode={"select"} />
      <Toolbar>
        <Item location="before" locateInMenu={"always"}>
          <DxButton
            text="Reset Grid"
            hint="Reset Grid"
            icon="revert"
            onClick={() => {
              pakingRef.current.instance.state(null);
            }}
          />
        </Item>
        
        <Item name="searchPanel" location="before" />

        <Item location="after">
          <DxButton
            hint="Refresh"
            icon="refresh"
            onClick={() => {
              // REF: https://supportcenter.devexpress.com/ticket/details/t1091118/treelist-how-to-expand-collapse-all-nodes#cbc5ee98-61e5-4293-aa7f-641b3bc0f8ed
              //  (via https://www.google.com/search?q=devextreme+tree+list+collapse+all)
              let listInstance = pakingRef.current.instance;
              listInstance.beginUpdate();

              let expandedRowKeys = [];
              listInstance.forEachNode(function (node) {
                if (listInstance.isRowExpanded(node.key)) {
                  expandedRowKeys.push(node.key);
                }
              });
              // listInstance.forEachNode(function (node) {
              //   listInstance.collapseRow(node.key);
              // });
              expandedRowKeys.forEach((key) => {
                listInstance.collapseRow(key);
              });

              listInstance.refresh();

              listInstance.option("expandedRowKeys", expandedRowKeys);

              listInstance.endUpdate();
              listInstance.repaint();
            }}
          />
        </Item>
        <Item name="columnChooserButton" location="after" />
      </Toolbar>
      <Column caption={"Type"} dataField="objectType" allowHiding={false} allowReordering={false} />
      <Column dataField="objectCode" caption={"ID/Barcode"} />
      <Column caption={"NDC"} dataField="shipmentProduct.ndc" />
      <Column caption={"GTIN"} dataField="shipmentProduct.productCode" />
      <Column caption={"Lot#"} dataField="lotNumber" />
      <Column caption={"Expiry"} dataField="expiryDate" dataType={"date"} />
      <Column caption={"Serial#"} dataField="serialNumber" />
      <Column caption={"Dosage"} dataField="shipmentProduct.dosage" />
      <Column caption={"Strength"} dataField="shipmentProduct.strength" />
      <Column caption={"Net Content"} dataField="shipmentProduct.netContent" />
      <Column
        caption={"Manufacturer"}
        dataField="shipmentProduct.manufacturer"
      />
      <Column
        caption={"Unit Count"}
        dataField="unitCount"
        dataType={"number"}
        alignment={"center"}
      />
      <Column
        caption={"Received"}
        dataField="qtyReceived"
        dataType={"number"}
        alignment={"center"}
      />
      <Column
        caption={"Completed (?)"}
        dataField="isComplete"
        dataType={"boolean"}
        visible={false}
        alignment={"center"}
      />
      <Column caption={"Status"} dataField="statusCode" visible={false} />
    </TreeList>
  );
};

export default ReceivingDetailGrid;
