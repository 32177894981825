import { DialogActions, DialogContent, Stack } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import SendIcon from "@mui/icons-material/SendRounded";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CloseIcon from "@mui/icons-material/Clear";
import ReactJson from "react-json-view";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import useResponsive from "./useResponsive";
import { BaseURLMock } from "Constant/BaseURL";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReceivingDialog({
  receiving_id,
  setActionToggle,
  actionToggle,
}) {
  const [open, setOpen] = React.useState(false);
  const [click, setClick] = React.useState(false);
  const [input, setInput] = React.useState("");
  const [stringArray, setStringArray] = React.useState([]);
  const [stringCount, setStringCount] = React.useState(0);
  const [jsonData, setJsonData] = React.useState({});
  const isMobile = useResponsive("down", "sm");

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    setInput(inputValue);

    const strings = inputValue.split("\n").filter((str) => str.trim() !== "");
    setStringCount(strings.length);
  };

  const handleBlur = () => {
    const strings = input.split("\n").filter((str) => str.trim() !== "");
    setStringArray(strings);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    if (stringCount > 0) {
      if (Object.keys(jsonData).length === 0) {
        let userChoice = confirm("Discard Scanned Label(s)?");
        if (!userChoice) {
          return;
        }
      }
    }
    if (Object.keys(jsonData).length > 0) {
      setActionToggle(!actionToggle);
      setJsonData({});
    }
    setClick(false);
    setOpen(false);
    setInput("");
    setStringCount(0);
  };

  const onSubmit = () => {
    axios(
      `${BaseURLMock}/ShipmentManifests/${receiving_id}/receive-scanned-objects`,
      {
        method: "POST",
        params: {
          companyGuid: localStorage.companyGuid,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
        },
        data: { objects: stringArray },
      }
    )
      .then((res) => {
        setJsonData(res.data);
        setClick(true);
      })
      .catch(function (error) {
        toast.error("Something went wrong, please try again!");
      });
  };

  return (
    <div>
      <Button
        className="d-sm-none"
        startIcon={<i class="fas fa-barcode-read"></i>}
        style={{ marginRight: "20px", marginTop: "12px" }}
        variant="contained"
        size="small"
        onClick={handleClickOpen}
      >
        Scan Label
      </Button>
      <Dialog
        maxWidth={"sm"}
        fullWidth={Boolean(true)}
        fullScreen={isMobile ? Boolean(true) : Boolean(false)}
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ maxHeight: "100vH" }}
      >
        <AppBar
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "lightgrey",
            color: "black",
          }}
        >
          <Toolbar>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {click ? "Scan Result" : "Scan Labels"}
            </Typography>
            <Button
              variant="contained"
              size={"small"}
              onClick={handleClose}
              startIcon={<CloseIcon sx={{ mt: 0 }} />}
              sx={{
                backgroundColor: "white",
                color: "black",
                "&:hover": {
                  backgroundColor: "white",
                  color: "black",
                },
              }}
            >
              <Typography>Close</Typography>
            </Button>
          </Toolbar>
        </AppBar>
        <ToastContainer autoClose={1000} />

        {!click ? (
          <>
            <DialogContent>
              <textarea
                autoFocus={true}
                value={input}
                onChange={handleInputChange}
                onBlur={handleBlur}
                class="form-control"
                placeholder="Scan the barcode/2D labels"
                inputMode="none"
                style={{
                  resize: "vertical",
                  height: "65vH",
                  minHeight: "80px",
                }}
              ></textarea>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={Boolean(stringCount === 0)}
                style={{ minWidth: 200 }}
                onClick={() => onSubmit()}
                endIcon={<SendIcon />}
              >
                <Typography>Submit: {stringCount}</Typography>
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <ReactJson
                style={{ backgroundColor: "#F9F9F9" }}
                src={jsonData}
                displayDataTypes={false}
                displayObjectSize={false}
                enableClipboard={false}
              />
            </DialogContent>
            <DialogActions
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <Button
                variant="contained"
                color="success"
                startIcon={<ArrowBackIosIcon sx={{ mt: 0 }} />}
                onClick={() => {
                  setInput("");
                  setStringCount(0);
                  setClick(false);
                }}
              >
                Scan More
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  setActionToggle(!actionToggle);
                  handleClose();
                }}
              >
                Finished
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </div>
  );
}
