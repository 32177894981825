/**
 * Initial state for the Assets which is empty.
 */
const loginDetails = JSON.parse(
  window.localStorage.getItem("@login_details") ||
    '{"userData":{},"msg":"","token":null}'
);
const InitialState = {
  userData: loginDetails.userData,
  msg: loginDetails.msg,
  token: loginDetails.token,
};

/**
 * To store Assets information and alter the data
 * @param {*} state
 * @param {*} action
 * @returns
 */
export default function loginReducer(state = InitialState, action) {
  switch (action.type) {
    case "@LOGIN_USER":
      // debugger;
      const { userData, msg, token, companyGuid } = action.payload;
      window.localStorage.setItem(
        "@login_details",
        JSON.stringify({ userData, msg, token })
      );
      window.localStorage.setItem("custodyToken", token);
      window.localStorage.setItem("companyGuid", companyGuid);
      return {
        ...state,
        userData,
        msg,
        token,
      };

    case "@LOGUT_USER":
      if (action.payload.success) {
        window.localStorage.setItem(
          "@login_details",
          '{"userData":{},"msg":"","token":null}'
        );
        return {
          ...state,
          userData: {},
          msg: "",
          token: null,
        };
      }
      return state;

    default:
      return state;
  }
}
