import React, { Component } from 'react';
import { connect } from 'react-redux';
import AssetsDetails from 'components/Assets/AssetsDetail';


const mapStateToProps = (state) => ({
    
})

const mapDispatchToProps = (dispatch) => {
    return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(AssetsDetails)
