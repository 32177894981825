import React, { Component, useEffect } from "react";


function NavIcons() {


    return (
        <div>
            <i className="fa-regular fa-bell" />
        </div>
    );
}



  export default NavIcons;
