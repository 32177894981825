import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { Formik } from "formik";
import { connect } from "react-redux";
import {
  Button,
  Form,
  FormControl,
  Row,
  Col,
  InputGroup,
} from "react-bootstrap";
import "./login.css";
import { loginUser } from "../../service/LoginServices";
import { get } from "lodash";
import { useRouter } from "../../hooks/routeHooks";
import Loader from "react-loader-spinner";

const schema = yup.object().shape({
  username: yup.string().required(),
  password: yup.string().required(),
});
let url = window.location.href.toString().toLowerCase();

function Login({ loginUser, token, loginMessage, userData }) {
  const router = useRouter();

  const handleSubmit = (data) => {
    loginUser({ email: data.username, password: data.password });
  };

  useEffect(() => {
    const previousPath = get(router, "location.state.from.pathname");
    const previousHash = get(router, "location.state.from.hash");
    if (previousPath && previousPath !== "/") {
      token && router.push(previousPath + previousHash);
    } else {
      token && router.push("/drugs");
    }
  }, [token, router]);

  return !token ? (
    <Formik
      validationSchema={schema}
      onSubmit={handleSubmit}
      initialValues={{
        password: "",
        username: "",
      }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <div className="login-page">
          <Form noValidate onSubmit={handleSubmit} className="container">
            <div className="login-header">
              <img
                src={require("assets/img/logo.svg").default}
                className="login-img"
              />
              <span className="login-title">CustodyRX</span>
            </div>

            <Form.Group controlId="validationFormik03" className="username">
              {/* <Form.Label className="label">Username</Form.Label> */}
              <Form.Control
                type="text"
                placeholder="Enter Username"
                name="username"
                value={values.username}
                onChange={handleChange}
                isInvalid={!!errors.username}
              />
            </Form.Group>
            <div className="errorTag">
              <span className="errorText">{errors.username}</span>
            </div>
            <Form.Group controlId="validationFormik04" className="username">
              {/* <Form.Label className="label">Password</Form.Label> */}
              <Form.Control
                type="password"
                placeholder="Enter Password"
                name="password"
                value={values.password}
                onChange={handleChange}
                isInvalid={!!errors.password}
              />
            </Form.Group>

            <div className="errorTag">
              <span className="errorText">{errors.password}</span>
            </div>

            <Button type="submit" className="login-button col-md-12">
              Login
            </Button>
            <div className="errorTag">
              <span className="errorText">{loginMessage}</span>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  ) : url.search("assets") == -1 &&
    url.search("asset") == -1 &&
    url.search("drugs") == -1 &&
    url.search("drug") == -1 &&
    url.search("disposables") == -1 &&
    url.search("disposable") == -1 &&
    url.search("packing") == -1 ? (
    <div className="spinner">
      <Loader type="ThreeDots" color="#2D2D2D" height="100" width="100" />
    </div>
  ) : (
    ""
  );
}
const mapStateToProps = (state) => {
  return {
    userData: state.loginReducer.userData,
    token: state.loginReducer.token,
    loginMessage: state.loginReducer.msg,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loginUser: (userObj) => dispatch(loginUser(userObj)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
