import React, { Component } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table-6";
import { AllList } from "../../commonComponents/DropDown";
import Pagination from "../../commonComponents/Pagination";

export class ProductList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
    };
  }

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  getEquipmentDetails = (Id) => {
    this.props.getEquipmentDetails(Id);
  };

  render() {
    let {
      name,
      history,
      tableData,
      addButtonURl,
      selectedItem,
      pageIndex,
      pageSize,
      total,
      onPageSizeChange,
      onPageChange,
      onSortedChange,
      handleSelect,
      handleTrProps,
      handleTdProps,
      tableCol,
    } = this.props;

    const { dropdownOpen, handleSelectDropDown } = this.state;
    let columns = [
      {
        width: "auto",
        Header: `${name} Name`,
        Cell: ({ row }) => <div>{row._original.name}</div>,
        accessor: "name",
      },
    ];
    return (
      <Col lg={`${tableCol} quick-navigation`}>
        
        <Container fluid className="table">
          <Row>
            <Col md="">
              <ReactTable
                manual
                data={tableData}
                columns={columns}
              />
              
            </Col>
          </Row>
        </Container>
      </Col>
    );
  }
}

export default withRouter(ProductList);
