import React, { Component } from "react";
import {Button} from "react-bootstrap";

import { BiPencil } from 'react-icons/bi';

class EditButton extends Component {
  render() {
    const { path, history, handleEdit } = this.props;
    return (
      <div className="cross">
        {/* <i
          className="fa fa-edit"
          onClick={() =>
            path ? history.push(path) : handleEdit()
          }
        ></i> */}
        <Button variant="secondary" className="btn-edit" onClick={() =>
            path ? history.push(path) : handleEdit()
          }><BiPencil></BiPencil></Button>
      </div>
    );
  }
}

export default EditButton;
