import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import Loader from "react-loader-spinner";

export const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  let url = window.location.href.toString().toLowerCase();

  return url.search("assets") == -1 &&
    url.search("asset") == -1 &&
    url.search("drugs") == -1 &&
    url.search("drug") == -1 &&
    url.search("disposables") == -1 &&
    url.search("disposable") == -1 &&
    url.search("packing") == -1
    ? promiseInProgress && (
        <div className="spinner">
          <Loader type="ThreeDots" color="#2D2D2D" height="100" width="100" />
        </div>
      )
    : "";
};
