import React from "react";

function Logo({title}) {
    return(
        <>
            <h3>{title}</h3>
        </>
    )
}

export default Logo;