import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Badge, Button, Dropdown, DropdownButton, Tab, Tabs } from 'react-bootstrap';
import { Row, Col } from 'react-bootstrap';
import CloseButton from 'commonComponents/closeButton';
import { connect } from 'react-redux';
import drugsService from '../../service/DrugsService';
import ProductServices from '../../service/CommonServices';
import PackingsService from '../../service/PackingsService';
import { Button as DxButton } from 'devextreme-react/button';
import { LoadPanel, TextArea, TextBox } from 'devextreme-react';
import { BaseURLDev } from '../../Constant/BaseURL';

import DataGrid, { Scrolling, Pager, Paging, Column, HeaderFilter, StateStoring, ColumnChooser, Toolbar, Item } from 'devextreme-react/data-grid';
import { Paper } from '@mui/material';
import { createStore } from 'devextreme-aspnet-data-nojquery';

import PackingProducts from './PackingProducts';
import Title from 'commonComponents/Title';
import { getTime } from 'commonMethod/common';
import { toLower } from 'lodash';
import { toast } from 'react-toastify';

const position = { of: '#packingminiGridContainer' };
const allowedPageSizes = [5, 10, 20, 25, 50, 'all'];
const renderGridCell = (data) => {
  var color =
    toLower(data.text) == toLower('shipped') ? 'badge-primary' : toLower(data.text) == toLower('delivered') ? 'badge-success' : 'badge-secondary';
  return data.text == '' ? '' : <Badge className={color}>{data.text}</Badge>;
};
const storageKey = 'packing-main-grid-v2';
// const dataSource = createStore({
//   key: "GUID",
//   loadUrl: `${BaseURL}/Packings?companyGuid=${localStorage.companyGuid}`,
//   onBeforeSend: function (method, ajaxOptions) {
//     ajaxOptions.headers = {
//       Authorization: `Bearer ${localStorage.custodyToken}`,
//     };
//   },
//   onAjaxError: function (error) {
//     localStorage.removeItem("@login_details");
//     localStorage.removeItem("custodyToken");
//     window.location.reload();
//   },
// });

class PackingDetail extends React.Component {
  constructor(props) {
    super(props);

    this.dataGrid = React.createRef();
    this.dataSource = createStore({
      key: 'GUID',
      loadUrl: `${BaseURLDev}/Packings?companyGuid=${localStorage.companyGuid}`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.custodyToken}`,
        };
      },
      onAjaxError: function (error) {
        localStorage.removeItem('@login_details');
        localStorage.removeItem('custodyToken');
        window.location.reload();
      },
    });
    this.state = {
      dropdownOpen: false,
      selectedItem: '',
      categoryStatus: 'Active',
      packing: [],
      loadPanelVisible: false,
      key: 'overview',
      packingState: [],
    };
  }

  loadState = () => {
    this.setState({ packingState: JSON.parse(localStorage.getItem(storageKey)) });
    return JSON.parse(localStorage.getItem(storageKey));
  };

  saveState = (state) => {
    if (this.state.packingState) {
      for (let i = 0; i <= 17; i++) {
        state.columns[i] = this.state.packingState.columns[i];
      }
    }

    localStorage.setItem(storageKey, JSON.stringify(state));
  };

  getDrugs = (params = {}) => {
    const { pageSize, pageIndex } = this.props;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: 'name',
      sortOrder: 'ASC',
      productType: 'drug',
      ...params,
    };
    this.props.getDrugList(obj);
  };

  componentDidMount() {
    this.sync();
    this.props.getAllCategory();
  }

  sync = async (params = {}) => {
    const { pageSize, pageIndex, match } = this.props;
    let packing;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: 'name',
      sortOrder: 'ASC',
      productType: 'drug',
      packingGuid: match.params.packing_id,
      ...params,
    };
    await this.props.getPackingDetail(obj).then(function (res) {
      packing = res.data.data[0];
    });
    if (packing) {
      this.setState({
        packing: packing,
      });
    } else {
      this.props.history.replace('/packing')
    }
  };

  toggleFilter = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };
  onRowClick(e) {
    this.props.history.push({
      pathname: `/packing/${e.key}`,
      state: {
        assetName: e.data.name,
      },
    });
    this.sync();
    e.component.selectRows(e.key);
  }
  handleSelect = async (val) => {
    await this.setState({
      selectedItem: val,
    });
    await this.props.changePage(0);
    val === 'All Drugs'
      ? this.getDrugs()
      : // this.sync({
      //   isActive : parseInt(val)
      // });
      val === '0' || val === '1'
      ? this.sync({
          isActive: parseInt(val),
        })
      : this.sync({
          isActive: '1',
          category: val,
        });
  };
  onHandleMoreOperation = async (key) => {
    if (key === 'delete') {
      this.props
        .deleteBulkPackings(this.props.match.params.packing_id)
        .then((res) => {
          toast.success(`${res.data.itemDeletedCount} Records Deleted`);
          this.sync();
          this.dataGrid.current.instance.refresh();
          this.props.history.push(`/packing`);
        })
        .catch(function (error) {
          toast.error(`An error occured while deleting the Records`);
        });
    }
  };
  render() {
    const { match, location } = this.props;
    let tote_id;
    let ship_status;
    ship_status = match.params.ship_status;
    tote_id = this.state.packing.parentEPC;
    return (
      <div>
        <LoadPanel
          shadingColor='rgba(0,0,0,0.4)'
          position={position}
          visible={this.state.loadPanelVisible}
          showIndicator={true}
          shading={false}
          showPane={true}
        />
        <Row className='drug-detail-table-actions'>
          <Col lg='3' className='quick-navigation'>
            <Paper>
              <DataGrid
                id='packingminiGridContainer'
                dataSource={this.dataSource}
                keyExpr='GUID'
                showBorders={false}
                hoverStateEnabled={true}
                loadPanel={false}
                ref={this.dataGrid}
                onRowClick={(e) => {
                  this.onRowClick(e);
                }}
                onRowDblClick={(e) => {
                  this.onRowClick(e);
                }}
                onContentReady={(e) => {
                  if (!e.component.getSelectedRowKeys().length) {
                    e.component.selectRows(match.params.packing_id);
                  }
                }}
              >
                <ColumnChooser enabled={true} mode={"select"} width={0} />
                <StateStoring
                  enabled={true}
                  type='custom'
                  customLoad={this.loadState}
                  customSave={this.saveState}
                  storageKey={storageKey}
                  savingTimeout={100}
                />
                <Column dataField='parentEPC' allowHiding={false} caption={`${process.env.REACT_APP_NOUN_CARTON} ID`} />
                <Column dataField='shipStatus' cellRender={renderGridCell} />
                <Column dataField='typeCodeName' cssClass='hide-drug-detail-column' />
                <Column dataField='Timestamp' cssClass='hide-drug-detail-column' />
                <Column dataField='packBizLocationName' cssClass='hide-drug-detail-column' />
                <Column dataField='packReadPointName' cssClass='hide-drug-detail-column' />
                <Column dataField='expectedQty' cssClass='hide-drug-detail-column' />
                <Column dataField='packedQty' cssClass='hide-drug-detail-column' />
                <Column dataField='customerName' cssClass='hide-drug-detail-column' />
                <Column dataField='salesOrderNumber' cssClass='hide-drug-detail-column' />
                <Column dataField='shipmentNumber' cssClass='hide-drug-detail-column' />
                <Column dataField='containerTagID' cssClass='hide-drug-detail-column' />
                <Column dataField='createdBy' cssClass='hide-drug-detail-column' />
                <Column dataField='protocolNumber' cssClass='hide-drug-detail-column' />
                <Column dataField='lotNumber' cssClass='hide-drug-detail-column' />
                <Column dataField='serialNumber' cssClass='hide-drug-detail-column' />
                <Column dataField='expiryDate' cssClass='hide-drug-detail-column' />

                <HeaderFilter visible={false} allowSearch={false} />
                <Scrolling rowRenderingMode='virtual'></Scrolling>
                <Paging defaultPageSize={this.props.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={'adaptive'}
                  showPageSizeSelector={true}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Toolbar>
                  <Item location='after'>
                    <DxButton
                      hint='Refresh'
                      icon='refresh'
                      onClick={() => {
                        this.setState({ loadPanelVisible: true });
                        this.sync();
                        this.dataGrid.current.instance.refresh();
                        setTimeout(() => {
                          this.setState({ loadPanelVisible: false });
                        }, 1000);
                      }}
                    />
                  </Item>
                </Toolbar>
              </DataGrid>
            </Paper>
          </Col>

          <Col lg='9' className='detail-overview'>
            <div>
              <div className='drug-detail-action'>
                <div className='col'>
                  <strong className='text-muted h5'>{process.env.REACT_APP_NOUN_CARTON} ID</strong>
                  <br />
                  <strong className='h5'>
                    <Badge className="badge-info" style={{ backgroundColor: "#6361E0", fontSize: "20px", lineHeight: "24px", borderRadius: "5px" }}>
                      {tote_id}&nbsp;
                    </Badge>
                    <Badge
                      className={
                        toLower(ship_status) == toLower('shipped')
                          ? 'badge-primary'
                          : toLower(ship_status) == toLower('delivered')
                          ? 'badge-success'
                          : 'badge-secondary'
                      }
                    >
                      {ship_status}
                    </Badge>
                  </strong>
                </div>
                <DropdownButton className={'drag_more_menu'} variant='info' title='More' onClick={(e) => this.onHandleMoreOperation(e.target.value)}>
                  <Dropdown.Item as='button' value='delete'>
                    Delete
                  </Dropdown.Item>
                </DropdownButton>
                <CloseButton history={this.props.history} path='/packing' />
              </div>
              <div className='dx-fieldset border-left-success'>
                <div className='dx-fieldset-header'>{process.env.REACT_APP_NOUN_CARTON} Info</div>
                <div className='dx-fieldset-column'>
                  <div className='dx-field'>
                    <div className='dx-field-label'>{process.env.REACT_APP_NOUN_CARTON} ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{tote_id}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Type:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.typeCodeName == null ? '-' : this.state.packing.typeCodeName}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Timestamp:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>
                        {!this.state.packing.timeStamp
                          ? '-'
                          : getTime(this.state.packing.timeStamp) == 'Invalid date'
                          ? ''
                          : getTime(this.state.packing.timeStamp)}
                      </div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Pallet ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>N/A</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Pack Station:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.packReadPointName == null ? '-' : this.state.packing.packReadPointName}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Expected Qty:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.expectedQty}</div>
                    </div>
                  </div>
                </div>
                <div className='dx-fieldset-column'>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Packed Qty:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.packedQty}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Customer:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.customerName}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Sales Order:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.salesOrderNumber}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Status:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.shipStatus}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Shipment:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'> {this.state.packing.shipmentNumber}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Pallet ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.containerTagID}</div>
                    </div>
                  </div>
                </div>
                <div className='dx-fieldset-column'>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Create By:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.createdBy}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Protocol ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.protocolNumber == null ? '-' : this.state.packing.protocolNumber}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Batch/Lot ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.lotNumber == null ? '-' : this.state.packing.lotNumber}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Serial ID:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.serialNumber == null ? '-' : this.state.packing.serialNumber}</div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Expiry Date:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>
                        {!this.state.packing.expiryDate
                          ? '-'
                          : getTime(this.state.packing.expiryDate) == 'Invalid date'
                          ? ''
                          : getTime(this.state.packing.expiryDate)}
                      </div>
                    </div>
                  </div>
                  <div className='dx-field'>
                    <div className='dx-field-label'>Completed:</div>
                    <div className='dx-field-value'>
                      <div id='operatortickets'>{this.state.packing.isCompleted == null ? '-' : this.state.packing.isCompleted}</div>
                    </div>
                  </div>
                </div>
                <div className='clear'></div>
              </div>
              <Tabs defaultActiveKey='drug-list' id='drug-detail-tabs' activeKey='drug-list' className='mb-3 mt-2'>
                <Tab eventKey='drug-list' title={`${process.env.REACT_APP_NOUN_CARTON} Contents`} className='drug-item-tag'>
                  <div className='drug-item-list-container'>
                    <PackingProducts packing_id={match.params.packing_id} />
                  </div>
                </Tab>
              </Tabs>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { drugs, activeCategories, rows, pageIndex, pageSize } = state.packingReducer;
  return {
    activeCategories,
    drugs: drugs,
    rows,
    pageIndex,
    pageSize,
  };
};

/**
 * To perform add,get,delete and update action on drugs
 * @param {*} dispatch
 * @returns
 */
const mapDispatchToProps = (dispatch) => {
  return {
    getPackingDetail: (obj) => dispatch(PackingsService.getPackingDetail(obj)),
    deleteBulkPackings: (id) => dispatch(PackingsService.deleteBulkPackings(id)),
    getDrugList: (obj) => dispatch(ProductServices.getAllProductList(obj)),
    getActiveCategory: (obj) => dispatch(ProductServices.getActiveCategory(obj)),
    getFilteredDrugList: (type) => dispatch(drugsService.getFilteredDrugList(type)),
    getAllCategory: () => dispatch(ProductServices.getAllCategory()),
    changePage: (pageNo) => dispatch(drugsService.changePage(pageNo)),
    // allProductCategory: () => dispatch(drugsService.allProductCategory()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PackingDetail);
