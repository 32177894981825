import React, { useState } from "react";
import ReceivingDetailGridProduct from "./ReceivingDetailGridProduct";
import axios from "axios";
import { BaseURLMock } from "Constant/BaseURL";

const ReceivingProducts = ({ receiving_id, actionToggle }) => {
  const dataSource = {
    load(loadOptions) {
      // const parentIdsParam = loadOptions.parentIds;
      // let url = "";
      // if (parentIdsParam[0].length === 0) {
      //   url = new URL(
      //     `${BaseURLMock}/ShipmentManifests/${receiving_id}/Products`
      //   );
      // } else {
      //   url = new URL(
      //     `${BaseURLMock}/ShipmentManifests/${receiving_id}/Products/${parentIdsParam[0]}/Objects`
      //   );
      // }
      // if (parentIdsParam) {
      //   parentIdsParam.forEach((parentGuid) => {
      //     url.searchParams.append("shipmentProductGuid", parentGuid);
      //   });
      // }

      // return axios(url.toString(), {
      //   method: "GET",
      //   params: {
      //     companyGuid: localStorage.companyGuid,
      //   },
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
      //   },
      // })
      //   .then((response) => {
      //     return response.data;
      //   })
      //   .catch(() => {
      //     throw new Error("Data Loading Error");
      //   });
      
      let url = new URL(
        `${BaseURLMock}/ShipmentManifests/${receiving_id}/Products`
      );
      // Include shipmentObjects related to shipmentProduct
      url.searchParams.append("includeObjects", true);

      return axios.get(url.toString(), {
          params: {
            companyGuid: localStorage.companyGuid,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
          },
        })
        .then((response) => {
          // Flatten response's payload into a plain (non-hierarchical) structure:
          if (response.data.data) {
            let shipmentProducts = response.data.data; // let shipmentProducts = Array.isArray(response.data.data) ? response.data.data : [response.data.data];
            let newPayload = [];
            shipmentProducts.forEach((shipmentProduct) => {
              // Populate shipmentObject.shipmentProduct
              shipmentProduct.shipmentObjects.forEach((shipmentObject) => {
                shipmentObject.shipmentProduct = shipmentProduct;
                newPayload.push(shipmentObject);
              });
              // Clear shipmentProduct.shipmentObjects (not needed)
              shipmentProduct.shipmentObjects = null;
              // Set shipmentProduct.shipmentProductGuid to NULL since it is a root node in dxTreeList
              shipmentProduct.shipmentProductGuid = null;
              newPayload.push(shipmentProduct);
            });
            // Overwrite response's payload
            response.data.data = newPayload;
          }
          return response.data;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            localStorage.removeItem("@login_details");
            localStorage.removeItem("custodyToken");
            window.location.reload();
          }
          throw new Error("Data Loading Error");
        });
    },
  };

  return <ReceivingDetailGridProduct dataSource={dataSource} />;
};

function areEqual(prevProps, nextProps) {
  // Only re-render if the actionToggle prop changes
  return prevProps.actionToggle === nextProps.actionToggle;
}
export default React.memo(ReceivingProducts, areEqual);
