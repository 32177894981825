import React, { useEffect } from "react";
import { useLocation, NavLink } from "react-router-dom";
import { Nav } from "react-bootstrap";
import Logo from "commonComponents/Logo";
import Asset from "../../assets/img/assets.png";
import Report from "../../assets/img/reports.svg";
import Distro from "../../assets/img/distribution.png";
import Vehicle from "../../assets/img/vehicles.png";
import { Collapse, Drawer, Box } from "@mui/material";
//import { Icon } from '@iconify/react';

function Sidebar({ routes, toggleDrawer, setToggleDrawer }) {
  const [open, setOpen] = React.useState(false);

  const location = useLocation();
  const activeRoute = (routeName) => {
    return location.pathname.toUpperCase().includes(routeName.toUpperCase())
      ? "active"
      : "";
  };

  useEffect(() => {
    const locationChildren = ["/equipmentChecklists", "/vehicles", "/users"];
    const inventoryChildren = ["/drugs", "/assets", "/disposables"];
    if (inventoryChildren.includes(location.pathname)) {
      setOpen("Inventory");
    }
    if (locationChildren.includes(location.pathname)) {
      setOpen("Locations & Devices");
    }
  }, []);

  useEffect(() => {
    setToggleDrawer(false);
  }, [location.pathname]);

  const handleClick = (e, key) => {
    e.preventDefault();
    if (open === key) {
      setOpen("");
    } else setOpen(key);
  };

  const handleGap = (key) => {
    const array = [
      "Dashboard",
      "Inventory",
      "Locations & Devices",
      "Sample Management",
      "Kit Packing",
      "Shipments",
      "Receiving",
      "Tag Fleet Management",
    ];
    if (array.includes(key)) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="hide-sidebar sidebar" data-color={"black"}>
        <div className="sidebar-wrapper">
          <div
            className="logo d-flex align-items-center justify-content-start"
            style={{ height: "50px", backgroundColor: "#161A27" }}
          >
            <NavLink to="/drugs">
              <img
                src={require("assets/img/new-logo.png").default}
                alt="..."
                style={{ width: "120px", height: "26px" }}
              />
            </NavLink>
          </div>

          <Nav>
            {routes.map((prop, key) => {
              if (!prop.redirect && prop.layout === "")
                return (
                  <li
                    style={{
                      marginTop: "0.1rem",
                      marginBottom: handleGap(prop.name) ? "1rem" : "0.1rem",
                    }}
                    className={
                      prop.upgrade
                        ? "active active-pro"
                        : activeRoute(prop.module)
                    }
                    key={key}
                  >
                    <NavLink
                      to={!prop.disabled && prop.path}
                      style={{ margin: 0 }}
                      className="nav-link d-flex justify-content-between align-items-center"
                      activeClassName="active"
                      onClick={
                        prop.children && prop.disabled
                          ? (event) => handleClick(event, prop.name)
                          : null
                      }
                    >
                      <span>
                        <i className={prop.icon} />
                        <p>{prop.name}</p>
                      </span>
                      {prop.children && (
                        <>
                          {Boolean(open === prop.name) ? (
                            <i className="fas fa-caret-down"></i>
                          ) : (
                            <i className="fas fa-caret-right"></i>
                          )}
                        </>
                      )}
                    </NavLink>
                    <Collapse
                      in={Boolean(open === prop.name)}
                      timeout="auto"
                      unmountOnExit
                    >
                      {prop.children &&
                        prop.children.map((chidProp) => (
                          <li
                            style={{ marginLeft: "0.5rem" }}
                            className={
                              prop.upgrade
                                ? "active active-pro"
                                : activeRoute(chidProp.module)
                            }
                            key={key}
                          >
                            <NavLink
                              to={!chidProp.disabled && chidProp.path}
                              style={{ margin: 0 }}
                              className="nav-link"
                              activeClassName="active"
                              onClick={chidProp.disabled ? null : null}
                            >
                              <i className={chidProp.icon} />
                              <p>{chidProp.name}</p>
                            </NavLink>
                          </li>
                        ))}
                    </Collapse>
                  </li>
                );
              return null;
            })}
          </Nav>
        </div>
      </div>

      <Drawer
        anchor={"left"}
        open={toggleDrawer}
        onClose={() => setToggleDrawer(false)}
      >
        <Box
          width={220}
          className="sidebar-mobile"
          data-color={"black"}
          height={"inherit"}
        >
          <div className="sidebar-wrapper-mobile">
            <div
              className="logo d-flex align-items-center justify-content-center"
              style={{ height: "50px" }}
            >
              <NavLink to="/drugs">
                <img
                  src={require("assets/img/new-logo.png").default}
                  alt="..."
                  style={{ width: "120px", height: "26px" }}
                />
              </NavLink>
            </div>

            <Nav>
              {routes.map((prop, key) => {
                if (!prop.redirect && prop.layout === "")
                  return (
                    <li
                      style={{
                        marginTop: "0.1rem",
                        marginBottom: handleGap(prop.name) ? "1rem" : "0.1rem",
                      }}
                      className={
                        prop.upgrade
                          ? "active active-pro"
                          : activeRoute(prop.module)
                      }
                      key={key}
                    >
                      <NavLink
                        to={!prop.disabled && prop.path}
                        style={{ margin: 0 }}
                        className="nav-link-mobile d-flex justify-content-between align-items-center"
                        activeClassName="active"
                        onClick={
                          prop.children && prop.disabled
                            ? (e) => handleClick(e, prop.name)
                            : null
                        }
                      >
                        <span>
                          <i className={`${prop.icon} nav-link-mobile-icon`} />
                          <p
                            style={{
                              display: "inline-flex",
                              marginBottom: 0,
                            }}
                          >
                            {prop.name}
                          </p>
                        </span>
                        {prop.children && (
                          <>
                            {Boolean(open === prop.name) ? (
                              <i className="fas fa-caret-down"></i>
                            ) : (
                              <i className="fas fa-caret-right"></i>
                            )}
                          </>
                        )}
                      </NavLink>
                      <Collapse
                        in={Boolean(open === prop.name)}
                        timeout="auto"
                        unmountOnExit
                      >
                        {prop.children &&
                          prop.children.map((chidProp) => (
                            <li
                              style={{ marginLeft: "1rem" }}
                              className={
                                prop.upgrade
                                  ? "active active-pro"
                                  : activeRoute(chidProp.module)
                              }
                              key={key}
                            >
                              <NavLink
                                to={!chidProp.disabled && chidProp.path}
                                style={{ margin: 0, padding: "0px !important" }}
                                className="nav-link-mobile "
                                activeClassName="active"
                                onClick={chidProp.disabled ? null : null}
                              >
                                <i
                                  className={`${chidProp.icon} nav-link-mobile-icon`}
                                />
                                <p
                                  style={{
                                    display: "inline-flex",
                                    marginBottom: 0,
                                  }}
                                >
                                  {chidProp.name}
                                </p>
                              </NavLink>
                            </li>
                          ))}
                      </Collapse>
                    </li>
                  );
                return null;
              })}
            </Nav>
          </div>
        </Box>
      </Drawer>
    </>
  );
}

export default Sidebar;
