import React, { useContext, createContext, useState, useEffect } from "react";
import { connect } from "react-redux";


const authContext = createContext();

function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  

  const signin = ({ userData, token }) => {
    setUser(userData);
    setToken(token);
  };

  const signout = cb => {
    cb && cb()
  };

  return {
    user,
    token,
    signin,
    signout
  };
}

export function useAuth() {
    return useContext(authContext);
}

function ProvideAuth({ children, userData, token }) {
    const auth = useProvideAuth();

    useEffect(() => {
        auth.signin({
            userData,
            token
        })
    }, [token, userData])

    return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    );
  }
export default connect((state) => {
    return { 
        userData: state.loginReducer.userData,
        token: state.loginReducer.token
    }
})(ProvideAuth)