import React from "react";
import {
  DataGrid,
  Column,
  TotalItem,
  Summary,
  Scrolling,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { getTime } from "commonMethod/common";
import { BaseURLDev } from "../../Constant/BaseURL";

class PackingItems extends React.Component {
  constructor(props) {
    super(props);
    this.dataSource = createStore({
      key: "GUID",
      loadUrl: `${BaseURLDev}/Packings/${this.props.packing_id}/Products/${this.props.data.GUID}/Items?companyGuid=${this.props.companyGuid}`,
      onBeforeSend: function (method, ajaxOptions) {
        ajaxOptions.headers = {
          Authorization: `Bearer ${localStorage.custodyToken}`,
        };
      },
      onAjaxError: function (error) {
        localStorage.removeItem("@login_details");
        localStorage.removeItem("custodyToken");
       window.location.reload();
      },
    });
  }

  render() {
    return (
      <React.Fragment>
        <DataGrid
          dataSource={this.dataSource}
          showBorders={false}
          columnAutoWidth={true}
          hoverStateEnabled={true}
        >
          <Scrolling rowRenderingMode="virtual"></Scrolling>

          <Column dataField="itemEPC" caption="Item ID" allowHiding={false} />
          {/* <Column dataField="itemType" caption="Type" /> */}
          <Column
            dataField="unitCount"
            caption="Unit Count"
            alignment="center"
            dataType="number"
          />
          <Column dataField="product.ndc" caption="NDC" />
          <Column dataField="serialNumber" caption="Serial#" />
          <Column dataField="lotNumber" caption="Batch/Lot#" />
          <Column
            dataField="expirationDate"
            caption="Expiry Date"
            dataType="date"
            customizeText={(cellInfo) => {
              return getTime(cellInfo.value) == "Invalid date"
                ? ""
                : getTime(cellInfo.value);
            }}
          />
          {/* <Column
            dataField="manufactureDate"
            caption="Manufacture Date"
            dataType="date"
            customizeText={(cellInfo) => {
              return getTime(cellInfo.value) == "Invalid date"
                ? ""
                : getTime(cellInfo.value);
            }}
          /> */}
          {/* <Column
            dataField="purchaseDate"
            caption="Purchase Date"
            dataType="date"
            customizeText={(cellInfo) => {
              return getTime(cellInfo.value) == "Invalid date"
                ? ""
                : getTime(cellInfo.value);
            }}
          /> */}
          {/* <Column dataField="vendor" caption="Vendor" />
          <Column dataField="vendorNumber" caption="Vendor#" />
          <Column dataField="department" caption="Department" />
          <Column dataField="assetId" caption="Asset ID" />
          <Column
            dataField="lastCheckIn"
            caption="Last Check-In"
            dataType="date"
            customizeText={(cellInfo) => {
              return getTime(cellInfo.value) == "Invalid date"
                ? ""
                : getTime(cellInfo.value);
            }}
          /> */}
          {/* <Column dataField="protocolNumber" caption="Protocol ID" />
          <Column dataField="kitNumber" caption="Lab Kit ID" />
          <Column dataField="medicationTypeID" caption="Medication Type ID" />
          <Column dataField="sequenceNumber" caption="Sequence#" /> */}

          {/* <Summary>
            <TotalItem
              column="unitCount"
              summaryType="sum"
              displayFormat={"TOTAL: {0}"}
            />
          </Summary> */}
        </DataGrid>
      </React.Fragment>
    );
  }
}

// function getTasks(key) {
//   return new DataSource({
//     store: new ArrayStore({
//       data: tasks,
//       key: "ID",
//     }),
//     filter: ["EmployeeID", "=", key],
//   });
// }

export default PackingItems;
