import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { connect } from 'react-redux';
import Input  from '../commonComponents/Input';
import ProductServices from "../service/CommonServices";
import AllFilter from "./ItemFilter";

const AllItemFilter = (props) => {
  const { handleItemSelect, toggleFilter, dropdownOpen, selectedItem, selectedKey,
    selectStartDate, selectEndDate, id, lotNumber, locationList } = props;

    useEffect(async() => {
      await props.getAllLotNumber(id);
      await props.getAllLocation(id);
    },[id]);

  return (

    <Dropdown isOpen={dropdownOpen} toggle={toggleFilter} className="drop-down-second">
      {/* <span style={{fontSize:20, paddingTop: 6}}>Filter :</span> */}
      <Dropdown.Toggle caret>
        {selectedKey === "endDate" || selectedKey === "startDate" 
        ? `Date: ${selectStartDate}→${selectEndDate}`
        : "Period:"
        }
      </Dropdown.Toggle>
      <Dropdown.Menu>
       

        <Dropdown.Item header>By Dates</Dropdown.Item>
          <Input className="date_item" dateClassName={"date_class_name"} label='From' 
          type='date' placeholder='From' value={selectStartDate}
          onChange={e => handleItemSelect("startDate", e.target.value)} />
          
          <Input className="date_item" dateClassName={"date_class_name"} label='To' 
          type='date' placeholder='To' value={selectEndDate}
          onChange={e => handleItemSelect("endDate", e.target.value)}
          />
       
      </Dropdown.Menu>
    </Dropdown>
   
  );
}

const mapStateToProps = (state) => {
  const { lotNumber, locationList } = state.lotNumberReducer;
  return {
      lotNumber,
      locationList
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAllLotNumber: (id) => dispatch(ProductServices.getAllLotNumber(id)),
    getAllLocation: (id) => dispatch(ProductServices.getAllLocations(id))
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(AllItemFilter)

// export default AllItemFilter;