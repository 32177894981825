import axios from "axios";
import { BaseURL } from "Constant/BaseURL";
import { trackPromise } from "react-promise-tracker";

let token = window.localStorage.getItem('custodyToken')

const config = {
  headers: { Authorization: `Bearer ${token}` }
};


const addUser = (userObj) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURL}/user/register`, {
          method: "POST",
          headers: { Authorization: `Bearer ${localStorage.getItem("custodyToken")}` },
          data: userObj,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    );
  };
};

const getAllUserList = (obj) => { 
  // if(token == null)
  //   window.location.reload();

  const { page, rows, field, sortOrder, isActive } = obj;
  
  let url = `${BaseURL}/user?page=${
    page + 1
  }&rows=${rows}&sortOrder=${sortOrder}&field=${field}&isActive=${1}`; 

  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        
        axios({
          method : 'GET',
          url: url,
          headers:{
            Authorization : `Bearer ${localStorage.getItem("custodyToken")}`
          }

        })
          .then((res) => {
            dispatch({ type: "GET_USERS_LIST", payload: res.data.data });
            resolve(res.data.data.data);
          })
          .catch((error) => {
            dispatch({ type: "GET_USERS_LIST", payload: [] });
            reject(error);
          });
      })
    );
  }; 
};

const updateUserStatus = (ids, status) => {
  return function (dispatch) {
    let url = status ? `/user/bulk-mark-active` : `/user/bulk-mark-inactive`;
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURL}${url}`, {
          method: "PUT",
          data: {
            GUIDS: ids,
          },
          headers:{
            Authorization : `Bearer ${localStorage.getItem("custodyToken")}`
          }
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch(function (error) {
            reject(error);
          });
      })
    );
  };
};

const deleteBulkUsers = (ids) => {
  return function (dispatch) {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURL}/user/bulk-delete-users`, {
          method: "DELETE",
          data: { GUIDS: ids },
          headers:{
            Authorization : `Bearer ${localStorage.getItem("custodyToken")}`
          }
        })
          .then((res) => {
            return resolve(res.data);
          })
          .catch(function (error) {
            reject(error);
          });
      })
    );
  };
};

const getUserDetails = (userId) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios.get(`${BaseURL}/user/${userId}`, {
          headers: { Authorization: `Bearer ${localStorage.getItem("custodyToken")}` }
        } )
          .then((res) => {
            dispatch({ type: "GET_USERS_DETAILS", payload: res.data.data });
            resolve(res.data.data);
          })
          .catch((error) => {
            dispatch({ type: "GET_USERS_DETAILS", payload: [] });
            reject(error);
          });
      })
    );
  };
};

const getProviderRole = () => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios.get(`${BaseURL}/user/providerrole` )
          .then((res) => {
            dispatch({ type: "GET_PROVIDER_ROLES", payload: res.data.data });
            resolve(res.data);
          })
          .catch((error) => {
            dispatch({ type: "GET_PROVIDER_ROLES", payload: [] });
            reject(error);
          });
      })
    );
  };
};

const addProviderRole = (obj) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURL}/user/providerrole`, {
          method: "POST",
          data: obj,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            reject(error);
          });
      })
    );
  };
};

const updateUser = (obj, userId) => {
  return function (dispatch) {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios({
          url : `${BaseURL}/user/${userId}`,
          method: "PUT",
          headers:{
            Authorization : `Bearer ${localStorage.getItem("custodyToken")}`
          },
          data: obj,
        })
          .then((res) => {
            resolve(res.data);
          })
          .catch(function (error) {
            reject(error);
          });
      })
    );
  };
};

const changeUsersPageSize = (pageSize) => {
  return { type: "CHANGE_USERS_PAGE_SIZE", payload: pageSize };
};

const changeUsersPage = (pageNumber) => {
  return { type: "CHANGE_USERS_PAGE", payload: pageNumber };
};



const getUserMovementHistory = (userId) => {
  return (dispatch) => {
    return trackPromise(
      new Promise((resolve, reject) => {
        axios(`${BaseURL}/user/movement-history/${userId}`, { method: "GET" })
          .then((res) => {
            dispatch({ type: "GET_MOVEMENT_HISTORY", payload: res.data.data });
            resolve(res.data.data);
          })
          .catch((error) => {
            dispatch({ type: "GET_MOVEMENT_HISTORY", payload: [] });
            reject(error);
          });
      })
    );
  };
};

export default {
  getAllUserList,
  updateUserStatus,
  deleteBulkUsers,
  getUserDetails,
  addUser,
  updateUser,
  changeUsersPageSize,
  changeUsersPage,
  getUserMovementHistory,
  getProviderRole,
  addProviderRole
};
