import { VehicleChecklistHistoryColumns } from "columns/VehicleChecklistHistory";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table-6";
import Pagination from "commonComponents/Pagination";

export class VehicleChecklistHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
    };
  }

  render() {
    let {
      name,
      movementList,
      onPageSizeChange,
      onPageChange,
      onSortedChange,
      pageIndex,
      pageSize,
      historyData,
      total,
      currentPage,
      handleTrPropsCheck
    } = this.props;

    return (
      <>
     
        <ReactTable
        className="table-row"
          data={historyData}
          columns={VehicleChecklistHistoryColumns}
          onSortedChange={onSortedChange}
          // pageSize={pageSize}
          loadingText={`Loading the ${name}`}
          noDataText={`Couldn't find ${name}`}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
          getTdProps={( state, rowInfo, column, instance) => ({
            onClick: () => {
                if (rowInfo && column.id !== 'check') {
                    this.props.history.push({
                        pathname: `/equipmentChecklistHistory/${rowInfo.original.GUID}`
                      })
                }
            }
        })}
        getTrGroupProps={(state, rowInfo) => {
          if (rowInfo !== undefined) {
            return handleTrPropsCheck(rowInfo);
          }
        }}
        />
        <Pagination total={total} pageSize={pageSize} currentPage={currentPage} />
      </>
    );
  }
}

export default withRouter(VehicleChecklistHistory);
