import React, { Component, useEffect } from "react";
import { withRouter } from "react-router";
import { Switch, useHistory } from 'react-router'
import { connect } from "react-redux";
import {
    Popover,
    OverlayTrigger,
    InputGroup,
    Button,
} from "react-bootstrap";
import '../../assets/css/main.css';
import { Formik } from 'formik';
import './profile.css'
import { useRouter } from '../../hooks/routeHooks';
import NavIcons from "./navIcons";
import { BaseURL } from 'Constant/BaseURL';

function Profile({ onLogout, userData, role }) {
    const router = useRouter();
    const history = useHistory();
    const username = userData.username;
    const email = userData.email;
    const avatar = userData.avatar;
    const guid = userData.GUID;
    console.log(avatar);

    return (
        <div>
            <div className="flex-grow-1 d-flex">

                {['bottom'].map((placement) => (
                    <OverlayTrigger
                        trigger="click"
                        rootClose
                        key={placement}
                        placement={placement}
                        overlay={
                            <Popover id={`popover-positioned-${placement}`} style={{ width: "300px" }}>
                                <Popover.Title as="h3" className="profile-title">
                                    <div className="user-profile">
                                        {
                                            avatar == '' || avatar == undefined
                                                ? <img src="" alt="" srcset="" className="user-img" />
                                                : <img src={BaseURL + '/images/users/' + avatar} alt="" srcset="" className="profile-img" />
                                        }
                                    </div>
                                    <div className="profile-display">
                                        <h5 className="username-pop">{username}</h5>
                                        <h6 className="useremail-pop">{userData.email}</h6>
                                    </div>
                                    <div className="profile-display1">
                                        <span className="profile-bt" onClick={() => { history.push(`/user/${guid}`) }}>PROFILE</span>
                                        <span className="line">|</span>
                                        <span className="log-bt" onClick={() => { onLogout && onLogout() }}>LOGOUT</span>
                                    </div>
                                </Popover.Title>
                            </Popover>
                        }
                    >
                        <Button variant="white" className="text-username">
                            {username}
                        </Button>
                    </OverlayTrigger>
                ))}
            </div>
        </div>
    );
}

const mapStateToProps = (state) => {

    console.log(state.loginReducer.userData);
    return {
        userData: state.loginReducer.userData,
        role: state.loginReducer.userData.systemRole,
    };
};

export default connect(mapStateToProps, null)(Profile);
