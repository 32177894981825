import { UserMovementHistoryColumns } from "columns/UserMovementHistoryColumns";
import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import ReactTable from "react-table-6";
import Pagination from "commonComponents/Pagination";
import { Container, Row, Col } from "react-bootstrap";
import DataGrid, {
  Scrolling,
  ColumnChooser,
  Selection,
  StateStoring,
  LoadPanel,
  Pager,
  Column,
  Paging,
  Toolbar,
  Item,
  HeaderFilter,
  SearchPanel,
  Export,
  FilterRow,
  Grouping,
  GroupPanel,
  FilterPanel,
  FilterBuilderPopup,
  Summary,
  GroupItem,
} from "devextreme-react/data-grid";
import { jsPDF } from "jspdf";
import { exportDataGrid as exportDataGridToPdf } from "devextreme/pdf_exporter";
import { Workbook } from "exceljs";
import { saveAs } from "file-saver-es";
import { exportDataGrid } from "devextreme/excel_exporter";
import { Button as DxButton } from "devextreme-react/button";
import { getDateTime } from "commonMethod/common";

const allowedPageSizes = [5, 10, 20, 25, 50, "all"];
const exportFormats = ["xlsx", "pdf"];
const filterBuilderPopupPosition = {
  of: window,
  at: "top",
  my: "top",
  offset: { y: 10 },
};
const filterPanelTexts = {
  clearFilter: "CLEAR FILTER"
};
export class OverviewMovementHistory extends Component {
  constructor(props) {
    super(props);
    this.movementhistoryGrid = React.createRef();
    this.state = {
      dropdownOpen: false,
      selectedItem: "",
    };
    this.refreshDataGrid = this.refreshDataGrid.bind(this);
  }

  // componentDidMount(Id) {
  //   const { handleGetMovementHistory } = this.props;
  //   handleGetMovementHistory(Id);
  // }

  getAssetItems = async (params = {}) => {
    const { pageSize, pageIndex } = this.state;
    let obj = {
      rows: pageSize,
      page: pageIndex,
      field: "itemEPC",
      sortOrder: "ASC",
      id: this.props.asset_id,
      productType: "asset",
      ...params,
    };
  };

  onExporting(e) {
    if (e.format === "xlsx") {
      const workbook = new Workbook();
      const worksheet = workbook.addWorksheet("Companies");
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(
            new Blob([buffer], { type: "application/octet-stream" }),
            "MovementHistory.xlsx"
          );
        });
      });
      e.cancel = true;
    } else if (e.format === "pdf") {
      const doc = new jsPDF();
      exportDataGridToPdf({
        jsPDFDocument: doc,
        component: e.component,
      }).then(() => {
        doc.save("MovementHistory.pdf");
      });
    }
  }
  refreshDataGrid() {
    this.movementhistoryGrid.current.instance.refresh();
    this.getAssetItems();
  }

  render() {
    let {
      name,
      movementList,
      onPageSizeChange,
      onPageChange,
      onSortedChange,
      pageIndex,
      pageSize,
      total,
      currentPage,
    } = this.props;

    return (
      <>
        {/* <ReactTable
          manual
          data={movementList}
          columns={UserMovementHistoryColumns}
          onSortedChange={onSortedChange}
          // pageSize={pageSize}
          loadingText={`Loading the ${name}`}
          noDataText={`Couldn't find ${name}`}
          onPageSizeChange={onPageSizeChange}
          onPageChange={onPageChange}
        />
        <Pagination
          total={total}
          pageSize={pageSize}
          currentPage={currentPage}
        /> */}
        <Container fluid>
          <Row>
            <Col md="">
              <DataGrid
                id="girdMovementHistory"
                dataSource={movementList}
                keyExpr="GUID"
                showBorders={false}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnResizingMode={"widget"}
                width={"100%"}
                onExporting={this.onExporting}
                ref={this.movementhistoryGrid}
              >
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  storageKey="movement-history-grid"
                />
                <FilterPanel visible={true} texts={filterPanelTexts} />
                <FilterBuilderPopup position={filterBuilderPopupPosition} />
                {/* <GroupPanel visible={true} /> */}
                {/* <Grouping autoExpandAll={true} contextMenuEnabled={true} /> */}
                <LoadPanel enabled />
                <FilterRow visible={false} applyFilter={"Immediately"} />
                {/* <Selection mode="multiple" /> */}
                <HeaderFilter visible={true} allowSearch={true} />
                <ColumnChooser enabled={true} mode={"select"} />
                <SearchPanel enabled={true} visible={true} width={300} />

                {/* <Column
                  dataField="eventTime"
                  dataType="datetime"
                  caption="Event Time"
                  customizeText={(cellInfo) => {
                    return getDateTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getDateTime(cellInfo.value);
                  }}
                /> */}
                <Column
                  dataField="firstSeen"
                  dataType="datetime"
                  caption="First Seen"
                  customizeText={(cellInfo) => {
                    return getDateTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getDateTime(cellInfo.value);
                  }}
                />
                <Column
                  dataField="lastSeen"
                  dataType="datetime"
                  caption="Last seen"
                  customizeText={(cellInfo) => {
                    return getDateTime(cellInfo.value) == "Invalid date"
                      ? ""
                      : getDateTime(cellInfo.value);
                  }}
                />
                <Column dataField="bizLocation.name" caption="Location" />
                <Summary>
                  {/* <GroupItem
                    column="eventTime"
                    summaryType="count"
                    displayFormat="{0}"
                  /> */}
                </Summary>
                <Export
                  enabled={true}
                  formats={exportFormats}
                  excelWrapTextEnabled={true}
                />
                <Scrolling rowRenderingMode="virtual"></Scrolling>
                <Paging defaultPageSize={this.state.pageSize} />
                <Pager
                  visible={true}
                  allowedPageSizes={allowedPageSizes}
                  displayMode={"adaptive"}
                  showPageSizeSelector={this.props.pageSize}
                  showInfo={true}
                  showNavigationButtons={true}
                />
                <Toolbar>
                  <Item location="before" locateInMenu={"always"}>
                    <DxButton
                      text="Reset Grid"
                      hint="Reset Grid"
                      icon="revert"
                      onClick={() => {
                        this.movementhistoryGrid.current.instance.state(null);
                        this.getAssetItems();
                      }}
                    />
                  </Item>
                  {/* <Item name="groupPanel" location="before" /> */}
                  <Item name="exportButton" location="after" />
                  <Item location="after">
                    <DxButton
                      hint="Refresh"
                      icon="refresh"
                      onClick={this.refreshDataGrid}
                    />
                  </Item>
                  <Item name="columnChooserButton" location="after" />
                  <Item name="searchPanel" location="before" />
                </Toolbar>
              </DataGrid>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default withRouter(OverviewMovementHistory);
