import { VehiclesColumns } from "Constant/Column";

/**
 * Initial state for the drugs which is empty.
 */
const initialState = {
  vehicles: [],
  vehiclesList:[],
  total: 0,
  pageSize: 20,
  pageIndex: 0,
  columns: [...VehiclesColumns],
  vehicleDetails: {},
  productDetails: {},
  vehicleFleet: [],
  vehicleTypes:[],
  sortInfo: { field: 'name', sortOrder: 'ASC' }
};

export default function vehicleReducer(state = initialState, action) {
  switch (action.type) {
    case 'GET_VEHICLE_LIST_SUCCESS':
      let { data, count, items, page, rows } = action.payload;
      return { ...state, vehicles: data, total: count, currentPage: page, rows: rows };

    // case 'GET_VEHICLES_NAME_LIST_SUCCESS':
    //  // let { data, count, items, page, rows } = action.payload;
    //   return { ...state, vehicles: data, total: count, currentPage: page, rows: rows };  

    case 'CHANGE_PAGE_SIZE_VEHICLES':
      return { ...state, pageSize: action.payload, pageIndex: 0 }

    case 'CHANGE_PAGE_VEHICLES':
      return { ...state, pageIndex: action.payload }

    case 'UPDATE_VEHICLE_LIST_COLUMNS':
      return { ...state, columns: [...action.payload] }

    case 'GET_VEHICLE_DETAILS_SUCCESS':
      return { ...state, vehicleDetails: { ...action.payload } }

    case 'GET_VEHICLE_FLEET_SUCCESS':
      return { ...state, vehicleFleet: action.payload }
      
    case 'GET_VEHICLE_TYPE_SUCCESS':
      return { ...state, vehicleTypes:action.payload }      

    case 'GET_PRODUCT_DETAILS_SUCCESS':
      return { ...state, productDetails: { ...action.payload } }

    case 'UPDATE_VEHICLE_SORT_INFO': {
      return { ...state, sortInfo: { ...action.payload } }

    }

    default:
      return state;
  }
}