import axios from "axios";
import { getAllCategoryURL, addCategoryURL } from "Constant/ApiRoutes";
import { BaseURLMock } from "Constant/BaseURL";
import { BaseURLDev } from "Constant/BaseURL";
import { trackPromise } from "react-promise-tracker";
class ReceivingService {
  getReceivingList = (obj) => {
    const { page, rows, field, sortOrder } = obj;
    return function (dispatch) {
      return trackPromise(
        new Promise((resolve, reject) => {
          axios(
            `${BaseURLMock}/ShipmentManifests?companyGuid=${localStorage.companyGuid}`,
            {
              method: "GET",
              params: {
                companyGuid: localStorage.companyGuid,
              },
              headers: {
                Authorization: `Bearer ${localStorage.getItem("custodyToken")}`,
              },
            }
          )
            .then((res) => {
              dispatch({
                type: "GET_VEHICLES_NAME_LIST_SUCCESS",
                payload: res.data.data,
              });
              resolve(res.data);
            })
            .catch(function (error) {
              dispatch({ type: "GET_VEHICLES_NAME_LIST_ERROR", payload: [] });
              reject(error);
            });
        })
      );
    };
  };

  // getPackingDetail = (obj) => {
  //   const { packingGuid } = obj;
  //   return function () {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios(`${BaseURLDev}/Packings`, {
  //           method: 'GET',
  //           params: {
  //             companyGuid: localStorage.companyGuid,
  //             packingGuid: packingGuid,
  //           },
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem('custodyToken')}`,
  //           },
  //         })
  //           .then((res) => {
  //             resolve(res.data);
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };

  // changePageSize = (pageSize) => {
  //   return { type: 'CHANGE_PAGE_SIZE', payload: pageSize };
  // };

  changePage = (pageNumber) => {
    return { type: "CHANGE_PAGE", payload: pageNumber };
  };

  updateColumns = (columns) => {
    return {
      type: "UPDATE_DRUGS_COLUMNS",
      payload: columns,
    };
  };
  deleteBulkReceivings = (ids) => {
    return function (dispatch) {
      return trackPromise(
        new Promise((resolve, reject) => {
          axios
            .delete(
              `${BaseURLDev}/ShipmentManifests/bulk-delete-shipments?companyGuid=${localStorage.companyGuid}`,
              {
                method: "DELETE",
                data: { guids: ids },
                headers: {
                  Authorization: `Bearer ${localStorage.getItem(
                    "custodyToken"
                  )}`,
                },
              }
            )
            .then((res) => {
              resolve(res.data);
              dispatch({ type: "DELETE_PACKING_DATA", payload: res.data });
            })
            .catch(function (error) {
              reject(error);
            });
        })
      );
    };
  };

  // getProductFilter = (ids, isActive) => {
  //   return function (dispatch) {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios
  //           .post(`${BaseURLDev}/products/product-filter`, {
  //             isActive: isActive,
  //             ids: ids,
  //           })
  //           .then((res) => {
  //             dispatch({ type: 'ADD_DRUGS_LIST', payload: res.data });
  //             resolve();
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };

  // getProductCategory = (ids, category) => {
  //   return function (dispatch) {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios
  //           .post(`${BaseURLDev}/productCategory/add-productcategory`, {
  //             name: category,
  //             ids: ids,
  //           })
  //           .then((res) => {
  //             dispatch({ type: 'ADD_DRUGS_LIST', payload: res.data });
  //             resolve();
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };

  // getCategory = () => {
  //   return function (dispatch) {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios(`${BaseURLDev}${getAllCategoryURL}`, {
  //           method: 'POST',
  //           data: {
  //             productType: 'category',
  //           },
  //         })
  //           .then((res) => {
  //             dispatch({ type: 'GET_ALL_CATEGORIES', payload: res.data });
  //             resolve(res.data);
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };

  // addCategory = (data) => {
  //   return function (dispatch) {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios(`${BaseURLDev}${addCategoryURL}`, {
  //           method: 'POST',
  //           data: data,
  //         })
  //           .then((res) => {
  //             dispatch({ type: 'GET_ALL_CATEGORIES', payload: res.data });
  //             resolve(res.data);
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };

  // filterProductByCategory = (category, data) => {
  //   const { page, rows, field, sortOrder, type } = data;
  //   return function (dispatch) {
  //     return trackPromise(
  //       new Promise((resolve, reject) => {
  //         axios
  //           .post(`${BaseURLDev}/products/category-filter/drug`, {
  //             category: category,
  //             productType: 'drug',
  //             rows: rows,
  //             page: page + 1,
  //             field: field,
  //             sortOrder: sortOrder ? sortOrder : 'ASC',
  //           })
  //           .then((res) => {
  //             dispatch({ type: 'ADD_DRUGS_LIST', payload: res.data });
  //             resolve();
  //           })
  //           .catch(function (error) {
  //             reject(error);
  //           });
  //       })
  //     );
  //   };
  // };
}

const receivingService = new ReceivingService();
export default receivingService;
