import React from "react";
import { Plugin, Getter } from "@devexpress/dx-react-core";

const getRowIndexes = ({ rows }) => {
  return new Map(rows.map((row, index) => [row, index+1]));
};

const getCellValue = ({ rowIndexes }) => (row, columnName) =>
  columnName === "index" ? rowIndexes.get(row) : row[columnName];

export const RowIndexer = () => {
  return (
    <Plugin>
      <Getter name="rowIndexes" computed={getRowIndexes} />
      <Getter name="getCellValue" computed={getCellValue} />
    </Plugin>
  );
};