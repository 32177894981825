import {getDateTime} from "commonMethod/common";
import {millisToMinutesAndSeconds} from "commonMethod/common"

export const EquipmentChecklistHistoryColumns = [
    {
      width: 120,
      Header: 'Timestamp',
      accessor: 'eventTime',
      Cell: ({ row }) => (
        <span>{getDateTime(row._original.eventTime)}</span>
      ),
    },
     {
      width: 120,
      Header: 'Checklist',
      accessor: 'name',
     }, 
     {
      width: 120,
      Header: 'Vehicle',
      accessor: 'vehicleName',
    },
    {
        width: 120,
        Header: 'duration',
        accessor: 'durationMilliSecs',
        Cell: ({ row }) => (
          <span>{millisToMinutesAndSeconds(row._original.durationMilliSecs)}</span>
        ),
      },
    // {
    //   width: 120,
    //   Header: 'Event Time',
    //   accessor: 'eventTime',
    //   Cell: ({ row }) => (
    //     <span>{getDateTime(row._original.eventTime)}</span>
    //   )
    // },
    {
      width: 120,
      Header: 'Qty Expected',
      accessor: 'qtyExpected',
    },
    {
        width: 120,
        Header: 'Qty Recorded',
        accessor: 'qtyRecorded',
      }, 
      {
        width: 120,
        Header: 'Completed',
        accessor: 'isCompleted',
        Cell: ({ row }) => (
          <span>{row._original.isCompleted.toString()}</span>
        ),
      },
      
      {
        width: 120,
        Header: 'Person',
        accessor: 'person.name',
      },
      {
        width: 120,
        Header: 'Supervisor',
        accessor: 'supervisor.name',
      },
      {
        width: 120,
        Header: 'Comment',
        accessor: 'comments',
      },
  ];